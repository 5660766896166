import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  InvoiceDocument,
  ApiSalesManagerInvoiceToOrderMutation,
  ApiSalesManagerInvoiceToOrderMutationVariables,
  ApiSalesManagerInvoiceToOrderDocument,
  InvoiceQuery,
  InvoiceQueryVariables,
} from '~/graphql/types'
import { invoiceStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class InvoiceService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = invoiceStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        InvoiceQuery,
        InvoiceQueryVariables
      >({
        query: InvoiceDocument,
        variables: {
          where: {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.invoice_view)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get invoices() {
    return this.store.invoices
  }

  async invoiceToOrder(ids: string[], provider: string, method: string) {
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerInvoiceToOrderMutation,
      ApiSalesManagerInvoiceToOrderMutationVariables
    >({
      mutation: ApiSalesManagerInvoiceToOrderDocument,
      variables: {
        data: {
          ids,
          provider,
          method,
        },
      },
    })
    return mutateResult.data?.api_sales_manager_invoice_to_order.result ?? ''
  }
}
