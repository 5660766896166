import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { ShipmentDocumentQuery } from '~/graphql/types'

@Module({
  name: 'shipment-document',
  stateFactory: true,
  namespaced: true,
})
export default class ShipmentDocument extends VuexModule {
  fetchStarted = false
  loading = true
  items: ShipmentDocumentQuery['shipment_document'] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
