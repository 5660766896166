import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

export enum MessageType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  SKIP = 'skip',
}

export type MessageObject = {
  message: string
  values?: {
    [key: string]: any
  }
  color: MessageType
  timeout: number
}

@Module({
  name: 'system-messages',
  stateFactory: true,
  namespaced: true,
})
export default class SystemMessages extends VuexModule {
  messages: MessageObject[] = []

  @Mutation
  setMessages(messages: MessageObject[]) {
    this.messages = messages
  }

  @Mutation
  addMessage(message: MessageObject) {
    this.messages.push(message)
  }
}
