var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('v-app', [_c('v-main', [_c('v-container', {
    staticClass: "d-flex"
  }, [_c('nuxt')], 1)], 1), _vm._v(" "), _c('app-scroll-to-top'), _vm._v(" "), _c('app-snack-bar-messages')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }