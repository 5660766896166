import 'reflect-metadata'
import {
  ApiSalesManagerQuoteToEmailMutation,
  ApiSalesManagerQuoteToEmailMutationVariables,
  ApiSalesManagerQuoteToEmailDocument,
} from '~/graphql/types'
import { salesCartQuoteStore } from '~/utils/store-accessor'
import { SalesCartAbstractService } from '~/services/sales-cart-abstract.service'
import SalesCartQuote from '~/store/sales-cart-quote'

export class SalesCartQuoteService extends SalesCartAbstractService {
  getStore(): SalesCartQuote {
    return salesCartQuoteStore
  }

  getCartType(): string {
    return 'quote'
  }

  subcustomerId() {
    return ''
  }

  get quoteCustomerDataIsUpdating() {
    return this.getStore().isQuoteCustomerDataUpdating
  }

  updateCartQuoteCustomerDataField(fieldName: string, fieldValue: string) {
    const cart = JSON.parse(JSON.stringify(this.cart))
    if (cart) {
      const quote = { ...(this.cartAdditionalInfo.quote ?? {}) }
      quote[fieldName] = fieldValue
      cart.additional_info.quote = quote
      this.getStore().setItem(cart)
    }
  }

  setIsQuoteCustomerDataUpdating(updating) {
    this.getStore().setIsQuoteCustomerDataUpdating(updating)
  }

  async updateCartQuoteCustomerData() {
    if (this.quoteCustomerDataIsUpdating) {
      return
    }
    this.setIsQuoteCustomerDataUpdating(true)
    setTimeout(() => {
      this.setIsQuoteCustomerDataUpdating(false)
    }, 3000)
    const quote = { ...(this.cartAdditionalInfo.quote ?? {}) }
    await this._updateCartAdditionalInfo({
      quote,
    })
  }

  async sendEmail(locale: string, endCustomerMode: boolean) {
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerQuoteToEmailMutation,
      ApiSalesManagerQuoteToEmailMutationVariables
    >({
      mutation: ApiSalesManagerQuoteToEmailDocument,
      variables: {
        id: this.cart?.id.toString(),
        locale,
        endCustomerMode,
      },
    })
    return mutateResult.data?.api_sales_manager_quote_to_email.result
  }
}
