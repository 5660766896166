import 'reflect-metadata'
import { salesCartProposalStore } from '~/utils/store-accessor'
import { SalesCartAbstractService } from '~/services/sales-cart-abstract.service'
import SalesCartProposal from '~/store/sales-cart-proposal'

export class SalesCartProposalService extends SalesCartAbstractService {
  getStore(): SalesCartProposal {
    return salesCartProposalStore
  }

  getCartType(): string {
    return 'proposal'
  }

  async sendProposal() {
    if (this.cart?.id) {
      return await this._updateCart({
        id: this.cart.id,
        status: 'sent',
        additional_info: {
          ...this.cart.additional_info,
          sentDate: new Date().toISOString(),
        },
      })
    }
    return undefined
  }
}
