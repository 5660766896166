import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  OrderItemUnshippedDocument,
  OrderItemUnshippedQuery,
  OrderItemUnshippedQueryVariables,
} from '~/graphql/types'
import { orderItemUnshippedStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class OrderItemUnshippedService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = orderItemUnshippedStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        OrderItemUnshippedQuery,
        OrderItemUnshippedQueryVariables
      >({
        query: OrderItemUnshippedDocument,
        variables: {
          where: {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.order_item_unshipped)
    }
    this.store.setLoading(false)
  }
}
