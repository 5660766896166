import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { MailType } from '~/store/mail'

export type CommunicationCodeType = {
  code: string
  is_external: boolean
  provider: string
}

export type CommunicationExternalMailType = {
  code: string
  created_at: string
  id: string
  identifier: string
  is_processed: boolean
  search_criteria?: string
  title?: string
  updated_at: string
}

export type CommunicationType = {
  code: string
  communication_code?: CommunicationCodeType
  communication_external_mail?: CommunicationExternalMailType
  created_at: string
  customer_erp_id: string
  id: string
  is_archived: boolean
  is_deleted: boolean
  mail?: MailType
  mail_id?: string
  external_mail_id?: string
  updated_at: string
}

export type CommunicationListType = {
  code: string
  created_at: string
  customer_erp_id: string
  id: string
  is_enabled_email: boolean
  is_enabled_site: boolean
  send_to_email: string[]
  updated_at: string
}

export type CommunicationSettingsType = {
  id?: string
  language?: string
}

@Module({
  name: 'communication',
  stateFactory: true,
  namespaced: true,
})
export default class Communication extends VuexModule {
  fetchStarted = false
  loading = true
  settings: CommunicationSettingsType = {}
  lists: CommunicationListType[] = []
  items: CommunicationType[] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setSettings(settings) {
    this.settings = settings
  }

  @Mutation
  setList(lists) {
    this.lists = lists
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
