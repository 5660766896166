import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { CustomerAddressQuery } from '~/graphql/types'

@Module({
  name: 'customer-address',
  stateFactory: true,
  namespaced: true,
})
export default class CustomerAddress extends VuexModule {
  fetchStarted = false
  loading = true
  _address?: CustomerAddressQuery['customer_address'] = undefined

  get customerAddress() {
    return this._address
  }

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setCustomerAddress(data) {
    this._address = data
  }
}
