import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { CountryRegionQuery } from '~/graphql/types'

@Module({
  name: 'country-region',
  stateFactory: true,
  namespaced: true,
})
export default class CountryRegion extends VuexModule {
  fetchStarted = false
  loading = true
  items: CountryRegionQuery['country_region'] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
