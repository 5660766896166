import moment, { unix } from 'moment'
import { cid, container } from 'inversify-props'
import { UserService } from '~/services/user.service'
import { LayoutService } from '~/services/layout.service'
import { SystemMessageService } from '~/services/system-message.service'

export default function ({ app, redirect }) {
  const isIDLUX = window.location.hostname === 'b2b.ideal-lux.com'
  const externalLoginLink = isIDLUX
    ? app.i18n.t('login.productionLink')
    : app.localePath('/login')
  const systemMessageService = container.get<SystemMessageService>(
    cid.SystemMessageService,
  )
  const userService = container.get<UserService>(cid.UserService)
  const layoutService = container.get<LayoutService>(cid.LayoutService)

  // If the user is not authenticated
  const jwtToken = userService.token
  if (!jwtToken) {
    return redirect(externalLoginLink)
  }
  const tokenDecoded: any = userService.tokenDecoded
  const logoutAfter = Math.max(
    0,
    unix(tokenDecoded.exp).diff(moment(), 'seconds'),
  )

  if (userService.refreshTokenTimeout) {
    clearTimeout(userService.refreshTokenTimeout)
    userService.refreshTokenTimeout = null
  }

  userService.refreshTokenTimeout = setTimeout(() => {
    systemMessageService.addErrorMessage('error.expired')
    setTimeout(async () => {
      await userService.logout()
      return redirect(externalLoginLink)
    }, 2000)
  }, logoutAfter * 1000)

  // manage access based on current page
  for (const item of layoutService.menuItems) {
    if (
      app.localePath(app.context.route.path) === app.localePath(item.link) &&
      !userService.inRoles(item.roles)
    ) {
      // throw app.context.error({ statusCode: 403 })
      return redirect(app.localePath('/'))
    }
  }
  return true
}
