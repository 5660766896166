var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('div', [_vm._l(_vm.snackbars, function (snackbar, idx) {
    return _c('v-snackbar', _vm._b({
      key: snackbar.key,
      ref: 'v-snackbars-' + _vm.identifier,
      refInFor: true,
      class: 'd-print-none v-snackbars v-snackbars-' + _vm.identifier + '-' + snackbar.key,
      attrs: {
        "transition": snackbar.transition,
        "top": snackbar.top,
        "bottom": snackbar.bottom,
        "left": snackbar.left,
        "right": snackbar.right,
        "color": snackbar.color,
        "content-class": snackbar.contentClass,
        "timeout": -1
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function () {
          return [_vm._t("default", function () {
            return [_vm._v("\n        " + _vm._s(snackbar.message.message) + "\n      ")];
          }, {
            "message": snackbar.message.message,
            "object": snackbar
          })];
        },
        proxy: true
      }, {
        key: "action",
        fn: function () {
          return [_vm._t("action", function () {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function ($event) {
                  return _vm.removeMessage(snackbar.key, true);
                }
              }
            }, [_vm._v("close")])];
          }, {
            "id": snackbar.key,
            "close": function () {
              return _vm.removeMessage(snackbar.key, true);
            },
            "index": idx,
            "message": snackbar.message.message,
            "object": snackbar
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: snackbar.show,
        callback: function ($$v) {
          _vm.$set(snackbar, "show", $$v);
        },
        expression: "snackbar.show"
      }
    }, 'v-snackbar', _vm.$attrs, false));
  }), _vm._v(" "), _vm._l(_vm.keys, function (key, idx) {
    return _c('css-style', {
      key: key + idx
    }, [_vm._v("\n    .v-snackbars.v-snackbars-" + _vm._s(_vm.identifier) + "-" + _vm._s(key) + " .v-snack__wrapper {\n    transition: " + _vm._s(_vm.topOrBottom[key]) + " 500ms; " + _vm._s(_vm.topOrBottom[key]) + ": 0; }\n    .v-snackbars.v-snackbars-" + _vm._s(_vm.identifier) + "-" + _vm._s(key) + " > .v-snack__wrapper {\n    " + _vm._s(_vm.topOrBottom[key]) + ":" + _vm._s(_vm.calcDistance(key)) + "px; }\n  ")]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }