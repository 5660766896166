
import { Prop } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import App from '~/mixins/app'

@Component
export default class AppLogo extends App {
  @Prop({
    type: String,
    required: true,
  })
  width!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  dark!: boolean
}
