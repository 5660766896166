import { cid, container } from 'inversify-props'
import { SalesTransactionService } from '~/services/sales-transaction.service'
import { SystemMessageService } from '~/services/system-message.service'

export default async function ({ app, params, query, redirect }) {
  const systemMessageService = container.get<SystemMessageService>(
    cid.SystemMessageService
  )
  const transactionService = container.get<SalesTransactionService>(
    cid.SalesTransactionService
  )

  const transactionId = params?.transaction
  const request = {
    ...query,
  }

  await transactionService.updateTransaction(transactionId, request)

  const transaction = await transactionService.getTransaction(transactionId)
  const transactionStatus = transaction?.status ?? 'undefined'
  if (
    ['voided', 'pending', 'authorized', 'captured'].includes(transactionStatus)
  ) {
    if (transaction.provider !== 'erp') {
      systemMessageService.addSuccessMessage('transaction.success.message')
    }
  } else {
    systemMessageService.addErrorMessage('transaction.error.message')
  }

  const orderType = transaction?.order_transactions[0]?.order?.type
  if (orderType === 'invoice') {
    return redirect(app.localePath('/invoices'))
  } else {
    const orderIncrementId =
      transaction?.order_transactions[0]?.order?.increment_id
    if (orderIncrementId) {
      return redirect(app.localePath(`/order/${orderIncrementId}`), { new: 1 })
    } else {
      return redirect(app.localePath('/new-order'), { step: 1 })
    }
  }
}
