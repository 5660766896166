import { render, staticRenderFns } from "./AppSnackBarMessages.vue?vue&type=template&id=25705506&scoped=true"
import script from "./AppSnackBarMessages.vue?vue&type=script&lang=ts"
export * from "./AppSnackBarMessages.vue?vue&type=script&lang=ts"
import style0 from "./AppSnackBarMessages.vue?vue&type=style&index=0&id=25705506&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25705506",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSnackbars: require('/app/components/AppSnackbars.vue').default})
