import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiFileUploadDocument,
  ApiFileUploadMutation,
  ApiFileUploadMutationVariables,
  DeleteFileByPkDocument,
  DeleteFileByPkMutation,
  DeleteFileByPkMutationVariables,
  Download_Insert_Input,
  Download_Set_Input,
  DownloadsDocument,
  DownloadsSubscription,
  DownloadsSubscriptionVariables,
  InsertDownloadDocument,
  InsertDownloadMutation,
  InsertDownloadMutationVariables,
  MoveDownloadDocument,
  MoveDownloadMutation,
  MoveDownloadMutationVariables,
  Role_Enum,
  UpdateDownloadDocument,
  UpdateDownloadMutation,
  UpdateDownloadMutationVariables,
} from '~/graphql/types'
import { fileDownloadStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class FileDownloadService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = fileDownloadStore

  private _o: ZenObservable.Subscription | undefined

  get role() {
    return this.userService.isAgent ? Role_Enum.Agent : Role_Enum.Customer
  }

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    const { data } = await this.apolloClientService.client.query<
      DownloadsSubscription,
      DownloadsSubscriptionVariables
    >({
      query: DownloadsDocument,
      variables: {
        where: {
          role: {
            _eq: this.role,
          },
        },
      },
    })

    if (data) {
      this.store.setItems(data.download)
    }
    this.store.setLoading(false)
  }

  subscribe() {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<DownloadsSubscription, DownloadsSubscriptionVariables>({
          query: DownloadsDocument,
          variables: {
            where: {},
          },
        })
        .subscribe(({ data }) => {
          this.store.setLoading(false)
          this.store.setUpdating(false)
          if (data) {
            this.store.setItems(data.download)
          }
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  get loading() {
    return this.store.loading
  }

  get updating() {
    return this.store.updating
  }

  get downloads() {
    return this.store.items
  }

  getBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          return resolve(
            Buffer.from(reader.result, 'binary').toString('base64')
          )
        }
        return resolve('')
      }
      reader.onerror = (error) => reject(error)
    })
  }

  async uploadFile(file: File) {
    const based = await this.getBase64(file)
    if (typeof based === 'string') {
      const uploadedFile = await this.apolloClientService.client.mutate<
        ApiFileUploadMutation,
        ApiFileUploadMutationVariables
      >({
        mutation: ApiFileUploadDocument,
        variables: {
          data: {
            name: file.name,
            type: 'downloads',
            mimeType: file.type,
            content: based,
            persist: true,
          },
        },
      })
      return uploadedFile.data?.api_file_upload.id
    }
    return null
  }

  /**
   * Delete a File (entity + real filesystem file)
   * Since download has cascade deleting files will delete associated downloads
   * @param id
   */
  async deleteFileById(id: any) {
    const deleteMutation = await this.apolloClientService.client.mutate<
      DeleteFileByPkMutation,
      DeleteFileByPkMutationVariables
    >({
      mutation: DeleteFileByPkDocument,
      variables: {
        id,
      },
    })
    return deleteMutation.data?.delete_file_by_pk
  }

  async updateDownloadSorting(id: any, position: number) {
    this.store.setUpdating(true)
    const updateMutation = await this.apolloClientService.client.mutate<
      MoveDownloadMutation,
      MoveDownloadMutationVariables
    >({
      mutation: MoveDownloadDocument,
      variables: {
        id,
        position,
      },
    })
    return (
      updateMutation.data?.move_download &&
      updateMutation.data?.move_download.length > 1
    )
  }

  async updateDownload(id: string, data: Download_Set_Input) {
    return await this.apolloClientService.client.mutate<
      UpdateDownloadMutation,
      UpdateDownloadMutationVariables
    >({
      mutation: UpdateDownloadDocument,
      variables: {
        id,
        _set: data,
      },
    })
  }

  async insertDownload(download: Download_Insert_Input) {
    return await this.apolloClientService.client.mutate<
      InsertDownloadMutation,
      InsertDownloadMutationVariables
    >({
      mutation: InsertDownloadDocument,
      variables: {
        download,
      },
    })
  }
}
