
import { Component, Watch } from 'nuxt-property-decorator'
import { Inject } from 'inversify-props'
import { UserService } from '~/services/user.service'
import App from '~/mixins/app'
import { LayoutService } from '~/services/layout.service'
import { SalesCartOrderService } from '~/services/sales-cart-order.service'
import { CustomerService } from '~/services/customer.service'
import { SalesCartProposalService } from '~/services/sales-cart-proposal.service'

@Component
export default class AppHeader extends App {
  @Inject('UserService') userService!: UserService
  @Inject('CustomerService') customerService!: CustomerService
  @Inject('LayoutService') layoutService!: LayoutService
  @Inject('SalesCartOrderService') salesCartOrderService!: SalesCartOrderService
  @Inject('SalesCartProposalService')
  salesCartProposalService!: SalesCartProposalService

  drawer = !this.$vuetify.breakpoint.mobile
  dialogPromo = true

  @Watch('$vuetify.breakpoint.mobile')
  onChangeBreakpointMobile(value) {
    this.drawer = !value
  }

  createdRoutePath = ''

  created() {
    this.createdRoutePath = this.$route.path
  }

  get isAgent() {
    return this.userService.isAgent
  }

  get isAgentCustomer() {
    return this.userService.isAgentCustomer
  }

  get customers() {
    return this.customerService.customers
  }

  get selectedCustomer() {
    return this.customerService.customer?.user_id ?? undefined
  }

  get userDisplayName() {
    return this.userService.userName
  }

  get menuItems() {
    return this.layoutService.menuItems
      .filter(
        (menuItem) =>
          menuItem.enabled &&
          menuItem.useInMenu &&
          this.userService.inRoles(menuItem.roles)
      )
      .map((menuItem) => {
        let counter = 0
        if (['/new-order'].includes(menuItem.link)) {
          counter = this.salesCartOrderService.counter
        } else if (['/new-proposal'].includes(menuItem.link)) {
          counter = this.salesCartProposalService.counter
        }
        return {
          ...menuItem,
          counter,
        }
      })
  }

  get promoPopupEnable() {
    return (
      !this.createdRoutePath.includes('/promo/') &&
      this.menuItems.find((item) => item.link === '/promo')
    )
  }

  get availableCurrencies() {
    return this.exchangeRateService.currencies
  }

  get availableLocales() {
    return this.$i18n.locales
  }

  get selectedLocale() {
    return this.$i18n.locale
  }

  set selectedLocale(locale: string) {
    this.$router.push(this.switchLocalePath(locale))
  }

  get selectedCurrency() {
    return this.exchangeRateService.store.currentCurrencyCode
  }

  set selectedCurrency(currency: any) {
    this.exchangeRateService.store.setCurrentCurrencyCode(currency)
  }

  get customNavigationDrawerStyle() {
    return this.$vuetify.breakpoint.lgAndUp
      ? null
      : {
          height: 'calc(100 * var(--vh))',
        }
  }

  typedCustomer(item): CustomerService['customer'] {
    return item
  }

  async selectCustomer(id) {
    if (id) {
      const wasAgentCustomer = this.userService.isAgentCustomer
      await this.userService.selectCustomer(id)
      if (wasAgentCustomer) {
        // @ts-ignore
        window.location.reload(true)
      } else {
        window.location.href = this.localePath('/')
      }
    }
  }

  async deselectCustomer() {
    await this.userService.selectCustomer('')
    window.location.href = this.localePath('/')
  }
}
