import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import {
  CustomerCurrentQuery,
  CustomerQuery,
  CustomerWithAdditionalDataQuery,
} from '~/graphql/types'

@Module({
  name: 'customer',
  stateFactory: true,
  namespaced: true,
})
export default class Customer extends VuexModule {
  fetchStarted = false
  fetchCurrentStarted = false
  loading = true
  loadingCurrent = true
  items:
    | CustomerQuery['customer']
    | CustomerWithAdditionalDataQuery['customer'] = []

  current: CustomerCurrentQuery['customer'][0] | null = null

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setFetchCurrentStarted(fetchCurrentStarted) {
    this.fetchCurrentStarted = fetchCurrentStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setLoadingCurrent(loadingCurrent) {
    this.loadingCurrent = loadingCurrent
  }

  @Mutation
  setItems(items) {
    this.items = items
  }

  @Mutation
  setCurrent(current) {
    this.current = current
  }
}
