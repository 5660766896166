import 'reflect-metadata'
import { Inject } from 'inversify-props'
import moment from 'moment'
import {
  ApiCustomerStatisticsDocument,
  ApiCustomerStatisticsQuery,
} from '~/graphql/types'
import { customerStatisticStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class CustomerStatisticService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = customerStatisticStore

  async fetchAll(force = false) {
    if (
      this.userService.hasDemoCustomerRole ||
      this.userService.hasSelectedDemoCustomerRole
    ) {
      this.store.setLoading(false)
      return
    }
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<ApiCustomerStatisticsQuery>({
        query: ApiCustomerStatisticsDocument,
      })
    ).data

    if (data) {
      this.store.setItem(data.api_customer_statistics)
    }
    this.store.setLoading(false)
  }

  get statistics() {
    const retVal: {
      year: number
      amount: number
    }[] = []

    const currentYear = moment().year()
    const lastYear = moment().subtract('1', 'year').year()
    const last2Year = moment().subtract('2', 'year').year()
    const item = this.store.item
    retVal.push({
      year: currentYear,
      amount: item?.revenue_current_year ?? 0,
    })
    retVal.push({
      year: lastYear,
      amount: item?.revenue_last_year ?? 0,
    })
    retVal.push({
      year: last2Year,
      amount: item?.revenue_2_years_ago ?? 0,
    })
    return retVal
  }
}
