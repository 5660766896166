var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('img', {
    staticClass: "logo",
    style: `width: ${_vm.width}`,
    attrs: {
      "src": require(`~/static/logo${_vm.dark ? '-white' : ''}.svg`),
      "alt": "Logo"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }