import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { Context } from '@nuxt/types'
import { jwtDecode } from 'jwt-decode'
import * as GraphqlTypes from '~/graphql/types'
import {
  ApiLoginDocument,
  ApiLoginMutation,
  ApiLoginMutationVariables,
  ApiSelectCustomerDocument,
  ApiSelectCustomerMutation,
  ApiSelectCustomerMutationVariables,
  DeleteUserByPkDocument,
  DeleteUserByPkMutation,
  DeleteUserByPkMutationVariables,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { userStore } from '~/utils/store-accessor'

export class UserService {
  @Inject('$apolloHelpers')
  private $apolloHelpers!: Context['app']['$apolloHelpers']

  @Inject('ApolloClientService')
  private apolloClientService!: ApolloClientService

  private store = userStore

  get refreshTokenTimeout() {
    return this.store.refreshTokenTimeout
  }

  set refreshTokenTimeout(value) {
    this.store.setRefreshTokenTimeout(value)
  }

  async login(login: string, password: string) {
    const loginMutation = await this.apolloClientService.client.mutate<
      ApiLoginMutation,
      ApiLoginMutationVariables
    >({
      mutation: ApiLoginDocument,
      variables: {
        login,
        password,
      },
    })
    if (loginMutation.errors) {
      throw new Error(
        loginMutation.errors
          .map((e) => e?.message ?? 'Generic error')
          .join(', ')
      )
    }
    await this.updateToken(loginMutation.data?.api_login.accessToken ?? '')
  }

  async logout() {
    await this.$apolloHelpers.onLogout(this.apolloClientService.client)
  }

  async updateToken(token: string) {
    await this.$apolloHelpers.onLogin(token, this.apolloClientService.client)
  }

  get token(): string {
    return this.$apolloHelpers.getToken()
  }

  get tokenDecoded(): null | {
    'https://hasura.io/jwt/claims': {
      'x-hasura-allowed-roles': Array<GraphqlTypes.Role_Enum>
      'x-hasura-default-role': GraphqlTypes.Role_Enum
      'x-hasura-user-id': GraphqlTypes.User['id']
      'x-hasura-user-name':
        | GraphqlTypes.Agent['name']
        | GraphqlTypes.Customer['name']
      'x-hasura-agent-erp-id': GraphqlTypes.Agent['erp_id']
      'x-hasura-customer-erp-id': GraphqlTypes.Customer['erp_id']
      'x-hasura-subcustomer-id': GraphqlTypes.Subcustomer['id']
      iat: number
      exp: number
    }
  } {
    if (!this.token) {
      return null
    }
    return jwtDecode(this.token)
  }

  get userId(): string {
    if (!this.tokenDecoded) {
      return ''
    }
    return this.tokenDecoded['https://hasura.io/jwt/claims']['x-hasura-user-id']
  }

  get userName(): string {
    if (!this.tokenDecoded) {
      return ''
    }
    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-user-name'
    ]
  }

  get customerErpId(): string {
    if (!this.tokenDecoded) {
      return ''
    }
    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-customer-erp-id'
    ]
  }

  get subcustomerId(): string {
    if (!this.tokenDecoded) {
      return ''
    }
    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-subcustomer-id'
    ]
  }

  get agentErpId(): string {
    if (!this.tokenDecoded) {
      return ''
    }
    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-agent-erp-id'
    ]
  }

  get roles(): Array<GraphqlTypes.Role_Enum> {
    if (!this.tokenDecoded) {
      return []
    }

    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-allowed-roles'
    ]
  }

  get defaultRole(): GraphqlTypes.Role_Enum | null {
    if (!this.tokenDecoded) {
      return null
    }

    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-default-role'
    ]
  }

  get selectedRole(): GraphqlTypes.Role_Enum | null {
    if (!this.tokenDecoded) {
      return null
    }

    return this.tokenDecoded['https://hasura.io/jwt/claims'][
      'x-hasura-selected-role'
    ]
  }

  get isAuthenticated(): boolean {
    return !!this.$apolloHelpers.getToken()
  }

  get hasBackendAdminRole(): boolean {
    return (
      this.roles && this.roles.includes(GraphqlTypes.Role_Enum.BackendAdmin)
    )
  }

  get hasBackendSuperAdminRole(): boolean {
    return (
      this.roles &&
      this.roles.includes(GraphqlTypes.Role_Enum.BackendSuperAdmin)
    )
  }

  get hasAgentRole(): boolean {
    return this.roles && this.roles.includes(GraphqlTypes.Role_Enum.Agent)
  }

  get hasCustomerRole(): boolean {
    return this.roles && this.roles.includes(GraphqlTypes.Role_Enum.Customer)
  }

  get hasSubCustomerRole(): boolean {
    return this.roles && this.roles.includes(GraphqlTypes.Role_Enum.SubCustomer)
  }

  get hasDemoAgentRole(): boolean {
    return this.roles && this.roles.includes(GraphqlTypes.Role_Enum.DemoAgent)
  }

  get hasDemoCustomerRole(): boolean {
    return (
      this.roles && this.roles.includes(GraphqlTypes.Role_Enum.DemoCustomer)
    )
  }

  get hasSelectedDemoCustomerRole(): boolean {
    return this.selectedRole === GraphqlTypes.Role_Enum.DemoCustomer
  }

  get hasDemoSubCustomerRole(): boolean {
    return (
      this.roles && this.roles.includes(GraphqlTypes.Role_Enum.DemoSubCustomer)
    )
  }

  get hasDemoGenericRole(): boolean {
    return this.roles && this.roles.includes(GraphqlTypes.Role_Enum.DemoGeneric)
  }

  get hasDemoStockRole(): boolean {
    return this.roles && this.roles.includes(GraphqlTypes.Role_Enum.DemoStock)
  }

  get isAgent(): boolean {
    return (
      (this.hasAgentRole || this.hasDemoAgentRole) && this.agentErpId !== ''
    )
  }

  get isCustomer(): boolean {
    return (
      (this.hasCustomerRole ||
        this.hasDemoCustomerRole ||
        this.hasDemoGenericRole ||
        this.hasDemoStockRole) &&
      this.customerErpId !== ''
    )
  }

  get isSubCustomer(): boolean {
    return (
      (this.hasSubCustomerRole || this.hasDemoSubCustomerRole) &&
      this.customerErpId !== ''
    )
  }

  get isAgentCustomer(): boolean {
    return this.isAgent && this.customerErpId !== ''
  }

  get isBackendAdmin(): boolean {
    return this.hasBackendAdminRole
  }

  get isBackendSuperAdmin(): boolean {
    return this.hasBackendSuperAdminRole
  }

  async selectCustomer(id: string) {
    const selectCustomerMutation = await this.apolloClientService.client.mutate<
      ApiSelectCustomerMutation,
      ApiSelectCustomerMutationVariables
    >({
      mutation: ApiSelectCustomerDocument,
      variables: {
        id,
      },
    })
    return this.updateToken(
      selectCustomerMutation.data?.api_select_customer.accessToken ?? ''
    )
  }

  /**
   * Checks if current user has at least one role authorized on an array of roles
   * @param roles Can be an array of roles or * to match any
   * @private
   */
  inRoles(
    roles: Array<GraphqlTypes.Role_Enum | 'CustomerSelectedByAgent'> | '*'
  ): boolean {
    if (roles === '*') {
      return true
    }

    if (this.isAgentCustomer && roles.includes('CustomerSelectedByAgent')) {
      return true
    }

    return this.roles.some((r) => roles.includes(r))
  }

  async delete(variables: DeleteUserByPkMutationVariables) {
    const deleteMutation = await this.apolloClientService.client.mutate<
      DeleteUserByPkMutation,
      DeleteUserByPkMutationVariables
    >({
      mutation: DeleteUserByPkDocument,
      variables,
    })
    return deleteMutation.data?.delete_user_by_pk
  }
}
