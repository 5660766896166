// export default async (context, locale) => {
// return await Promise.resolve({
//
// })}
export default () => {
  return {
    '{imported} / {rows} rows imported from excel file - {productsImported} products':
      '{imported} / {rows} rows imported from excel file - {productsImported} products',
    'Amount to pay {amount} {currency} for the transaction ID {transactionId}':
      'Amount to pay: {amount} {currency}. Transaction: {transactionId}.',
    admin: {
      dragndrop: {
        cancel: {
          confirm: {
            message: 'Are you sure to cancel the operation?',
          },
          error: {
            message: 'There was an error canceling the operation. {error}',
          },
        },
        drop: {
          confirm: {
            message: 'Are you sure to drop the image?',
          },
          error: {
            message: 'There was an error deleting the image. {error}',
          },
          success: {
            message: 'Image successfully deleted',
          },
        },
        template: {
          cancel: 'Cancel',
          drop: 'Drop Image',
          label: 'Click or Drag N Drop Image',
          upload: 'Upload Image',
        },
        upload: {
          confirm: {
            message: 'Are you sure to update the image?',
          },
          error: {
            message: 'There was an error uploading the image.  {error}',
          },
          success: {
            message: 'Image successfully uploaded',
          },
        },
      },
      order: {
        cancel: {
          error: {
            message: 'There was an error canceling order',
          },
          success: {
            message: 'Cancel executed',
          },
        },
        capture: {
          error: {
            message: 'There was an error capturing order',
          },
          success: {
            message: 'Capture executed',
          },
        },
      },
      promo: {
        dateFrom: 'From',
        dateTo: 'To',
        discount: 'Discount',
        enable: 'Enable',
        maxQty: 'Max quantities',
        minQty: 'Min quantities',
        save: 'Save',
      },
      replacement: {
        action: {
          back: 'Back',
          status: { disabled: 'Deactivated', enabled: 'Activated' },
        },
        drop: {
          confirm: {
            message:
              'Removing this image make the client replacement view for the product {filename} unusable. Would you continue?',
          },
          error: {
            message: 'Action blocked from user before drop the image.',
          },
        },
        image: {
          validator: {
            message:
              "The image format is invalid. '{mimetypes}' types images with '{extensions}' extensions and {dimensions} pixel dimensions are acceptable.",
          },
        },
        template: {
          search: {
            label: 'Add part',
            placeholder: 'Search by replacement code',
          },
          title: 'Manage the replacements for the product "{name}"',
        },
      },
      replacements: {
        action: {
          manage: 'Manage settings',
        },
        manage: {
          action: {
            back: 'Back',
            save: 'Save',
          },
          maxOrderQty: {
            label: 'Max order quantity per replacement',
          },
          message: {
            success: 'Replacement settings updated successfully',
          },
        },
      },
    },
    api: {
      0: 'Application error',
      10000: 'Cart error',
      10400: 'The cart is empty',
      10401: 'Invalid customer',
      10404: 'Cart not found',
      11404: 'Active cart not found',
      12000: 'There was an error retrieving shipping information',
      12100: 'There was an issue saving address information',
      12404: 'Shipping address not found',
      12510: 'Selected drop-ship address is not valid for customer',
      12520: 'The customer is not allowed to use drop-ship addresses',
      12600: 'Missing required shipping information',
      13500: 'Shipping date must be a valid date',
      14000: 'Order notes error',
      15000: 'Cart item error',
      15500: 'Cart item quantity error',
      15600: 'Cart item discount error',
      15610: 'Cart item discount agent error',
      15620: 'Cart item discount agent error',
      15700: 'Cart item notes error',
      15800: 'Cart item box pieces error',
      16000: 'Cart replacement error',
      16500: 'Cart replacement quantity error',
      16510: 'Cart replacement quantity full error',
      16700: 'Cart replacement notes error',
      error: {
        0: 'Application error.',
        10000: 'An error occurred while processing the cart.',
        10400: 'The cart is empty. Check items please.',
        10401: 'Unauthorized action. The page will be reloaded to resolve the issue.',
        10404: 'Cart not found.',
        11404: 'Active cart not found.',
        12000:
          'There was an error retrieving shipping information. Please retry.',
        12100: 'There was an issue saving address information. Please retry.',
        12404: 'Shipping address not found.',
        12510:
          'Selected drop-ship address is not valid for customer. Please retry.',
        12520: 'The customer is not allowed to use drop-ship addresses.',
        12600: 'There are missing required shipping information.',
        13500: 'Shipping date must be a valid date between {min} and {max}.',
        14000: 'Order notes cannot exceeds {length} characters length.',
        15000: 'Cart item {item} error.',
        15500: 'Cart item {item} must have a positive quantity.',
        15600:
          'Cart item {item} must have a valid discount percentage between {min}% and {max}%.',
        15610: 'Cart item {item} agent discount not allowed',
        15620: 'Cart item {item} must have a valid discount percentage between {min}% and {max}%.',
        15700:
          'Cart item {item} notes cannot exceeds {length} characters length.',
        15800:
          'The quantities {quantity} of the item {item} in the cart must be a multiple of {boxPieces} due to its packaging.',
        16000: 'Cart replacement {replacement} error.',
        16500:
          'Cart replacement {replacement} must have a positive quantity and cannot exceeds {max} units.',
        16510:
          'Cart replacement {replacement} cannot exceeds {max} units per cart.',
        16700:
          'Cart replacement {replacement} notes cannot exceeds {length} characters length.',
      },
      info: {
        13500:
          'Shipping date must be a valid date between {min} and {max}. The date is set to: "{newData}".',
        14000:
          'The order notes cannot exceeds {length} characters length. The order notes are truncated to: "{newData}".',
        15500:
          'The cart item {item} must have a positive quantity. It is set to "{newData}".',
        15700:
          'The cart item {item} notes cannot exceeds {length} characters length. The cart item notes are truncated to: "{newData}".',
        15800:
          'The quantity of the item {item} in the cart must be a multiple of {boxPieces} due to its packaging. The quantity {quantity} of the item {item} is set to {newData}.',
      },
    },
    cart: {
      item: {
        action: {
          add: { label: 'Add' },
          addMore: 'More Quantity',
          excelLoad: { label: 'Upload Excel file', title: 'Upload Excel' },
          remove: { label: 'Remove' },
        },
        excel: {
          error: {
            invalid_file: 'Invalid file format',
            row: {
              invalid: 'Row {row}: column "{col}", "{value}" is not valid',
              not_found: 'Row {row}: The product {id} does not exist',
              qty_invalid:
                'Row {row}: quantity {qty} invalid, pack of {box_pieces}',
              required: 'Row {row}: column {col} - missing data',
            },
          },
          example: 'Download sample files',
          hint: 'Upload an excel file with the columns code, qt, reference keeping the header row.',
          rows: '{rows} rows ready to be imported',
          select_file: 'Select an excel file',
        },
        notes: { cancel: 'Cancel', label: 'Your ref.', update: 'Update' },
        price: {
          discountAgent: 'Agent discount',
          discountPercent: 'Discount',
          public: 'Public Price',
          realNoDiscount: 'Wholesale Price',
        },
        product: {
          replacement: {
            title: 'Replacements',
          },
        },
        quantity: {
          addMore: 'Add more',
          addNew: 'Add quantity',
          error: {
            text: 'Input quantity data invalid:  package of {steps} pieces.',
            title: 'Error',
          },
          label: 'Quantity',
          placeholder: '1',
          remove: 'Remove from cart',
          stepHelp: 'Package of {steps}',
        },
        search: { label: 'Search', placeholder: 'Start typing to search' },
        volumeNotDefined: 'N/A',
        weightNotDefined: 'N/A',
      },
      replacement: {
        action: {
          add: {
            label: 'Add to cart',
            message: {
              success: 'Replacement added to cart',
            },
          },
          close: { label: 'Close' },
          remove: {
            label: 'Remove from cart',
            message: {
              success: 'Replacement removed from cart',
            },
          },
        },
        code: 'Replacement: <strong>{replacement}</strong>',
        title: 'Replacements for {name}',
      },
      state: { sent: 'order proposal sent' },
      table: {
        footer: {
          discount: 'Cash discount',
          notax: 'Prices and Totals VAT included',
          shippingCost: 'Shipping cost',
          shippingExtraCost: 'Shipping extra cost',
          shippingMissingAmountNotice:
            'Missing amount to get the free shipping costs: {amount}',
          shippingThresholdNotice: 'Transport fees for orders under {amount}',
          subtotal: 'Total articles',
          tax: 'VAT Amount',
          total: 'Total order',
          volume: 'Total volume',
          weight: 'Total weight',
        },
        header: {
          action: 'Action',
          agentDiscount: 'Agent discount',
          code: 'Code',
          codeAndName: '[Code] Name',
          codeReplacement: 'Replacement',
          dimensions: 'Weight / Volume',
          image: 'image',
          name: 'Name',
          notes: 'Your ref.',
          price: 'Unit price',
          priceWithDiscount: 'Discounted price',
          quoteDiscountPercentage: 'Discount',
          quantity: 'Quantity',
          stock: 'Stock',
          tax: 'VAT',
          taxQuote: 'IVA/TAX',
          total: 'Row total',
          volume: 'Volume',
          weight: 'Weight',
        },
      },
    },
    checkRev: {
      newVersion: {
        cta: 'click here to update',
        label: 'New version available',
      },
      updateError: {
        cta: 'click here to find out how',
        line1: "Looks like you're having trouble updating.",
        line2: 'Try hard refreshing your browser manually.',
      },
    },
    communications: {
      action: {
        archive: 'Archive selected',
        archiveConfirmation: 'Are you sure you want to archive communications?',
        delete: 'Delete selected',
        deleteConfirmation: 'Are you sure you want to delete communications?',
        noPreview: 'The preview action is not enabled',
        preview: 'Preview',
        save: 'Save preference',
        select: 'Select',
      },
      code: {
        administrative: {
          communication: {
            service: 'Administrative/Accounting',
          },
        },
        'fairs-events': {
          communication: {
            service: 'Fairs and events',
          },
        },
        general: {
          communication: {
            service: 'Service communications',
          },
        },
        important: {
          communication: {
            service: 'Important communications',
          },
        },
        'incentive-plan': {
          communication: {
            service: 'Incentive plan',
          },
        },
        informative: {
          communication: {
            service: 'Quality and product information',
          },
        },
        mail: {
          transactional: {
            erp: {
              invoice: 'Invoices and Shipment Documents',
            },
            sales: {
              'create-order-invoice': 'Transactional Email: New Order Invoices',
              'create-order-product': 'Transactional Email: New Order Products',
              'update-order-canceled': 'Transactional Email: Order Deleted',
            },
          },
        },
        price: {
          communication: {
            service: 'Price list',
          },
        },
        promo: {
          communication: {
            service: 'Promotions',
          },
        },
      },
      link: {
        archive: 'Archive',
        back: 'Back',
        manage: 'Manage',
      },
      manage: {
        list: {
          table: {
            code: 'Type',
            isEnabledSite: 'Site',
            isEnabledEmail: 'Email',
            sendToEmail: 'Email addresses',
          },
        },
        localization:
          'Choose the language which we will send you communications: ',
      },
      table: {
        column: {
          code: 'Type',
          date: 'Date',
          title: 'Title',
          actions: 'Actions',
        },
      },
      title: 'Communications',
    },
    customer: {
      address: 'Address',
      code: 'Customer Code',
      company: 'Business name',
      currency: { rate: 'Exchange rate', updatedAt: 'Last update' },
      data: 'Customer details',
      email: 'Email',
      fax: 'Fax',
      fiscalCode: 'Fiscal code',
      identifier: 'customer code',
      label: 'Customer',
      name: 'Name',
      phone: 'Phone',
      surname: 'Surname',
      tax: 'IVA/TAX',
      vatNumber: 'VAT number',
      web: 'Web',
    },
    downloads: {
      documents: {
        action: 'Go to the page',
        text: 'Certifications and downloads relating to individual products.',
        title: 'Product documentation',
      },
      noFiles: '',
    },
    error: {
      401: '401 Unauthorized',
      403: '403 Forbidden',
      404: '404 Not Found',
      500: '404 Internal Server Error',
      expired: 'Authentication expired. New login is required',
      generic: 'An error occurred',
      message: {
        401: 'You must be authenticated to access the page',
        403: 'You are not authorized to access the page',
        404: 'The page you are looking for is not found',
        500: 'An internal server error has occurred',
      },
    },
    footer: {
      line1:
        'Ideal Lux s.r.l. | Legal head office: Via Taglio Destro, 32 30035 Mirano (VE) Italy | Headquarters: Via delle Industrie, 8/D 30036 Santa Maria di Sala (VE) | P.I, C.F e CCIAA 02420040277',
      line2:
        'Capitale sociale € 100.000 i.v. | e-mail info@ideal-lux.com | orario ufficio 8.00-12.00, 13.30-17.30 | tel +39 041 57 90 500 | fax +39 041 57 00 085',
    },
    form: {
      address: {
        city: { label: 'City' },
        country: { label: 'State' },
        create: 'Create',
        email: { label: 'Email' },
        error: 'Something went wrong',
        firstname: { label: 'Name' },
        headline: {
          address: 'Surname',
          contact: 'Contact info',
          contact_methods: 'Contact methods',
          mandatory: 'Contact',
        },
        lastname: { label: 'Surname' },
        line: { label: 'Address' },
        mandatory: { label: 'Ship to' },
        province: { label: 'Province' },
        telephone: { label: 'Phone' },
        title: 'Create a new address',
        zipcode: { label: 'Zipcode' },
      },
      help: { mandatory: 'These fields are mandatory' },
      input: { login: 'Login', password: 'Password' },
      newCustomer: {
        addAnother: 'Add another customer',
        Address: 'Address',
        City: 'City',
        Com_End_Series: 'End of series communication',
        Country_Region_Code: 'Country',
        County: 'County',
        Customer_Disc_Group: 'Discount group',
        Customer_Discount_Base: 'Base discount',
        Customer_Price_Group: 'Price group',
        Diff_Address: 'Address',
        Diff_City: 'City',
        Diff_Country_Region_Code: 'Country',
        Diff_County: 'County',
        Diff_Goods_Destination: 'Different goods destination',
        Diff_Post_Code: 'Postcode',
        Diff_Territory_Code: 'Region',
        Dimension_Code: 'Dimension code',
        E_Mail: 'E-mail',
        error: 'Something went wrong',
        Fiscal_Code: 'Fiscal Code',
        headline: {
          address: 'Address',
          agreedConditions: 'Agreed conditions',
          companyInfo: 'Company info',
          details: 'Details',
          diffAddress: 'Different goods destination',
          otherInformation: 'Other informations',
          pecOrUniqueCode:
            'Specify if you wish to receive electronic invoices via a certified e-mail address or a unique code',
        },
        IBAN: 'IBAN',
        Name: 'Name',
        Note: 'Notes',
        Office_Unique_Code: 'Unique code',
        Payment_Method_Code: 'Payment method',
        Payment_Terms_Code: 'Payment terms',
        Pec: 'Certified e-mail address',
        PEC_E_Mail: 'PEC e-mail address',
        Phone_No: 'Phone No',
        Platform: 'Specify site or platform',
        Post_Code: 'Postcode',
        Remaining_Management: 'Remaining management',
        Sell_On_WEB: 'Sell on WEB',
        send: 'Send',
        Shipment_Method_Code: 'Shipping method',
        success: 'Data submission completed',
        Territory_Code: 'Region',
        Unique_Code: 'Unique code',
        VAT_Registration_No: 'VAT registration No',
      },
      submit: { signIn: 'Enter' },
      validation: {
        message: {
          digits: 'Insert a number',
          email: 'Insert a valid email',
          limit: 'Max number of characters: {characters}',
          limitMin: 'Min number of characters: {characters}',
          max: 'Max value: {maxValue}',
          min: 'Min value: {minValue}',
          required: 'Insert a value',
          requiredFile: 'Select a file',
        },
      },
    },
    header: {
      currency: 'Currency',
      deselectCustomerLabel: 'Deselect customer',
      language: 'Language',
      logout: 'Logout',
      selectCustomerLabel: 'Choose customer',
    },
    'Invalid password': 'Invalid password',
    label: {
      bonus: {
        firstTarget: 'First target',
        inactive: 'No active bonus',
        invoiced: 'Revenue',
        missingAmountFromTarget: '{amount} missing to reach the target',
        percent: 'Bonus Percentage',
        prize: 'Prize',
        rebate: 'Bonus',
        secondTarget: 'Second target',
        summary: 'Summary',
        thirdTarget: 'Third Target',
      },
      buy: 'Buy',
      fileUnavailable: 'File not available',
      open: 'open',
      quantity: 'Quantity',
    },
    login: {
      productionLink: 'https://www.ideal-lux.com/en/reserved-area',
    },
    mail: {
      action: {
        back: 'Back',
      },
      createdAt: 'Created at: {date}',
      header: {
        bcc: 'BCC: {bcc}',
        cc: 'CC: {cc}',
        from: 'FROM: {from}',
        to: 'TO: {to}',
      },
      id: 'Mail ID: {id}',
      logs: 'Logs',
      send: {
        error: {
          message: 'Something went wrong! Your message has not been sent.',
        },
        sending: {
          message: 'Sending Message...',
        },
        submit: {
          message: 'Submit',
        },
        success: {
          message: 'Great! Your message has been sent successfully.',
        },
      },
      status: 'Status: {status}',
      updatedAt: 'Last update at: {date}',
    },
    order: {
      dataLabel: 'Order Data',
      date: 'Date',
      error: { notFound: 'Order not found' },
      exceedingStock: 'Order subject to confirmation by the company.',
      from: 'Order done by',
      no: 'Order N',
      note: 'Notes',
      payment: 'Payment condition',
      print: 'Print',
      reference: 'Agent',
      shipping: {
        date: 'Shipping',
        destination: {
          address: 'Address',
          label: 'Delivery address',
          name: 'Company address',
        },
        method: 'Shipping Method',
        mode: 'Shipping Mode',
      },
      state: {
        active: 'pending confirmation',
        canceled: 'order canceled',
        complete: 'ongoing',
        label: 'Status',
        new: 'pending payment',
        pending: 'authorized, pending payment',
        processing: 'Inserted',
        shipped: 'Shipped',
      },
      table: {
        agentDiscount: 'Agent discount',
        amount: 'Row total',
        invoiceAmount: 'Amount',
        invoiceItem: 'Invoice',
        item: 'Item',
        notes: 'Additional notes',
        product_amount: 'Unit price',
        product_amount_with_discount: 'Discounted price',
        quote_discount_percentage: 'Discount',
        qty: 'Quantity',
        tax: 'VAT',
        volume: 'Volume',
        weight: 'Weight',
      },
      totals: {
        discount: 'Final Discount',
        invoiceTotal: 'Invoice totals',
        shipmentCost: 'Shipment cost',
        shipmentExtraCost: 'Shipping extra cost',
        shipmentFree: 'Free shipping',
        shipmentThresholdNotice: 'Transport fees for orders under',
        subtotals: 'Subtotal',
        tax: 'VAT Amount',
        total: 'Total',
        volume: 'Total Volume',
        weight: 'Total Weight',
      },
    },
    page: {
      admin: {
        admins: 'Administrators',
        communicationCodes: 'Communication Codes',
        communicationMails: 'Mailup Communications',
        customers: 'Customers',
        'download-agent': 'Agent download',
        'download-customer': 'Customer download',
        mail: 'Mail',
        mailSend: 'Send Mail',
        orders: 'Orders',
        promo: 'Promotions',
        'product-replacement': 'Product replacement',
        'product-replacements': {
          index: 'Replacements',
          manage: 'Replacements settings',
        },
        subcustomers: 'Subcustomers',
        translate: 'Translate',
      },
      backOrders: 'Back orders',
      canceledOrder: 'Order canceled',
      communications: {
        archive: 'Archive',
        index: 'Communications',
        manage: 'Manage',
      },
      creditNotes: 'Credit notes',
      ddt: 'Shipment Documents',
      download: 'Download',
      downloadProducts: 'Product information material',
      home: 'Home',
      invoice: 'Invoice',
      invoices: 'Invoices',
      invoicesAndCreditNotes: 'Invoices/Credit notes',
      newCustomer: 'New customer',
      newOrder: 'New order',
      quotes: 'Quotes',
      newProposal: 'New proposal',
      order: 'Confirmed Order',
      orders: 'Orders',
      pendingInvoices: 'Pending Invoices',
      pendingOrder: 'Order with Pending Payment',
      pendingOrders: 'Orders with Pending Payment',
      promo: 'Promotions',
      promotionalOffers: 'Promotional offers',
      proposals: 'Proposals sent',
      proposalSent: 'Proposal schedule',
      proposalsOrders: 'Orders (to Supplier)',
      proposalsReceived: 'Proposals received',
      proposalsSent: 'Order proposals (to Distributor)',
      rebate: {
        active: 'Rebate {year}',
        canBeActive: 'Rebate {year} - <strong>TO ACTIVATE</strong>',
        inactive: 'Rebate - <strong>NOT ACTIVE</strong>',
        title: 'Rebate',
      },
      rebateAgent: {
        active: 'Rebate {year}',
        canBeActive: 'Rebate {year} - <strong>TO ACTIVATE</strong>',
        inactive: 'Rebate - <strong>NOT ACTIVE</strong>',
        title: 'Agent rebate',
      },
      reports: 'Report',
      statistics: 'Statistics',
    },
    'Pay using:': 'Pay using: {method}',
    payment: {
      erp: {
        title: 'Offline Payments',
      },
      nexi: {
        brand: {
          '-': { description: 'Mastercard - VISA - PayPal' },
          ae: { description: 'American Express' },
          amex: { description: 'American Express' },
          americanexpress: { description: 'American Express' },
          bancomat_pay: { description: 'BANCOMAT Pay' },
          diners: { description: 'DINERS' },
          jcb: { description: 'JCB' },
          mastercard: { description: 'Mastercard' },
          mc: { description: 'Mastercard' },
          paypal: { description: 'PayPal' },
          visa: { description: 'VISA' },
        },
        description: 'Mastercard - VISA - PayPal',
        title: 'NEXI XPay',
      },
      nexigroup: {
        brand: {
          '-': { description: 'Mastercard - VISA - PayPal' },
          ae: { description: 'American Express' },
          amex: { description: 'American Express' },
          americanexpress: { description: 'American Express' },
          bancomat_pay: { description: 'BANCOMAT Pay' },
          diners: { description: 'DINERS' },
          jcb: { description: 'JCB' },
          mastercard: { description: 'Mastercard' },
          mc: { description: 'Mastercard' },
          paypal: { description: 'PayPal' },
          visa: { description: 'VISA' },
        },
        description: 'Mastercard - VISA - PayPal',
        title: 'NEXI XPay',
      },
    },
    productCard: { button: { add: 'Add', remove: 'Remove' } },
    promo: {
      popup: {
        action: {
          goto: 'Go now',
          skip: 'Close',
        },
        label:
          'Promo section active for a limited period. During this period it will be possible to place only one order.',
      },
      table: {
        article: 'Item',
        buy: 'Buy 1 pc.',
        code: 'Code',
        discount: 'Discount',
        image: 'Image',
        promoPrice: 'Promotional Price',
        startPrice: 'Initial Price',
      },
    },
    promotionalOffers: {
      table: {
        article: 'Item',
        buy: 'Quantity',
        code: 'Code',
        discount: 'Discount',
        image: 'Image',
        promoPrice: 'Promotional Price',
        startPrice: 'Initial Price',
      },
    },
    proposal: {
      addToOrder: 'Add to order',
      createdBy: 'Proposal made by',
      error: { notFound: 'Proposal not found' },
      no: 'Proposal N',
    },
    quote: {
      addToOrder: 'Add to order',
      endCustomerModeLabel: 'Public price list',
      header: {
        email: 'Email',
        address: 'Address "Street, Civic, City (Prov.) Zip Code, Country"',
        name: 'Name',
        phone: 'Phone',
        surname: 'Surname',
        tax: 'IVA/TAX',
      },
      no: 'Quote N',
      print: 'Print',
      sendEmail: 'Send via mail',
      sendEmailMessageOK: 'Email sent successfully',
      state: {
        active: 'Open',
        imported: 'Imported',
        ordered: 'Ordered',
        sent: 'Sent',
      },
    },
    reports: {
      date: 'Date',
      download: 'Download',
      monthly: 'Progressive monthly report',
      weekly: 'Progressive weekly report',
      yearly: 'Monthly reports',
    },
    sales: {
      buy: { label: 'Send order and pay' },
      currency: 'Currency',
      order: {
        btn: 'orders to check',
        label: 'There are some orders waiting for payment:',
        note: 'Notes of the order',
      },
      invoices: {
        btn: 'LIST OF INVOICES TO BE VERIFIED',
        label: 'There are outstanding invoices waiting for payment',
      },
      payment: {
        description: 'Choose your payment method:',
        title: 'Payment methods',
      },
      proceed: { label: 'Proceed to order' },
      proposals: {
        btn: 'List of proposals received',
        label: 'There are proposals to be verified',
      },
      review: { title: 'Confirm and send order' },
      rma: 'Transport modality',
      send: { label: 'Submit order' },
      shipment: {
        date: {
          description: 'Choose departure date of goods (shipping/collection)',
          title: 'Shipping/ collection of goods',
        },
        destination: {
          description: 'Choose one of your registered destinations',
          label: 'Chosen address',
          title: 'Shipping address',
        },
        title: 'Choose destination',
      },
      step: {
        action: {
          back: 'Back',
          complete: 'Complete the order',
          confirmProposal: 'Proposal confirmation',
          continue: 'Continue',
          empty: 'Empty',
          forward: 'Continue Shopping',
          invalidQuantitiesMax: 'Maximum quantity of {max} items exceeded',
          invalidQuantitiesMin: 'Minimum quantity of {min} items not reached',
          sendProposal: 'Send proposal',
        },
        cart: { label: 'Edit your order' },
        payment: { label: 'Payment methods' },
        popup: {
          freeShipping: {
            action: { continue: 'Review cart', proceed: 'Ignore and proceed' },
            label:
              'Transport fees for orders under {threshold}. Missing amount to get the free shipping costs: {amount}.',
          },
        },
        review: { label: 'Order confirmation' },
        shipment: { label: 'Shipping details' },
      },
      terms: {
        commercial: 'Sales conditions',
        discount: 'discount',
        notes:
          'Valid for all articles, promotional and discounted items excluded.',
        payment: 'Payment terms',
        'out-of-stock': 'goods not available',
      },
      updates: {
        destination: 'Delivery address: choice in the next step',
        qty: 'Update availability',
      },
    },
    shipping: { mode: { dropship: { description: 'dropshipping' } } },
    table: {
      admin: {
        admins: {
          actions: {
            add: 'Add administrator',
            delete: 'Delete',
            deleteConfirmation:
              'Are you sure you want to delete this administrator?',
            edit: 'Edit',
            save: 'Save',
          },
          column: {
            actions: 'Actions',
            createdAt: 'Created at',
            login: 'Username',
            updatedAt: 'Updated at',
          },
        },
        customers: {
          show_only_authorized: 'Show only authorized customers',
          actions: {
            edit: 'Edit',
            save: 'save',
          },
          column: {
            actions: 'Actions',
            createdAt: 'Created at',
            code: 'Customer code',
            name: 'Name',
            updatedAt: 'Updated at',
          },
          label: {
            authorizedEmails: 'Authorized emails',
            authorizedEmailsHint: 'Insert one or more email and press enter',
            emailSubjects: 'Allowed subjects',
            emailSubjectsHint:
              'If empty the customer code will be used as default',
          },
        },
        communicationCodes: {
          actions: {
            add: 'Add code',
            delete: 'Remove',
            deleteConfirmation: 'Are you sure to remove this code?',
            edit: 'Edit',
            save: 'Save',
          },
          column: {
            actions: 'Actions',
            code: 'Code',
            active: 'Enabled',
            provider: 'Provider',
            createdAt: 'Created at',
            updatedAt: 'Last edit',
          },
        },
        communicationMails: {
          actions: {
            add: 'Add Mailup Communication',
            delete: 'Remove',
            deleteConfirmation: 'Are you sure to remove this communication?',
            edit: 'Edit',
            save: 'Save',
          },
          column: {
            actions: 'Actions',
            agents: 'Agents',
            customers: 'Customers',
            countries: 'Countries',
            code: 'Group code',
            identifier: 'Identifier',
            search_criteria: 'Search criteria',
            createdAt: 'Created at',
            updatedAt: 'Last edit',
            title: 'Title',
          },
        },
        downloads: {
          actions: {
            add: 'Add new file',
            delete: 'Cancel',
            deleteConfirmation: 'Are you sure you want to delete this file?',
            edit: 'Edit',
            save: 'Save',
          },
          column: { actions: 'Actions', file: 'File', sorter: 'Sorting' },
          edit: {
            active: 'Active',
            file: 'Upload File',
            name: 'Name',
            preview: 'Preview',
            uploadedFile: 'File',
          },
        },
        mail: {
          actions: {
            preview: 'Preview',
            retry: 'Retry',
            retryConfirmation:
              'Are you sure you want to retry to send this mail?',
          },
          column: {
            actions: 'Actions',
            createdAt: 'Created at',
            from: 'Sender',
            id: 'ID',
            status: 'Status',
            subject: 'Subject',
            to: 'Recipient',
            updatedAt: 'Date',
          },
          status: { error: 'ERROR', notsent: 'NOT SENT', sent: 'SENT' },
        },
        orders: {
          actions: {
            cancel: 'Cancel',
            capture: 'Capture',
            preview: 'Preview',
            cancelConfirmation:
              'Are you sure you want to force cancel the order?',
            captureConfirmation:
              'Are you sure you want to force capture the order?',
          },
          column: {
            actions: 'Actions',
            amount: 'Amount',
            createdAt: 'Created at',
            customer_erp_id: 'Customer ID',
            id: 'ID',
            increment_id: 'Increment ID',
            payment: 'Payment',
            status: 'Status',
            type: 'Type',
            updatedAt: 'Date',
          },
          status: {
            new: 'pending payment',
            pending: 'authorized, pending payment',
          },
        },
        replacements: {
          actions: {
            edit: 'Spare parts map',
          },
          column: {
            erp_id: 'Product',
            count: 'Mapped parts',
          },
        },
        subcustomers: {
          actions: {
            add: 'Add subcustomer',
            delete: 'Delete',
            deleteConfirmation: 'Are you sure you want to delete this user?',
            edit: 'Edit',
            save: 'Save',
          },
          column: {
            actions: 'Actions',
            createdAt: 'Created at',
            customer: 'Customer',
            login: 'Username',
            updatedAt: 'Updated at',
          },
        },
      },
      creditmemo: { column: { date: 'Date', download: 'Download', no: 'N°' } },
      customer: { statistic: { column: { amount: 'Amount', year: 'Year' } } },
      download: { column: { files: 'File', name: 'Name' } },
      input: { search: { label: 'Search' } },
      invoice: {
        accounting: 'Paid, pending validation',
        column: {
          amount: 'Amount',
          amountExpired: 'Expired amount',
          date: 'Date',
          download: 'Download',
          no: 'N',
        },
        detail: {
          about: 'Payment details',
          amount: 'Amount',
          order: 'Order',
          status: 'Status',
        },
        paid: 'Paid',
        payableMessage:
          "There are overdue amounts for some invoices, you can pay them directly from here by selecting them and clicking 'PAY NOW'",
        payNow: 'Pay now',
        select: 'Select',
        showOnlyExpired: 'Show only overdue amounts',
        status: {
          complete: 'Validating',
          processing: 'Pending validation',
          verified: 'Verified',
        },
        topay: 'Amount to pay',
      },
      order: {
        confirmed: {
          column: {
            confirmed: 'Confirmed',
            date: 'Date',
            download: 'Download',
            no: 'N',
            orderWeb: 'Web order',
            shipped: 'Shipped',
            shipping: 'Carrying out',
          },
        },
        item: {
          unshipped: {
            column: {
              articleCode: 'Art. code',
              articleName: 'Art. name',
              availabilityDate: 'Date of availability',
              currentOrderN: 'Order N°',
              originalOrderDate: 'Orig. Order date',
              originalOrderN: 'Orig. order N°',
              quantity: 'Quantity',
            },
          },
        },
      },
      pending: {
        actions: {
          cancel: 'Cancel order',
          cancelConfirm:
            'Do you confirm to cancel order <strong>{id}</strong>?',
          cancelConfirmPayment:
            'Do you confirm to cancel payment: <strong>{id}</strong>?',
          cancelInvoicePayment: 'Cancel payment',
          cancelPayment: 'Cancel payment',
          close: 'Close',
          goToOrder: 'Go to order',
          retry: 'Retry payment',
          send: 'Send order',
          sendDescription: 'Send order with payment methods',
        },
        invoices: {
          column: {
            actions: 'Actions',
            amount: 'Total',
            date: 'Date',
            invoices: 'Invoices group',
            no: 'N°',
            status: 'Satus',
          },
          status: {
            new: 'Invoice with pending payment',
            pending: 'Invoice with pending payment',
          },
        },
        messages: {
          canceled: 'Order successfully canceled',
          error: 'There was an error sending order',
          sent: 'Order successfully sent',
        },
        orders: {
          actions: 'Actions',
          amount: 'Total',
          date: 'Date',
          no: 'N°',
          status: 'Status',
        },
      },
      productfiles: {
        code: 'Code',
        column: {
          image: 'Image',
          article: 'Item',
          files: '',
        },
        btn: {
          folder: {
            classe_energetica: 'Energy efficiency class',
            istruzioni: 'Assembly instructions',
            scheda_prodotto: 'Product profile',
          },
        },
      },
      proposalsOrders: {
        column: { date: 'Date', no: 'no.', status: 'Order in progress status' },
      },
      proposalsReceived: {
        column: {
          date: 'Date',
          no: 'no.',
          open: 'Open',
          status: 'Order in progress status',
        },
      },
      proposalsSent: {
        column: { date: 'Date', no: 'no.', status: 'Order in progress status' },
      },
      quotes: {
        actions: {
          add: 'Add quote',
          delete: 'Delete',
          deleteConfirm: 'Quote was successfully deleted',
          deleteConfirmation: 'Are you sure you want to delete this quote?',
          edit: 'Edit',
          save: 'Save',
        },
        column: {
          actions: 'Actions',
          createdAt: 'Created at',
          id: 'no.',
          name: 'Name',
          surname: 'Surname',
          status: 'Status',
        },
      },
      shipment: {
        document: { column: { date: 'Date', download: 'Download', no: 'N°' } },
      },
      shipping: {
        action: {
          new: { label: 'Add destination' },
          search: { label: 'Find address...' },
        },
        column: { name: { label: 'Name' } },
        notfound: 'No valid address found',
        onlyDropShipping: 'Only Dropshipping',
      },
    },
    terms: {
      method: {
        ANTICIPO: { description: 'Order deposit' },
        VISTA: { description: 'receipt of invoice' },
      },
    },
    'There was an error creating transaction for the amount to pay {amount} {currency}':
      'Something wen wrong while creating the transaction for payment {amount} {currency}.',
    transaction: {
      back: { message: 'Re-try to pay.' },
      error: { message: 'Payment refused.' },
      success: { message: 'Payment successfully.' },
    },
    'User not found': 'User not found',
    'You will be redirected to:': 'You will be redirected to: {method}',
  }
}
