import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

@Module({
  name: 'file',
  stateFactory: true,
  namespaced: true,
})
export default class File extends VuexModule {
  updating = false
  deleting = false

  @Mutation
  setUpdating(updating) {
    this.updating = updating
  }

  @Mutation
  setDeleting(updating) {
    this.deleting = updating
  }
}
