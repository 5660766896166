import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { proposalSentStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'
import {
  ApiSalesManagerCollectTotalsDocument,
  ApiSalesManagerCollectTotalsQuery,
  ApiSalesManagerCollectTotalsQueryVariables,
  ProposalDocument,
  ProposalSubscription,
  ProposalSubscriptionVariables,
} from '~/graphql/types'
import { CartTotals } from '~/store/sales-cart-abstract'

export class ProposalSentService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = proposalSentStore

  get commonWhere() {
    const where: ProposalSubscriptionVariables['where'] = {
      customer_erp_id: {
        _eq: this.userService.customerErpId,
      },
      status: {
        _eq: 'sent',
      },
      type: {
        _eq: 'proposal',
      },
    }

    if (this.userService.subcustomerId) {
      where.subcustomer_id = {
        _eq: this.userService.subcustomerId,
      }
    } else {
      where.subcustomer_id = {
        _is_null: false,
      }
    }

    return where
  }

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    const where = { ...this.commonWhere }

    const data = (
      await this.apolloClientService.client.query<
        ProposalSubscription,
        ProposalSubscriptionVariables
      >({
        query: ProposalDocument,
        variables: {
          where,
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.cart)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get items() {
    return this.store.items
  }

  async getByIncrementId(id: string | number) {
    const where = { ...this.commonWhere }

    where.id = {
      _eq: id,
    }

    const proposal = (
      await this.apolloClientService.client.query<
        ProposalSubscription,
        ProposalSubscriptionVariables
      >({
        query: ProposalDocument,
        variables: {
          where,
        },
      })
    ).data.cart[0]
    if (!proposal) {
      throw new Error('proposal not fetched')
    }

    let cartTotals: CartTotals | null = null
    if (!this.userService.isSubCustomer) {
      const cartTotalsQueryResult = await this.apolloClientService.client.query<
        ApiSalesManagerCollectTotalsQuery,
        ApiSalesManagerCollectTotalsQueryVariables
      >({
        query: ApiSalesManagerCollectTotalsDocument,
        variables: {
          id: id.toString(),
        },
      })
      cartTotals = JSON.parse(
        cartTotalsQueryResult.data?.api_sales_manager_collect_totals.result ??
          ''
      )
      if (!cartTotals) {
        throw new Error('cartTotals not fetched')
      }
    }

    return {
      proposal,
      cartTotals,
    }
  }
}
