import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { communicationCodesStore } from '~/utils/store-accessor'
import {
  CommunicationCodesDocument,
  CommunicationCodesQuery,
  CommunicationCodesQueryVariables,
  DeleteCommunicationCodeDocument,
  DeleteCommunicationCodeMutation,
  DeleteCommunicationCodeMutationVariables,
  InsertCommunicationCodeDocument,
  InsertCommunicationCodeMutation,
  InsertCommunicationCodeMutationVariables,
  UpdateCommunicationCodeDocument,
  UpdateCommunicationCodeMutation,
  UpdateCommunicationCodeMutationVariables,
} from '~/graphql/types'

export class CommunicationCodesService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  private store = communicationCodesStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        CommunicationCodesQuery,
        CommunicationCodesQueryVariables
      >({
        query: CommunicationCodesDocument,
        variables: {},
      })
    ).data

    if (data) {
      this.store.setItems(data.communication_code)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get codes() {
    return this.store.items
  }

  async deleteByCode(code: string) {
    const deletedCode = await this.apolloClientService.client.mutate<
      DeleteCommunicationCodeMutation,
      DeleteCommunicationCodeMutationVariables
    >({
      mutation: DeleteCommunicationCodeDocument,
      variables: {
        code,
      },
    })
    return !!deletedCode.data?.delete_communication_code_by_pk?.code
  }

  async update(
    code: string,
    codeProps: {
      is_enabled: boolean
      code: string
      provider: string
    }
  ) {
    const updatedCode = await this.apolloClientService.client.mutate<
      UpdateCommunicationCodeMutation,
      UpdateCommunicationCodeMutationVariables
    >({
      mutation: UpdateCommunicationCodeDocument,
      variables: {
        code,
        _set: codeProps,
      },
    })
    return !!updatedCode.data?.update_communication_code_by_pk?.code
  }

  async insert(codeProps: {
    is_enabled: boolean
    code: string
    provider: string
  }) {
    const insertedCode = await this.apolloClientService.client.mutate<
      InsertCommunicationCodeMutation,
      InsertCommunicationCodeMutationVariables
    >({
      mutation: InsertCommunicationCodeDocument,
      variables: {
        object: {
          ...codeProps,
          is_external: true,
          is_list: true,
        },
      },
    })
    return !!insertedCode.data?.insert_communication_code_one?.code
  }
}
