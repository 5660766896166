import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  OrderConfirmedDocument,
  OrderConfirmedQuery,
  OrderConfirmedQueryVariables,
} from '~/graphql/types'
import { orderConfirmedStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class OrderConfirmedService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = orderConfirmedStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        OrderConfirmedQuery,
        OrderConfirmedQueryVariables
      >({
        query: OrderConfirmedDocument,
        variables: {
          where: {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.order_confirmed)
    }
    this.store.setLoading(false)
  }

  get items() {
    return this.store.items
  }
}
