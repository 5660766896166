import { Inject } from 'inversify-props'
import { SalesCartPromoService } from '~/services/sales-cart-promo.service'
import {
  NewAbstractService,
  StepperStep,
} from '~/services/new-abstract.service'
import NewAbstract from '~/store/new-abstract'
import { newPromoStore } from '~/utils/store-accessor'

export class NewPromoService extends NewAbstractService {
  @Inject('SalesCartPromoService') salesCartService!: SalesCartPromoService

  getStore(): NewAbstract {
    return newPromoStore
  }

  getSteps(): StepperStep[] {
    return [
      { step: 1, stepLabelKey: 'sales.step.cart.label' },
      { step: 2, stepLabelKey: 'sales.step.shipment.label' },
      { step: 3, stepLabelKey: 'sales.step.payment.label' },
      { step: 4, stepLabelKey: 'sales.step.review.label' },
    ]
  }
}
