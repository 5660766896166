import 'reflect-metadata'
import { Plugin } from '@nuxt/types'
import { ApolloProvider } from 'vue-apollo/types/apollo-provider'
import { ApolloHelpers } from '@nuxtjs/apollo'
import { container } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { SystemMessageService } from '~/services/system-message.service'
import { CreditMemoService } from '~/services/credit-memo.service'
import { RebateService } from '~/services/rebate.service'
import { AgentService } from '~/services/agent.service'
import { CustomerService } from '~/services/customer.service'
import { CustomerStatisticService } from '~/services/customer-statistic.service'
import { ExchangeRateService } from '~/services/exchange-rate.service'
import { InvoiceService } from '~/services/invoice.service'
import { ReportService } from '~/services/report.service'
import { OrderConfirmedService } from '~/services/order-confirmed.service'
import { OrderService } from '~/services/order.service'
import { OrderItemUnshippedService } from '~/services/order-item-unshipped.service'
import { ProductService } from '~/services/product.service'
import { ProductPriceService } from '~/services/product-price.service'
import { ReplacementService } from '~/services/replacement.service'
import { SalesCartOrderService } from '~/services/sales-cart-order.service'
import { SalesCartProposalService } from '~/services/sales-cart-proposal.service'
import { SalesTransactionService } from '~/services/sales-transaction.service'
import { ShipmentDocumentService } from '~/services/shipment-document.service'
import { UserService } from '~/services/user.service'
import { DownloadService } from '~/services/download.service'
import { CustomerAddressService } from '~/services/customer-address.service'
import { CustomerPaymentService } from '~/services/customer-payment.service'
import { LayoutService } from '~/services/layout.service'
import { NewOrderService } from '~/services/new-order.service'
import { NewPromoService } from '~/services/new-promo.service'
import { OrderPendingService } from '~/services/order-pending.service'
import { BackendAdminService } from '~/services/backend-admin.service'
import { SubcustomerService } from '~/services/subcustomer.service'
import { ShippingMethodService } from '~/services/shipping-method.service'
import { CustomerAddressDropshippingService } from '~/services/customer-address-dropshipping.service'
import { FileService } from '~/services/file.service'
import { FileDownloadService } from '~/services/file-download.service'
import { MailService } from '~/services/mail.service'
import { CommunicationService } from '~/services/communication.service'
import { CommunicationCodesService } from '~/services/communication-codes.service'
import { CommunicationMailService } from '~/services/communication-mail.service'
import { NewProposalService } from '~/services/new-proposal.service'
import { ProposalSentService } from '~/services/proposal-sent.service'
import { QuoteService } from '~/services/quote.service'
import { QuoteListService } from '~/services/quote-list.service'
import { SalesCartQuoteService } from '~/services/sales-cart-quote.service'
import { CountryRegionService } from '~/services/country-region.service'
import { ProductFilesService } from '~/services/product-files.service'
import { SalesCartPromoService } from '~/services/sales-cart-promo.service'
import { SettingService } from '~/services/setting.service'
import { OrderPromoService } from '~/services/order-promo.service'
import { AdminOrderService } from '~/services/admin-order.service'

const servicesPlugin: Plugin = (ctx) => {
  container
    .bind<ApolloProvider['defaultClient']>('$apollo')
    .toConstantValue(ctx.app.apolloProvider?.defaultClient)
  container
    .bind<ApolloHelpers>('$apolloHelpers')
    .toConstantValue(ctx.app.$apolloHelpers)

  container.addSingleton(ApolloClientService, 'ApolloClientService')

  container.addSingleton(SystemMessageService, 'SystemMessageService')

  container.addSingleton(MailService, 'MailService')
  container.addSingleton(CommunicationService, 'CommunicationService')
  container.addSingleton(CommunicationCodesService, 'CommunicationCodesService')
  container.addSingleton(CommunicationMailService, 'CommunicationMailService')
  container.addSingleton(CreditMemoService, 'CreditMemoService')
  container.addSingleton(RebateService, 'RebateService')
  container.addSingleton(AgentService, 'AgentService')
  container.addSingleton(CustomerService, 'CustomerService')
  container.addSingleton(CustomerAddressService, 'CustomerAddressService')
  container.addSingleton(
    CustomerAddressDropshippingService,
    'CustomerAddressDropshippingService'
  )
  container.addSingleton(CustomerPaymentService, 'CustomerPaymentService')
  container.addSingleton(CustomerStatisticService, 'CustomerStatisticService')
  container.addSingleton(ExchangeRateService, 'ExchangeRateService')
  container.addSingleton(FileService, 'FileService')
  container.addSingleton(FileDownloadService, 'FileDownloadService')
  container.addSingleton(InvoiceService, 'InvoiceService')
  container.addSingleton(ReportService, 'ReportService')
  container.addSingleton(OrderConfirmedService, 'OrderConfirmedService')
  container.addSingleton(OrderItemUnshippedService, 'OrderItemUnshippedService')
  container.addSingleton(ProductService, 'ProductService')
  container.addSingleton(ProductPriceService, 'ProductPriceService')
  container.addSingleton(ProductFilesService, 'ProductFilesService')
  container.addSingleton(ReplacementService, 'ReplacementService')
  container.addSingleton(SalesCartOrderService, 'SalesCartOrderService')
  container.addSingleton(SalesCartProposalService, 'SalesCartProposalService')
  container.addSingleton(SalesTransactionService, 'SalesTransactionService')
  container.addSingleton(ShipmentDocumentService, 'ShipmentDocumentService')
  container.addSingleton(ShippingMethodService, 'ShippingMethodService')
  container.addSingleton(UserService, 'UserService')
  container.addSingleton(DownloadService, 'DownloadService')
  container.addSingleton(OrderService, 'OrderService')
  container.addSingleton(OrderPendingService, 'OrderPendingService')
  container.addSingleton(LayoutService, 'LayoutService')
  container.addSingleton(NewOrderService, 'NewOrderService')
  container.addSingleton(NewPromoService, 'NewPromoService')
  container.addSingleton(NewProposalService, 'NewProposalService')
  container.addSingleton(BackendAdminService, 'BackendAdminService')
  container.addSingleton(SubcustomerService, 'SubcustomerService')
  container.addSingleton(ProposalSentService, 'ProposalSentService')
  container.addSingleton(QuoteService, 'QuoteService')
  container.addSingleton(QuoteListService, 'QuoteListService')
  container.addSingleton(SalesCartQuoteService, 'SalesCartQuoteService')
  container.addSingleton(SalesCartPromoService, 'SalesCartPromoService')
  container.addSingleton(CountryRegionService, 'CountryRegionService')
  container.addSingleton(SettingService, 'SettingService')
  container.addSingleton(OrderPromoService, 'OrderPromoService')
  container.addSingleton(AdminOrderService, 'AdminOrderService')
}
export default servicesPlugin
