import 'reflect-metadata'
import { Inject } from 'inversify-props'
import moment from 'moment'
import {
  SettingDocument,
  SettingSubscription,
  SettingSubscriptionVariables,
  UpdatePromoSettingsDocument,
  UpdatePromoSettingsMutation,
  UpdatePromoSettingsMutationVariables,
  UpdateReplacementSettingsDocument,
  UpdateReplacementSettingsMutation,
  UpdateReplacementSettingsMutationVariables,
} from '~/graphql/types'
import { settingStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'
import { OrderPromoService } from '~/services/order-promo.service'

export class SettingService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService
  @Inject('OrderPromoService') orderPromoService!: OrderPromoService

  public store = settingStore

  private _o: ZenObservable.Subscription | undefined

  subscribe() {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<SettingSubscription, SettingSubscriptionVariables>({
          query: SettingDocument,
          variables: {
            where: {
              key: {
                _in: [
                  'promoEnable',
                  'promoDiscount',
                  'promoDateFrom',
                  'promoDateTo',
                  'promoMinQty',
                  'promoMaxQty',
                  'replacementMaxOrderQty',
                ],
              },
            },
          },
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.setting)
          }
          this.store.setLoading(false)
          if (
            this.userService.hasCustomerRole ||
            this.userService.hasDemoCustomerRole
          ) {
            this.orderPromoService.unsubscribe()
            this.orderPromoService.subscribe(
              this.promoDateFrom,
              this.promoDateTo
            )
          }
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  async updatePromo(variables: UpdatePromoSettingsMutationVariables) {
    const result = (
      await this.apolloClientService.client.mutate<
        UpdatePromoSettingsMutation,
        UpdatePromoSettingsMutationVariables
      >({
        mutation: UpdatePromoSettingsDocument,
        variables,
      })
    ).data?.insert_setting

    return result?.affected_rows
  }

  async updateReplacement(
    variables: UpdateReplacementSettingsMutationVariables
  ) {
    const result = (
      await this.apolloClientService.client.mutate<
        UpdateReplacementSettingsMutation,
        UpdateReplacementSettingsMutationVariables
      >({
        mutation: UpdateReplacementSettingsDocument,
        variables,
      })
    ).data?.insert_setting

    return result?.affected_rows
  }

  get items() {
    return this.store.items
  }

  get loading() {
    return this.store.loading
  }

  get promoEnableNow() {
    const now = moment()
    return (
      this.promoEnable &&
      this.promoDateFrom !== '' &&
      moment(this.promoDateFrom, 'YYYY-MM-DD')
        .startOf('day')
        .isSameOrBefore(now) &&
      this.promoDateTo !== '' &&
      moment(this.promoDateTo, 'YYYY-MM-DD').endOf('day').isSameOrAfter(now)
    )
  }

  get promoEnable() {
    const promoEnable = this.items.find((item) => item.key === 'promoEnable')
    return promoEnable?.value === 'true'
  }

  get promoDiscount() {
    const promoDiscount = this.items.find(
      (item) => item.key === 'promoDiscount'
    )
    return Number(JSON.parse(promoDiscount?.value ?? '0'))
  }

  get promoMinQty() {
    const promoMinQty = this.items.find((item) => item.key === 'promoMinQty')
    return Number(JSON.parse(promoMinQty?.value ?? '0'))
  }

  get promoMaxQty() {
    const promoMaxQty = this.items.find((item) => item.key === 'promoMaxQty')
    return Number(JSON.parse(promoMaxQty?.value ?? '0'))
  }

  get promoDateFrom() {
    const promoDateFrom = this.items.find(
      (item) => item.key === 'promoDateFrom'
    )
    return promoDateFrom?.value ?? ''
  }

  get promoDateTo() {
    const promoDateTo = this.items.find((item) => item.key === 'promoDateTo')
    return promoDateTo?.value ?? ''
  }

  get replacementMaxOrderQty() {
    const replacementMaxOrderQty = this.items.find(
      (item) => item.key === 'replacementMaxOrderQty'
    )
    return Number(JSON.parse(replacementMaxOrderQty?.value ?? '5'))
  }
}
