import { Inject } from 'inversify-props'
import { Vue } from 'nuxt-property-decorator'
import { SalesTransactionService } from '~/services/sales-transaction.service'
import { paymentDataStore } from '~/utils/store-accessor'
import {
  PaymentMethodAndConditionDocument,
  PaymentMethodAndConditionQuery,
  PaymentMethodAndConditionQueryVariables,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'
import { SystemMessageService } from '~/services/system-message.service'
import App from '~/mixins/app'
import { CustomerService } from '~/services/customer.service'

export const PAYMENT_PROVIDER_ERP = 'erp'
export const PAYMENT_PROVIDER_NEXI = 'nexi'
export const PAYMENT_PROVIDER_NEXIGROUP = 'nexigroup'
export const PAYMENT_PROVIDER_DEFAULT = PAYMENT_PROVIDER_ERP

export type FormDefinition = {
  ref?: string
  method?: 'get' | 'post' | 'redirect'
  action?: string
  input?: {
    type: 'hidden' | 'text'
    name: string
    value: string
  }[]
  autosubmit?: boolean
}

export type PaymentDefinition = {
  provider?: string
  methods?: {
    code: string
  }[]
  callback?: (
    app: Vue,
    salesTransactionService: SalesTransactionService,
    transactionId: string,
    form?: FormDefinition,
  ) => Promise<void>
  form?: (
    app: Vue,
    salesTransactionService: SalesTransactionService,
    transactionId: string,
  ) => Promise<FormDefinition>
  includingTax?: boolean
}

export class CustomerPaymentService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService
  @Inject('SystemMessageService') systemMessageService!: SystemMessageService
  @Inject('CustomerService') customerService!: CustomerService
  @Inject('SalesTransactionService')
  salesTransactionService!: SalesTransactionService

  private static readonly ONLINE_PAYMENT_PROVIDERS = [
    PAYMENT_PROVIDER_NEXI,
    PAYMENT_PROVIDER_NEXIGROUP,
  ]

  private isOnlinePayment(provider?: string) {
    return (
      provider &&
      CustomerPaymentService.ONLINE_PAYMENT_PROVIDERS.includes(provider)
    )
  }

  get canUseOnlinePayment() {
    return this.customerService.customer?.country !== 'GB'
  }

  private store = paymentDataStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        PaymentMethodAndConditionQuery,
        PaymentMethodAndConditionQueryVariables
      >({
        query: PaymentMethodAndConditionDocument,
        variables: {
          whereMethod: {},
          whereCondition: {},
        },
      })
    ).data

    if (data) {
      this.store.setPaymentMethods(data.payment_method)
      this.store.setPaymentConditions(data.payment_condition)
    }
    this.store.setLoading(false)
  }

  get customerPayments() {
    const paymentMethods: PaymentDefinition[] = []

    // OFFLINE PAYMENT PROVIDERS

    // allow ERP Payment only for customer having one
    if (this.customerService.customer?.payment_method_code) {
      // allow only one payment method for ERP Payment
      paymentMethods.push({
        provider: PAYMENT_PROVIDER_DEFAULT,
        methods: [{ code: this.customerService.customer.payment_method_code }],
        async callback(
          app: Vue,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          salesTransactionService: SalesTransactionService,
          transactionId: string,
        ) {
          await app.$router.push({
            path: app.localePath(`/transactions/manage/${transactionId}`),
          })
        },
        includingTax: false,
      })
    }

    if (!this.canUseOnlinePayment) {
      return paymentMethods
    }

    // ONLINE PAYMENT PROVIDERS

    // allow nexi payment method for Nexi Payment
    // paymentMethods.push({
    //   provider: PAYMENT_PROVIDER_NEXI,
    //   methods: [{ code: PAYMENT_PROVIDER_NEXI }],
    //   async form(
    //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //     app: Vue,
    //     salesTransactionService: SalesTransactionService,
    //     transactionId: string
    //   ) {
    //     const result = await salesTransactionService.form(transactionId, app.$i18n.locale)
    //     return result ? JSON.parse(result) : {}
    //   },
    //   includingTax: true,
    // })

    // allow nexigroup payment method for Nexi Payment
    paymentMethods.push({
      provider: PAYMENT_PROVIDER_NEXIGROUP,
      methods: [{ code: PAYMENT_PROVIDER_NEXIGROUP }],
      async form(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        app: Vue,
        salesTransactionService: SalesTransactionService,
        transactionId: string,
      ) {
        const result = await salesTransactionService.form(
          transactionId,
          app.$i18n.locale,
        )
        return result ? JSON.parse(result) : {}
      },
      includingTax: true,
    })
    return paymentMethods
  }

  get methods() {
    return this.store.paymentMethods
  }

  get conditions() {
    return this.store.paymentConditions
  }

  async pay(
    app: App,
    provider?: string,
    method?: string,
    taxRate?: number,
    amount?: number | string,
    reference?: number | string,
  ) {
    // console.log('CustomerPaymentService::pay::provider', provider)
    // console.log('CustomerPaymentService::pay::method', method)
    // console.log('CustomerPaymentService::pay::taxRate', taxRate)
    // console.log('CustomerPaymentService::pay::amount', amount)
    // console.log('CustomerPaymentService::pay::reference', reference)
    const amountByReference = reference
      ? await this.salesTransactionService.amount(reference)
      : undefined
    const payment = this.getPayment(app, provider, method, amountByReference)
    const selectedPayment = Object.assign(
      {},
      this.customerPayments.find((item) => item.provider === payment.provider),
    )
    // console.log('CustomerPaymentService::pay::selectedPayment', selectedPayment)
    let selectedForm: FormDefinition = {}
    if (selectedPayment != null) {
      const amountToPay = this.getAmount(
        app,
        selectedPayment,
        taxRate,
        amount,
        amountByReference,
      )
      // console.log('CustomerPaymentService::pay::amountToPay', amountToPay)
      // const selectedMethod = selectedPayment.methods?.find(
      //   (item) => item.code === payment.method
      // )
      // console.log('CustomerPaymentService::pay::selectedMethod', selectedMethod)
      const transactionId = await this.salesTransactionService.register(
        amountToPay.amount,
        amountToPay.currency,
        selectedPayment?.provider ? selectedPayment.provider : provider ?? '',
        selectedPayment?.methods
          ? selectedPayment.methods[0].code
          : method ?? '',
        reference,
      )
      // console.log('CustomerPaymentService::pay::transactionId', transactionId)
      if (transactionId != null) {
        // this.systemMessageService.addSuccessMessage(
        //   `Amount to pay {amount} {currency} for the transaction ID {transactionId}`,
        //   {
        //     amount: amountToPay.amount,
        //     currency: amountToPay.currency,
        //     transactionId,
        //   }
        // )
        if (selectedPayment.form) {
          const form = await selectedPayment.form(
            app,
            this.salesTransactionService,
            transactionId,
          )
          selectedForm = Object.assign({}, selectedForm, form)
          if (form?.method === 'redirect' && form?.action) {
            return {
              selectedPayment,
              selectedForm,
            }
          }
        }
        if (selectedPayment.callback) {
          await selectedPayment.callback(
            app,
            this.salesTransactionService,
            transactionId,
          )
        }
      } else {
        this.systemMessageService.addErrorMessage(
          `There was an error creating transaction for the amount to pay {amount} {currency}`,
          {
            amount: amountToPay.amount,
            currency: amountToPay.currency,
          },
        )
      }
    }
    return {
      selectedPayment,
      selectedForm,
    }
  }

  private getPayment(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app: App,
    provider?: string,
    method?: string,
    amountByReference?: {
      currency: string
      amount: string
      amountInclTax: string
      provider: string
      method: string
      condition: string
    },
  ) {
    // Customers from Great Britain cannot use online payment providers.
    if (
      this.isOnlinePayment(provider || amountByReference?.provider) &&
      !this.canUseOnlinePayment
    ) {
      throw new Error(
        `Invalid payment method provided. Customers from Great Britain cannot use online payment providers.`,
      )
    }
    if (provider && method) {
      return {
        provider,
        method,
      }
    }
    if (amountByReference) {
      return {
        provider: amountByReference.provider,
        method: amountByReference.method,
      }
    }
    throw new Error(`Invalid payment method provided.`)
  }

  private getAmount(
    app: App,
    payment: PaymentDefinition,
    taxRate?: number,
    amount?: number | string,
    amountByReference?: {
      currency: string
      amount: string
      amountInclTax: string
      provider: string
      method: string
      condition: string
    },
  ) {
    let currency = 'EUR'
    let amountIncludingTax: number = -1
    if (amountByReference) {
      currency = amountByReference.currency
      amount = parseFloat(amountByReference.amount)
      amountIncludingTax = parseFloat(amountByReference.amountInclTax)
    }
    if (typeof amount !== 'undefined') {
      amount = typeof amount === 'string' ? parseFloat(amount) : amount
      let amountToPay = amount

      // based on payment definition adding tax rate when provided
      if (payment.includingTax) {
        if (amountIncludingTax < 0) {
          let taxFactor = 1
          if (taxRate && taxRate > 0) {
            taxFactor += taxRate / 100
          }
          amountIncludingTax = amount * taxFactor
        }
        amountToPay = amountIncludingTax
      }

      return {
        amount: app.priceManager.toFixed(amountToPay),
        currency,
      }
    }
    throw new Error(`Invalid amount provided.`)
  }
}
