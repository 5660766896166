import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { communicationMailsStore } from '~/utils/store-accessor'
import {
  AgentsDocument,
  AgentsQuery,
  AgentsQueryVariables,
  Communication_External_Mail_Insert_Input,
  Communication_External_Mail_Set_Input,
  CommunicationExternalMailDocument,
  CommunicationExternalMailQuery,
  CommunicationExternalMailQueryVariables,
  DeleteCommunicationExternalMailDocument,
  DeleteCommunicationExternalMailMutation,
  DeleteCommunicationExternalMailMutationVariables,
  InsertCommunicationExternalMailDocument,
  InsertCommunicationExternalMailMutation,
  InsertCommunicationExternalMailMutationVariables,
  UpdateCommunicationExternalMailDocument,
  UpdateCommunicationExternalMailMutation,
  UpdateCommunicationExternalMailMutationVariables,
} from '~/graphql/types'

export class CommunicationMailService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  private store = communicationMailsStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        CommunicationExternalMailQuery,
        CommunicationExternalMailQueryVariables
      >({
        query: CommunicationExternalMailDocument,
        variables: {},
      })
    ).data

    if (data) {
      this.store.setExternalMails(data.communication_external_mail)
    }

    const agentsData = (
      await this.apolloClientService.client.query<
        AgentsQuery,
        AgentsQueryVariables
      >({
        query: AgentsDocument,
        variables: {},
      })
    ).data

    if (agentsData) {
      this.store.setAgents(
        agentsData.agent.map((item) => {
          return {
            id: item.id,
            erp_id: item.erp_id,
            name: `[${item.erp_id}] ${item.name}`,
            email: item.email,
          }
        })
      )
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get externalMails() {
    return this.store.externalMails
  }

  get agents() {
    return this.store.agents
  }

  async deleteExternalMailById(id: string) {
    const deletedMail = await this.apolloClientService.client.mutate<
      DeleteCommunicationExternalMailMutation,
      DeleteCommunicationExternalMailMutationVariables
    >({
      mutation: DeleteCommunicationExternalMailDocument,
      variables: {
        id,
      },
    })
    return !!deletedMail.data?.delete_communication_external_mail_by_pk?.id
  }

  async updateExternalMail(
    id: string,
    set: Communication_External_Mail_Set_Input
  ) {
    const updatedMail = await this.apolloClientService.client.mutate<
      UpdateCommunicationExternalMailMutation,
      UpdateCommunicationExternalMailMutationVariables
    >({
      mutation: UpdateCommunicationExternalMailDocument,
      variables: {
        id,
        set,
      },
    })
    return !!updatedMail.data?.update_communication_external_mail_by_pk?.id
  }

  async insertExternalMail(object: Communication_External_Mail_Insert_Input) {
    const insertedMail = await this.apolloClientService.client.mutate<
      InsertCommunicationExternalMailMutation,
      InsertCommunicationExternalMailMutationVariables
    >({
      mutation: InsertCommunicationExternalMailDocument,
      variables: {
        object,
      },
    })
    return !!insertedMail.data?.insert_communication_external_mail_one?.id
  }
}
