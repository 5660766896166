import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import {
  ApiFileDataDocument,
  ApiFileDataQuery,
  ApiFileDataQueryVariables,
  ApiFileReportDataDocument,
  ApiFileReportDataQuery,
  ApiFileReportDataQueryVariables,
  DocTypes,
  FileDataOutput,
} from '~/graphql/types'

export class DownloadService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  async getFileData(
    filename: string,
    year: number,
    docType: DocTypes
  ): Promise<FileDataOutput | null> {
    const fileDataResponse = await this.apolloClientService.client.query<
      ApiFileDataQuery,
      ApiFileDataQueryVariables
    >({
      query: ApiFileDataDocument,
      variables: {
        filename,
        year,
        docType,
      },
    })
    if (fileDataResponse.errors) {
      return null
    }
    return fileDataResponse.data.api_file_data
  }

  async getFileReportData(filename: string): Promise<FileDataOutput | null> {
    const fileDataResponse = await this.apolloClientService.client.query<
      ApiFileReportDataQuery,
      ApiFileReportDataQueryVariables
    >({
      query: ApiFileReportDataDocument,
      variables: {
        filename,
      },
    })
    if (fileDataResponse.errors) {
      return null
    }
    return fileDataResponse.data.api_file_report_data
  }
}
