import { Inject } from 'inversify-props'
import { CustomerService } from '~/services/customer.service'
import { ProductPriceService } from '~/services/product-price.service'
import { ProductService } from '~/services/product.service'
import { SalesCartQuoteService } from '~/services/sales-cart-quote.service'

export class QuoteService {
  @Inject('CustomerService') customerService!: CustomerService
  @Inject('SalesCartQuoteService') salesCartQuoteService!: SalesCartQuoteService
  @Inject('ProductPriceService') productPriceService!: ProductPriceService
  @Inject('ProductService') productService!: ProductService

  get loading() {
    return (
      this.customerService.loadingCurrent ||
      this.salesCartQuoteService.loading ||
      this.productPriceService.loading
    )
  }
}
