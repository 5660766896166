import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { ApiCustomerStatisticsQuery } from '~/graphql/types'

@Module({
  name: 'customer-statistic',
  stateFactory: true,
  namespaced: true,
})
export default class CustomerStatistic extends VuexModule {
  fetchStarted = false
  loading = true
  _item: ApiCustomerStatisticsQuery['api_customer_statistics'] | any = []

  get item() {
    return this._item
  }

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItem(item) {
    this._item = item
  }
}
