
import { Prop, Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import { NuxtError } from '@nuxt/types'

@Component({
  layout: 'empty',
})
export default class ErrorLayout extends Vue {
  @Prop({
    type: Object as () => NuxtError,
    default: null,
  })
  error!: NuxtError

  get title() {
    return this.$t(`error.${this?.error?.statusCode ?? 500}`)
  }

  get message() {
    const messageKey = `error.message.${this?.error?.statusCode ?? 500}`
    const message = this.$t(messageKey)
    if (messageKey !== message) {
      return message
    }
    return this?.error?.message ?? ''
  }
}
