import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiSalesManagerCancelOrderDocument,
  ApiSalesManagerCancelOrderMutation,
  ApiSalesManagerCancelOrderMutationVariables,
  ApiSalesManagerResetOrderDocument,
  ApiSalesManagerResetOrderMutation,
  ApiSalesManagerResetOrderMutationVariables,
  OrderByIncrementIdDocument,
  OrderByIncrementIdQuery,
  OrderByIncrementIdQueryVariables,
  OrderListDocument,
  OrderListQuery,
  OrderListQueryVariables,
  OrderSubcustomerByIncrementIdDocument,
  OrderSubcustomerByIncrementIdQuery,
  OrderSubcustomerByIncrementIdQueryVariables,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { orderStore } from '~/utils/store-accessor'
import { UserService } from '~/services/user.service'

export class OrderService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = orderStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    const where: OrderListQueryVariables['where'] = {
      customer_erp_id: {
        _eq: this.userService.customerErpId,
      },
      status: {
        _nin: ['new', 'canceled'],
      },
    }

    if (this.userService.subcustomerId) {
      where.order_products = {
        order_product_items: {
          additional_info: {
            _contains: {
              subcustomer_ids: [this.userService.subcustomerId],
            },
          },
        },
      }
    }

    const data = (
      await this.apolloClientService.client.query<
        OrderListQuery,
        OrderListQueryVariables
      >({
        query: OrderListDocument,
        variables: {
          where,
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.order)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get list() {
    return this.store.items
  }

  async getByIncrementId(incrementId: string | number) {
    return (
      await this.apolloClientService.client.query<
        OrderByIncrementIdQuery,
        OrderByIncrementIdQueryVariables
      >({
        query: OrderByIncrementIdDocument,
        variables: {
          incrementId:
            typeof incrementId === 'string'
              ? parseInt(incrementId)
              : incrementId,
        },
      })
    ).data?.order?.[0]
  }

  async getSubcustomerOrderByIncrementId(incrementId: string | number) {
    return (
      await this.apolloClientService.client.query<
        OrderSubcustomerByIncrementIdQuery,
        OrderSubcustomerByIncrementIdQueryVariables
      >({
        query: OrderSubcustomerByIncrementIdDocument,
        variables: {
          incrementId:
            typeof incrementId === 'string'
              ? parseInt(incrementId)
              : incrementId,
          whereOrder: {
            order_products: {
              order_product_items: {
                additional_info: {
                  _contains: {
                    subcustomer_ids: [this.userService.subcustomerId],
                  },
                },
              },
            },
          },
          whereOrderProductItem: {
            additional_info: {
              _contains: {
                subcustomer_ids: [this.userService.subcustomerId],
              },
            },
          },
        },
      })
    ).data?.order?.[0]
  }

  async cancelOrderByIncrementId(incrementId: string) {
    const result = await this.apolloClientService.client.mutate<
      ApiSalesManagerCancelOrderMutation,
      ApiSalesManagerCancelOrderMutationVariables
    >({
      mutation: ApiSalesManagerCancelOrderDocument,
      variables: {
        data: {
          id: incrementId,
        },
      },
    })
    return !!result.data?.api_sales_manager_cancel_order?.result
  }

  async resetOrderByIncrementId(
    incrementId: string,
    payment?: {
      provider?: string
      method?: string
      condition?: string
    }
  ) {
    const result = await this.apolloClientService.client.mutate<
      ApiSalesManagerResetOrderMutation,
      ApiSalesManagerResetOrderMutationVariables
    >({
      mutation: ApiSalesManagerResetOrderDocument,
      variables: {
        data: {
          id: incrementId,
          provider: payment?.provider ?? '',
          method: payment?.method ?? '',
          condition: payment?.condition ?? '',
        },
      },
    })
    return result.data?.api_sales_manager_reset_order?.result
  }

  public isPaymentProviderOnline(payment: {
    provider?: string
    method?: string
  }) {
    return payment?.provider !== 'erp'
  }
}
