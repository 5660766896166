import { Inject } from 'inversify-props'
import {
  NewAbstractService,
  StepperStep,
} from '~/services/new-abstract.service'
import NewAbstract from '~/store/new-abstract'
import { newProposalStore } from '~/utils/store-accessor'
import { SalesCartProposalService } from '~/services/sales-cart-proposal.service'

export class NewProposalService extends NewAbstractService {
  @Inject('SalesCartProposalService')
  salesCartService!: SalesCartProposalService

  getStore(): NewAbstract {
    return newProposalStore
  }

  getSteps(): StepperStep[] {
    return [
      { step: 1, stepLabelKey: 'sales.step.cart.label' },
      { step: 2, stepLabelKey: 'sales.step.review.label' },
    ]
  }
}
