import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

export type EmailType = {
  email: string
  name: string
}

export type MailType = {
  created_at: string
  id: string
  logs: { error?: string; date: string; success?: true }[]
  mail: {
    bcc?: EmailType[]
    cc?: EmailType[]
    from: EmailType
    to: EmailType[]
    content: { html: string; subject: string; text?: string }
  }
  status: string | 'error' | 'notsent' | 'sent'
  updated_at: string
}

@Module({
  name: 'mail',
  stateFactory: true,
  namespaced: true,
})
export default class Mail extends VuexModule {
  fetchStarted = false
  loading = true
  items: MailType[] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
