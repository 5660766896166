import { Module, Mutation } from 'vuex-module-decorators'
import SalesCartAbstract from '~/store/sales-cart-abstract'

@Module({
  name: 'sales-cart-quote',
  stateFactory: true,
  namespaced: true,
})
export default class SalesCartQuote extends SalesCartAbstract {
  _isQuoteCustomerDataUpdating: boolean = false

  get isQuoteCustomerDataUpdating() {
    return this._isQuoteCustomerDataUpdating
  }

  @Mutation
  setIsQuoteCustomerDataUpdating(updating) {
    this._isQuoteCustomerDataUpdating = updating
  }
}
