
import { Component } from 'nuxt-property-decorator'
import App from '~/mixins/app'

@Component
export default class AppScrollToTop extends App {
  fab = false

  onScroll(e) {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset || e.target.scrollTop || 0
    this.fab = top > 300
  }

  toTop() {
    this.$vuetify.goTo(0)
  }
}
