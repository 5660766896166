
import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import AppSnackBarMessages from '~/components/AppSnackBarMessages.vue'

@Component({
  middleware: 'notAuthenticated',
  components: {
    AppSnackBarMessages,
  },
})
export default class LoginLayout extends Vue {}
