import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { CustomerAddressDropshippingSubscription } from '~/graphql/types'

@Module({
  name: 'customer-address-dropshipping',
  stateFactory: true,
  namespaced: true,
})
export default class CustomerAddressDropshipping extends VuexModule {
  loading = true
  items: CustomerAddressDropshippingSubscription['customer_address_dropshipping'] =
    []

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(data) {
    this.items = data
  }
}
