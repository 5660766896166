import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { InvoiceQuery } from '~/graphql/types'

@Module({
  name: 'invoice',
  stateFactory: true,
  namespaced: true,
})
export default class Invoice extends VuexModule {
  fetchStarted = false
  loading = true
  _items: InvoiceQuery['invoice_view'] = []

  get invoices() {
    return this._items
  }

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this._items = items
  }
}
