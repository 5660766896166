import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import * as GraphqlTypes from '~/graphql/types'

export type MenuItem = {
  enabled: boolean
  useInMenu: boolean
  label: string
  link: string
  class?: string
  icon?: string
  counter?: number
  counterIcon?: string
  prependDivider?: boolean
  roles: Array<GraphqlTypes.Role_Enum | 'CustomerSelectedByAgent'> | '*'
}

@Module({
  name: 'layout',
  stateFactory: true,
  namespaced: true,
})
export default class Layout extends VuexModule {
  _pageName = null
  menuItems: MenuItem[] = [
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.admins',
      link: '/admin/admins',
      icon: 'mdi-account-supervisor-outline',
      prependDivider: true,
      roles: [GraphqlTypes.Role_Enum.BackendSuperAdmin],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.orders',
      link: '/admin/orders',
      icon: 'mdi-clipboard-text-search-outline',
      roles: [
        GraphqlTypes.Role_Enum.BackendAdmin,
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.product-replacements.index',
      link: '/admin/product-replacements',
      icon: 'mdi-hammer-wrench',
      roles: [
        GraphqlTypes.Role_Enum.BackendAdmin,
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.mail',
      link: '/admin/mail',
      icon: 'mdi-email',
      roles: [GraphqlTypes.Role_Enum.BackendSuperAdmin],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.translate',
      link: '/admin/translate',
      icon: 'mdi-translate',
      roles: [GraphqlTypes.Role_Enum.BackendSuperAdmin],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.customers',
      link: '/admin/customers',
      icon: 'mdi-account-outline',
      prependDivider: true,
      roles: [
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
        GraphqlTypes.Role_Enum.BackendAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.communicationCodes',
      link: '/admin/communication-codes',
      icon: 'mdi-email',
      prependDivider: false,
      roles: [GraphqlTypes.Role_Enum.BackendSuperAdmin],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.communicationMails',
      link: '/admin/communication-mails',
      icon: 'mdi-email',
      prependDivider: false,
      roles: [
        GraphqlTypes.Role_Enum.BackendAdmin,
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.subcustomers',
      link: '/admin/subcustomers',
      icon: 'mdi-account-child-outline',
      prependDivider: false,
      roles: [
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
        GraphqlTypes.Role_Enum.BackendAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.promo',
      link: '/admin/promo',
      icon: 'mdi-tag-outline',
      roles: [
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
        GraphqlTypes.Role_Enum.BackendAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.download-agent',
      link: '/admin/download-agent',
      icon: 'mdi-cloud-upload-outline',
      roles: [
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
        GraphqlTypes.Role_Enum.BackendAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.admin.download-customer',
      link: '/admin/download-customer',
      icon: 'mdi-cloud-upload-outline',
      roles: [
        GraphqlTypes.Role_Enum.BackendSuperAdmin,
        GraphqlTypes.Role_Enum.BackendAdmin,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.reports',
      link: '/reports',
      icon: 'mdi-microsoft-excel',
      roles: [GraphqlTypes.Role_Enum.Agent, GraphqlTypes.Role_Enum.DemoAgent],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.newCustomer',
      link: '/new-customer',
      icon: 'mdi-account-plus-outline',
      roles: [GraphqlTypes.Role_Enum.Agent, GraphqlTypes.Role_Enum.DemoAgent],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.rebateAgent.title',
      link: '/rebate-agent',
      icon: 'mdi-podium-bronze',
      roles: [GraphqlTypes.Role_Enum.Agent, GraphqlTypes.Role_Enum.DemoAgent],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.download',
      link: '/download-agent',
      icon: 'mdi-cloud-download-outline',
      roles: [GraphqlTypes.Role_Enum.Agent, GraphqlTypes.Role_Enum.DemoAgent],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.newOrder',
      link: '/new-order',
      icon: 'mdi-cart-plus',
      counter: 0,
      counterIcon: 'mdi-cart-variant',
      prependDivider: true,
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        GraphqlTypes.Role_Enum.DemoGeneric,
        GraphqlTypes.Role_Enum.DemoStock,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.quotes',
      link: '/quotes',
      icon: 'mdi-calculator',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.newProposal',
      link: '/new-proposal',
      icon: 'mdi-cart-plus',
      counter: 0,
      counterIcon: 'mdi-cart-variant',
      prependDivider: true,
      roles: [
        GraphqlTypes.Role_Enum.SubCustomer,
        GraphqlTypes.Role_Enum.DemoSubCustomer,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.proposals',
      link: '/proposals-sent',
      icon: 'mdi-send-outline',
      roles: [
        GraphqlTypes.Role_Enum.SubCustomer,
        GraphqlTypes.Role_Enum.DemoSubCustomer,
      ],
    },
    {
      enabled: true,
      useInMenu: false,
      label: 'page.proposalsReceived',
      link: '/proposals-received',
      icon: 'mdi-send-outline',
      roles: [
        GraphqlTypes.Role_Enum.Agent,
        GraphqlTypes.Role_Enum.DemoAgent,
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.promo',
      link: '/promo',
      class: 'primary',
      icon: 'mdi-tag-outline',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      icon: 'mdi-sale-outline',
      label: 'page.promotionalOffers',
      link: '/promotional-offers',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.orders',
      link: '/orders',
      icon: 'mdi-clipboard-text-search-outline',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.invoicesAndCreditNotes',
      link: '/invoices',
      icon: 'mdi-file-document-multiple-outline',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.backOrders',
      link: '/back-orders',
      icon: 'mdi-calendar-clock',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.ddt',
      link: '/ddt',
      icon: 'mdi-clipboard-flow-outline',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.statistics',
      link: '/statistics',
      icon: 'mdi-finance',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.rebate.title',
      link: '/rebate',
      icon: 'mdi-podium-bronze',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        'CustomerSelectedByAgent',
      ],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.communications.index',
      link: '/communications',
      icon: 'mdi-at',
      roles: [GraphqlTypes.Role_Enum.Customer],
    },
    {
      enabled: true,
      useInMenu: false,
      label: 'page.communications.archive',
      link: '/communications/archive',
      roles: [GraphqlTypes.Role_Enum.Customer],
    },
    {
      enabled: true,
      useInMenu: false,
      label: 'page.communications.manage',
      link: '/communications/manage',
      roles: [GraphqlTypes.Role_Enum.Customer],
    },
    {
      enabled: true,
      useInMenu: true,
      label: 'page.download',
      link: '/download',
      icon: 'mdi-cloud-download-outline',
      roles: [
        GraphqlTypes.Role_Enum.Customer,
        GraphqlTypes.Role_Enum.DemoCustomer,
        GraphqlTypes.Role_Enum.SubCustomer,
        GraphqlTypes.Role_Enum.DemoSubCustomer,
        GraphqlTypes.Role_Enum.DemoGeneric,
      ],
    },
  ]

  get pageName() {
    return this._pageName
  }

  @Mutation
  setPageName(name) {
    this._pageName = name
  }
}
