import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  CommunicationListDocument,
  CommunicationListQuery,
  CommunicationListQueryVariables,
  CommunicationsDocument,
  CommunicationSettingsDocument,
  CommunicationSettingsQuery,
  CommunicationSettingsQueryVariables,
  CommunicationsQuery,
  CommunicationsQueryVariables,
  InsertCommunicationListDocument,
  InsertCommunicationListMutation,
  InsertCommunicationListMutationVariables,
  InsertCommunicationSettingsDocument,
  InsertCommunicationSettingsMutation,
  InsertCommunicationSettingsMutationVariables,
  UpdateCommunicationDocument,
  UpdateCommunicationMutation,
  UpdateCommunicationMutationVariables,
} from '~/graphql/types'
import { communicationStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import {
  CommunicationListType,
  CommunicationSettingsType,
} from '~/store/communication'

export class CommunicationService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  public store = communicationStore

  async fetchAll(force = false, isArchived = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    // fetch settings
    const settings = (
      await this.apolloClientService.client.query<
        CommunicationSettingsQuery,
        CommunicationSettingsQueryVariables
      >({
        query: CommunicationSettingsDocument,
      })
    ).data.communication_settings as CommunicationSettingsType[]
    if (settings && settings[0]) {
      this.store.setSettings(settings[0])
    }

    // fetch list
    const lists = (
      await this.apolloClientService.client.query<
        CommunicationListQuery,
        CommunicationListQueryVariables
      >({
        query: CommunicationListDocument,
      })
    ).data.communication_list as CommunicationListType[]
    if (lists) {
      this.store.setList(lists)
    }

    // fetch items
    await this.fetchItems(lists, isArchived)

    this.store.setLoading(false)
  }

  async fetchManagement(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    // fetch settings
    const settings = (
      await this.apolloClientService.client.query<
        CommunicationSettingsQuery,
        CommunicationSettingsQueryVariables
      >({
        query: CommunicationSettingsDocument,
      })
    ).data?.communication_settings[0] as CommunicationSettingsType
    if (settings) {
      this.store.setSettings(settings)
    }

    // fetch list
    const lists = (
      await this.apolloClientService.client.query<
        CommunicationListQuery,
        CommunicationListQueryVariables
      >({
        query: CommunicationListDocument,
      })
    ).data?.communication_list as CommunicationListType[]
    if (lists) {
      this.store.setList(lists)
    }

    this.store.setLoading(false)
  }

  async fetchCommunications(force = false, isArchived = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    // fetch list
    const lists = (
      await this.apolloClientService.client.query<
        CommunicationListQuery,
        CommunicationListQueryVariables
      >({
        query: CommunicationListDocument,
      })
    ).data.communication_list as CommunicationListType[]
    if (lists) {
      this.store.setList(lists)
    }

    // fetch items
    await this.fetchItems(lists, isArchived)

    this.store.setLoading(false)
  }

  private async fetchItems(
    lists?: CommunicationListType[],
    isArchived = false
  ) {
    const itemsWhereConditions: {
      code: any
      is_deleted: any
      is_archived: any
    } = {
      code: { _is_null: false },
      is_deleted: { _eq: false },
      is_archived: { _eq: isArchived },
    }
    if (lists) {
      itemsWhereConditions.code = {
        _in: lists
          .filter((list) => list.is_enabled_site)
          .map((list) => list.code),
      }
    }
    const items = (
      await this.apolloClientService.client.query<
        CommunicationsQuery,
        CommunicationsQueryVariables
      >({
        query: CommunicationsDocument,
        variables: {
          where: itemsWhereConditions,
        },
      })
    ).data.communication
    if (items) {
      this.store.setItems(items)
    }
  }

  get settings() {
    return this.store.settings
  }

  get lists() {
    return this.store.lists
  }

  get items() {
    return this.store.items
  }

  get loading() {
    return this.store.loading
  }

  deleteLogically(ids: string[]) {
    return this.update(ids, true)
  }

  archiveLogically(ids: string[]) {
    return this.update(ids, false, true)
  }

  async update(ids: string[], isDeleted = false, isArchived = false) {
    await this.apolloClientService.client.mutate<
      UpdateCommunicationMutation,
      UpdateCommunicationMutationVariables
    >({
      mutation: UpdateCommunicationDocument,
      variables: {
        ids,
        changes: {
          is_deleted: isDeleted,
          is_archived: isArchived,
        },
      },
    })
  }

  async saveSettings(settings: CommunicationSettingsType) {
    return (
      await this.apolloClientService.client.mutate<
        InsertCommunicationSettingsMutation,
        InsertCommunicationSettingsMutationVariables
      >({
        mutation: InsertCommunicationSettingsDocument,
        variables: {
          objects: {
            language: settings.language,
          },
        },
      })
    ).data?.insert_communication_settings
      ?.returning[0] as CommunicationSettingsType
  }

  async saveLists(lists: CommunicationListType[]) {
    return (
      await this.apolloClientService.client.mutate<
        InsertCommunicationListMutation,
        InsertCommunicationListMutationVariables
      >({
        mutation: InsertCommunicationListDocument,
        variables: {
          objects: lists.map((list) => {
            return {
              code: list.code,
              is_enabled_site: !!list.is_enabled_site,
              is_enabled_email: !!list.is_enabled_email,
              send_to_email: list.send_to_email.map((item) => item.trim()),
            }
          }),
        },
      })
    ).data?.insert_communication_list?.returning as CommunicationListType[]
  }
}
