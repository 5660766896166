import 'reflect-metadata'
import { salesCartOrderStore } from '~/utils/store-accessor'
import { SalesCartAbstractService } from '~/services/sales-cart-abstract.service'
import SalesCartOrder from '~/store/sales-cart-order'

export class SalesCartOrderService extends SalesCartAbstractService {
  getStore(): SalesCartOrder {
    return salesCartOrderStore
  }

  getCartType(): string {
    return 'order'
  }
}
