var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('v-row', {
    staticClass: "align-self-center fill-height"
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('h1', [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]), _vm._v(" "), _vm.message ? _c('h3', [_vm._v("\n      " + _vm._s(_vm.message) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v(_vm._s(_vm.$t('page.home')))])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }