// @ts-ignore
window.onNuxtReady(async (app) => {
  const createElement = ({
    html = '',
    styles = '',
    tag = 'div',
    onClick = () => {},
  } = {}): [HTMLElement, any] => {
    const el = document.createElement(tag)
    el.style.cssText = styles
    el.innerHTML = html

    el.onclick = () => onClick()

    const mount = (target: HTMLElement) => {
      target.appendChild(el)
      el.getBoundingClientRect()
    }

    return [el, mount]
  }

  const showPrompt = (text = '', link = '') => {
    return new Promise((resolve) => {
      const [container, mountContainer] = createElement({
        styles: `
                    display: flex;
                    position: fixed;
                    bottom: 10px;
                    left: 10px;
                    right: 10px;
                    z-index: 9999999999;`,
      })
      const [textBox, mountTextBox] = createElement({
        html: text,
        styles: `
                    display: block;
                    background-color: #9c27b0;
                    color: white;
                    padding: 10px 20px;
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    line-height: 1.4em;
                    margin: 0 auto;
                    text-align: center;
                    box-shadow: 0 2px 5px 0 rgba(0,0,0,.3);`,
      })
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [linkBox, mountlinkBox] = createElement({
        html: link,
        onClick: () => onClick(),
        styles: `
                    display: inline-block;
                    cursor: pointer;
                    text-decoration: underline;
                    font-weight: bold;
                    padding-left: 8px;`,
      })
      mountTextBox(container)
      mountlinkBox(textBox)
      mountContainer(document.body)

      function onClick() {
        resolve(true)
        // document.body.removeChild(container)
      }
    })
  }

  let promptActive = false

  const checkNewRev = async () => {
    const res = await fetch(`///${app.$config.wwwDomain}/rev.json`)
    const resJson = await res.json()

    if (resJson?.rev && `${resJson.rev}` !== `${app.$config.checkRev}`) {
      promptActive = true
      if (`${window.localStorage.getItem('check-rev')}` === `${resJson.rev}`) {
        if (!window.location.pathname.includes('login')) {
          await showPrompt(
            `${app.$t('checkRev.updateError.line1')}<br>` +
              `${app.$t('checkRev.updateError.line2')}<br><br>`,
            // @ts-ignore
            app.$t('checkRev.updateError.cta')
          )
          window.open(
            'https://fabricdigital.co.nz/blog/how-to-hard-refresh-your-browser-and-clear-cache',
            '_blank'
          )
        }
      } else {
        if (!window.location.pathname.includes('login')) {
          await showPrompt(
            // @ts-ignore
            app.$t('checkRev.newVersion.label'),
            app.$t('checkRev.newVersion.cta')
          )
        }

        window.localStorage.setItem('check-rev', `${resJson.rev}`)

        // @ts-ignore
        window.location.reload(true)
      }
    }
  }

  do {
    try {
      await checkNewRev()
    } catch (e) {}
    await new Promise((resolve) => setTimeout(resolve, 30000))

    // eslint-disable-next-line no-unmodified-loop-condition
  } while (!promptActive)
})
