import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiFileDeleteDocument,
  ApiFileDeleteMutation,
  ApiFileDeleteMutationVariables,
  ApiFileUploadDocument,
  ApiFileUploadMutation,
  ApiFileUploadMutationVariables,
} from '~/graphql/types'
import { fileStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'

export class FileService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  private store = fileStore

  get updating() {
    return this.store.updating
  }

  get deleting() {
    return this.store.deleting
  }

  async upload(
    file: {
      name: string
      mimeType: string
      content: string | File
    },
    type: string,
    persist = true
  ) {
    if (this.updating) {
      return false
    }
    this.store.setUpdating(true)
    try {
      let content = file.content as string
      if (typeof file.content !== 'string') {
        content = await this.getBase64FromFile(file.content as File)
      }
      const uploadedFile = await this.apolloClientService.client.mutate<
        ApiFileUploadMutation,
        ApiFileUploadMutationVariables
      >({
        mutation: ApiFileUploadDocument,
        variables: {
          data: {
            name: file.name,
            type,
            mimeType: file.mimeType,
            content,
            persist,
          },
        },
      })
      return uploadedFile.data?.api_file_upload.id
    } finally {
      this.store.setUpdating(false)
    }
  }

  async delete(name: string, type: string) {
    if (this.deleting) {
      return false
    }
    this.store.setDeleting(true)
    try {
      const deleteFile = await this.apolloClientService.client.mutate<
        ApiFileDeleteMutation,
        ApiFileDeleteMutationVariables
      >({
        mutation: ApiFileDeleteDocument,
        variables: {
          data: {
            name,
            type,
          },
        },
      })
      return deleteFile.data?.api_file_delete.result
    } finally {
      this.store.setDeleting(false)
    }
  }

  getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          return resolve(
            Buffer.from(reader.result, 'binary').toString('base64')
          )
        }
        return resolve('')
      }
      reader.onerror = (error) => reject(error)
    })
  }
}
