import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ReportDocument,
  ReportQuery,
  ReportQueryVariables,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { reportStore } from '~/utils/store-accessor'

export class ReportService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  private store = reportStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        ReportQuery,
        ReportQueryVariables
      >({
        query: ReportDocument,
      })
    ).data

    if (data) {
      this.store.setItems(data.report)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get reports() {
    return this.store.items
  }

  get weeklyReports() {
    return this.reports.filter((r) => r.type === 'week')
  }

  get monthlyReports() {
    return this.reports.filter((r) => r.type === 'month')
  }

  get yearlyReports() {
    return this.reports.filter((r) => r.type === 'year')
  }
}
