import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  PendingOrdersDocument,
  PendingOrdersSubscription,
  PendingOrdersSubscriptionVariables,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { orderPendingStore } from '~/utils/store-accessor'

export class OrderPendingService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  public store = orderPendingStore

  private _o: ZenObservable.Subscription | undefined

  subscribe() {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<
          PendingOrdersSubscription,
          PendingOrdersSubscriptionVariables
        >({
          query: PendingOrdersDocument,
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.order)
          }
          this.store.setLoading(false)
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  get loading() {
    return this.store.loading
  }

  get order_products() {
    return this.store.items.filter((o) => o.type === 'product')
  }

  get order_invoices() {
    return this.store.items.filter((o) => o.type === 'invoice')
  }
}
