import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  OrderPromoDocument,
  OrderPromoSubscription,
  OrderPromoSubscriptionVariables,
} from '~/graphql/types'
import { orderPromoStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class OrderPromoService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = orderPromoStore

  private _o: ZenObservable.Subscription | undefined

  subscribe(promoDateFrom, promoDateTo) {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<OrderPromoSubscription, OrderPromoSubscriptionVariables>({
          query: OrderPromoDocument,
          variables: {
            customerErpId: this.userService.customerErpId,
            additionalInfoContains: {
              type: 'promo',
              promoDateFrom,
              promoDateTo,
            },
          },
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.order)
          }
          this.store.setLoading(false)
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  get items() {
    return this.store.items
  }

  get loading() {
    return this.store.loading
  }
}
