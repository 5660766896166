import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { OrderItemUnshippedQuery } from '~/graphql/types'

@Module({
  name: 'order-item-unshipped',
  stateFactory: true,
  namespaced: true,
})
export default class OrderItemUnshipped extends VuexModule {
  fetchStarted = false
  loading = true
  items: OrderItemUnshippedQuery['order_item_unshipped'] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
