import { Inject } from 'inversify-props'
import { CustomerService } from '~/services/customer.service'
import { ProductPriceService } from '~/services/product-price.service'
import NewAbstract from '~/store/new-abstract'
import { SalesCartAbstractService } from '~/services/sales-cart-abstract.service'

export type StepperStep = {
  step: number
  stepLabelKey: string
}

export abstract class NewAbstractService {
  @Inject('CustomerService') customerService!: CustomerService
  @Inject('ProductPriceService') productPriceService!: ProductPriceService
  salesCartService!: SalesCartAbstractService

  abstract getStore(): NewAbstract

  abstract getSteps(): StepperStep[]

  get editable() {
    return !this.salesCartService.updating
  }

  get loading() {
    return (
      this.customerService.loadingCurrent ||
      this.salesCartService.loading ||
      this.productPriceService.loading
    )
  }

  get stepLoaded() {
    return this.loading ? false : this.step
  }

  get steps(): StepperStep[] {
    return this.getSteps()
  }

  get step() {
    return this.getStore().step
  }

  set step(step) {
    this.getStore().setStep(step)
  }

  prevStep() {
    if (this.salesCartService.updating) {
      return
    }
    if (this.step > 1) {
      --this.step
    }
  }

  nextStep() {
    if (this.salesCartService.updating) {
      return
    }
    if (this.step < this.steps.length) {
      ++this.step
    }
  }
}
