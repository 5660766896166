import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ShipmentDocumentDocument,
  ShipmentDocumentQuery,
  ShipmentDocumentQueryVariables,
} from '~/graphql/types'
import { shipmentDocumentStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class ShipmentDocumentService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = shipmentDocumentStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        ShipmentDocumentQuery,
        ShipmentDocumentQueryVariables
      >({
        query: ShipmentDocumentDocument,
        variables: {
          where: {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.shipment_document)
    }
    this.store.setLoading(false)
  }
}
