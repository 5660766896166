import 'reflect-metadata'
import { Inject } from 'inversify-props'
// eslint-disable-next-line import/no-named-as-default
import readXlsxFile from 'read-excel-file'
import {
  CartSubscription,
  CartSubscriptionVariables,
  CartDocument,
  ApiSalesManagerCartUpdateMutation,
  ApiSalesManagerCartUpdateMutationVariables,
  ApiSalesManagerCartUpdateDocument,
  ApiSalesManagerCartToOrderMutation,
  ApiSalesManagerCartToOrderMutationVariables,
  ApiSalesManagerCartToOrderDocument,
  ApiSalesManagerCollectTotalsQuery,
  ApiSalesManagerCollectTotalsQueryVariables,
  ApiSalesManagerCollectTotalsDocument,
  ApiSalesManagerEnsureCartDocument,
  ApiSalesManagerEnsureCartMutation,
  ApiSalesManagerEnsureCartMutationVariables,
  ApiSalesManagerCartItemRemoveMutation,
  ApiSalesManagerCartItemRemoveMutationVariables,
  ApiSalesManagerCartItemRemoveDocument,
  ApiSalesManagerCartItemUpdateMutation,
  ApiSalesManagerCartItemUpdateMutationVariables,
  ApiSalesManagerCartItemUpdateDocument,
  ApiSalesManagerCartReplacementRemoveMutation,
  ApiSalesManagerCartReplacementRemoveMutationVariables,
  ApiSalesManagerCartReplacementRemoveDocument,
  ApiSalesManagerCartReplacementUpdateMutation,
  ApiSalesManagerCartReplacementUpdateMutationVariables,
  ApiSalesManagerCartReplacementUpdateDocument,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { SystemMessageService } from '~/services/system-message.service'
import { Shipping } from '~/components/new-order/step-2/AppTableShippingAddress.vue'
import { UserService } from '~/services/user.service'
import { CustomerService } from '~/services/customer.service'
import { MessageType } from '~/store/system-messages'
import { ProductPriceService } from '~/services/product-price.service'
import { ProductService } from '~/services/product.service'
import SalesCartAbstract, { ResetCartTotals } from '~/store/sales-cart-abstract'

export type CartUpdate = {
  id?: number | null
  type?: string | null
  order_id?: number | null
  status?: string | null
  additional_info?: any | null
  cart_items?: Array<{
    id?: number | null
    cart_id?: number | null
    product_erp_id?: string | null
    qty?: number | null
    additional_info?: any | null
  }>
  cart_replacements?: Array<{
    id?: number | null
    cart_id?: number | null
    replacement_erp_id?: string | null
    qty?: number | null
    additional_info?: any | null
  }>
}

export type SalesCartEnsurePayload = {
  error?: {
    code: number
    messageCode: string
    parameters: {
      [key: string]: string | number | boolean | string[] | number[] | boolean[]
    }
  }
  oldData?: any
  newData?: any
}

type AddableCartItem = {
  sku: string
  quantity: number | string
  additionalInfo?: any
}

export interface ExcelFileError {
  row: number
  col: string
  value: any
  reason: string | undefined
  extras: object
}

export type ImportableExcelRowObject = {
  id: number
  qty: number
  notes: string | null
}

export abstract class SalesCartAbstractService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('SystemMessageService') systemMessageService!: SystemMessageService
  @Inject('CustomerService') customerService!: CustomerService
  @Inject('ProductPriceService') productPriceService!: ProductPriceService
  @Inject('UserService') userService!: UserService
  @Inject('ProductService') productService!: ProductService

  protected _o: { [key: string]: ZenObservable.Subscription | undefined } = {}

  protected static readonly API_PAYLOAD_MESSAGE_TYPE = {
    13500: MessageType.INFO,
    14000: MessageType.INFO,
    15500: MessageType.INFO,
    15700: MessageType.INFO,
    15800: MessageType.INFO,
  }

  abstract getStore(): SalesCartAbstract

  abstract getCartType(): string

  subcustomerId() {
    return this.userService.subcustomerId
  }

  lockStore() {
    this.getStore().setLoading(true)
  }

  unlockStore() {
    this.getStore().setLoading(false)
  }

  lockService() {
    this.getStore().setUpdating(true)
  }

  unlockService() {
    this.getStore().setUpdating(false)
  }

  subscribe(
    id: CartSubscription['cart'][0]['id'] | undefined = undefined,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.loading || !this.userService.customerErpId) {
        resolve()
        return
      }
      const observerKey: string = this.getCartType() + (id || 'active')
      if (this._o?.[observerKey]) {
        this.unlockStore()
        resolve()
        return
      }
      this.lockStore()
      // customer/subcustomer may be without cart so trigger the creation
      this.ensureCart(id)
        .then(() => {
          if (this._o?.[observerKey]) {
            this.unlockStore()
            resolve()
            return
          }
          const where: CartSubscriptionVariables['where'] = {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
            status: {
              _eq: 'active',
            },
            type: {
              _eq: this.getCartType(),
            },
          }

          if (this.subcustomerId()) {
            where.subcustomer_id = {
              _eq: this.subcustomerId(),
            }
          } else {
            where.subcustomer_id = {
              _is_null: true,
            }
          }

          if (this.userService.agentErpId) {
            where.agent_erp_id = {
              _eq: this.userService.agentErpId,
            }
          } else {
            where.agent_erp_id = {
              _is_null: true,
            }
          }

          if (id) {
            where.id = {
              _eq: id,
            }
          }

          this._o[observerKey] = this.apolloClientService.client
            .subscribe<CartSubscription, CartSubscriptionVariables>({
              query: CartDocument,
              variables: {
                where,
              },
            })
            .subscribe(({ data }) => {
              if (data && data.cart && data.cart.length > 0) {
                this.getStore().setItem(data.cart[0])

                // update mutable object contains cart totals
                if (!this.userService.isSubCustomer) {
                  const variables: ApiSalesManagerCollectTotalsQueryVariables =
                    {}
                  if (data.cart?.[0]?.id) {
                    variables.id = data.cart[0].id.toString()
                  }

                  this.apolloClientService.client
                    .query<
                      ApiSalesManagerCollectTotalsQuery,
                      ApiSalesManagerCollectTotalsQueryVariables
                    >({
                      query: ApiSalesManagerCollectTotalsDocument,
                      variables,
                    })
                    .then((cartTotalsQueryResult) => {
                      this.cartTotals = JSON.parse(
                        cartTotalsQueryResult.data
                          ?.api_sales_manager_collect_totals.result ?? '',
                      )
                    })
                    .catch(() => {
                      this.cartTotals = ResetCartTotals
                    })
                }
              } else {
                this.getStore().setItem(undefined)
              }

              this.unlockStore()
              resolve()
            })
        })
        .catch((e) => {
          this.unlockStore()
          reject(e)
        })
    })
  }

  unsubscribe(id: CartSubscription['cart'][0]['id'] | undefined = undefined) {
    const observerKey: string = id || 'active'
    if (this._o?.[observerKey]) {
      this._o[observerKey]?.unsubscribe()
      this._o[observerKey] = undefined
      this.getStore().setItem(undefined)
      this.unlockStore()
    }
  }

  get loading() {
    return this.getStore().loading
  }

  get updating() {
    return this.getStore().updating
  }

  get emptying() {
    return this.getStore().emptying
  }

  get cart() {
    return this.getStore().cart
  }

  get cartItems() {
    return this.cart?.cart_items ?? []
  }

  get counter() {
    let counter = 0
    this.cartItems?.forEach((i) => {
      counter = counter + i.qty
    })
    return counter
  }

  get cartAdditionalInfo() {
    return this.cart?.additional_info
  }

  get cartTotals() {
    return this.getStore().cartTotals
  }

  set cartTotals(value) {
    this.getStore().setCartTotals(value)
  }

  async createCart(additionalInfo = undefined) {
    return await this._updateCart({
      id: undefined,
      additional_info: additionalInfo,
      type: this.getCartType(),
      status: 'active',
      order_id: undefined,
      cart_items: [],
    })
  }

  cartTotalsItem(id: number) {
    return this.cartTotals.items.find((item) => item.cart_item_id === id)
  }

  async addToCartMany(items: AddableCartItem[], merge: boolean = false) {
    const cart = this.cart
    if (cart && items.length) {
      // if merge remove old items with same sku
      const newCartItems = JSON.parse(
        JSON.stringify(
          merge
            ? cart.cart_items?.filter(
                (item) =>
                  !items.map((i) => i.sku).includes(item.product_erp_id),
              )
            : cart.cart_items,
        ),
      )

      items.forEach((i) => {
        const item = {
          id: undefined,
          product_erp_id: i.sku,
          qty:
            typeof i.quantity === 'string' ? parseInt(i.quantity) : i.quantity,
          additional_info: i.additionalInfo || {},
          created_at: undefined,
          updated_at: undefined,
        }
        if (merge) {
          const sameItemInCart = cart.cart_items?.find(
            (ci) => ci.product_erp_id === i.sku,
          )
          if (
            sameItemInCart?.additional_info?.note &&
            item?.additional_info?.note
          ) {
            item.additional_info.note = `${sameItemInCart.additional_info.note} | ${item.additional_info.note}`
          }
          if (
            sameItemInCart?.additional_info?.subcustomer_ids &&
            item.additional_info.subcustomer_ids
          ) {
            item.additional_info.subcustomer_ids = [
              ...new Set([
                ...sameItemInCart.additional_info.subcustomer_ids,
                ...item.additional_info.subcustomer_ids,
              ]),
            ]
          }
          if (
            sameItemInCart?.additional_info?.parent_cart_ids &&
            item?.additional_info?.parent_cart_ids
          ) {
            item.additional_info.parent_cart_ids = [
              ...new Set([
                ...sameItemInCart.additional_info.parent_cart_ids,
                ...item.additional_info.parent_cart_ids,
              ]),
            ]
          }
          if (sameItemInCart?.qty && item.qty) {
            item.qty = sameItemInCart.qty + item.qty
          }
        }
        newCartItems.push(item)
      })
      return await this._updateCart({ id: cart.id, cart_items: newCartItems })
    }
    return undefined
  }

  addToCart(sku: string, quantity: number, additionalInfo?: any) {
    return this.updateCartItemById(sku, quantity, undefined, additionalInfo)
  }

  async ensureCart(id?: number) {
    if (this.updating) {
      return
    }
    this.lockService()
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerEnsureCartMutation,
      ApiSalesManagerEnsureCartMutationVariables
    >({
      mutation: ApiSalesManagerEnsureCartDocument,
      variables: {
        data: {
          id: id ?? this.cart?.id,
          type: this.getCartType(),
        },
      },
    })
    this.unlockService()
    // manage payload and push error messages
    const payload: SalesCartEnsurePayload[] = JSON.parse(
      mutateResult.data?.api_sales_manager_ensure_cart?.result.payload ?? '[]',
    )
    this.manageEnsurePayload(payload)
  }

  async emptyCart() {
    const cart = this.cart
    if (cart) {
      this.getStore().setEmptying(true)
      await this.removeCartItemById(
        cart.cart_items.map((item) => item.id).filter((id) => !!id),
      )
      this.getStore().setEmptying(false)
    }
  }

  async removeCartItem(sku: string) {
    const cart = this.cart
    if (cart?.id) {
      if (
        await this._updateCart({
          id: cart.id,
          cart_items: JSON.parse(
            JSON.stringify(
              cart.cart_items?.filter((item) => item.product_erp_id !== sku),
            ),
          ),
        })
      ) {
        return true
      }
    }
    return false
  }

  async removeCartItemById(ids: number[]) {
    if (this.updating) {
      return false
    }
    this.lockService()
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerCartItemRemoveMutation,
      ApiSalesManagerCartItemRemoveMutationVariables
    >({
      mutation: ApiSalesManagerCartItemRemoveDocument,
      variables: {
        data: {
          ids,
        },
      },
    })
    if (
      mutateResult.data &&
      mutateResult.data?.api_sales_manager_cart_item_remove?.result?.success &&
      mutateResult.data?.api_sales_manager_cart_item_remove?.result?.cart &&
      mutateResult.data?.api_sales_manager_cart_item_remove?.result?.totals
    ) {
      this.getStore().setItem(
        JSON.parse(
          mutateResult.data.api_sales_manager_cart_item_remove.result.cart,
        ),
      )
      this.cartTotals = JSON.parse(
        mutateResult.data.api_sales_manager_cart_item_remove.result.totals,
      )
    }
    this.unlockService()
    return (
      mutateResult.data?.api_sales_manager_cart_item_remove?.result?.success ??
      false
    )
  }

  updateCartItem(
    sku: string,
    quantity: number,
    id?: number,
    additionalInfo?: any,
  ) {
    const cart = this.cart
    if (!cart && !id) {
      return this.addToCart(sku, quantity, additionalInfo)
    } else if (cart && id) {
      const itemIndex =
        cart?.cart_items?.findIndex((item) => item.id === id) ?? -1
      if (itemIndex >= 0) {
        return this.updateCartItemById(
          sku,
          quantity,
          id,
          additionalInfo
            ? {
                ...(cart?.cart_items?.[itemIndex]?.additional_info ?? {}),
                ...additionalInfo,
              }
            : undefined,
        )
      }
    } else if (!cart && id) {
      return this.updateCartItemById(sku, quantity, id, additionalInfo)
    } else {
      // update the first item found with sku or add new item
      const itemIndex =
        cart?.cart_items?.findIndex((item) => item.product_erp_id === sku) ?? -1
      if (itemIndex >= 0) {
        return this.updateCartItemById(
          sku,
          quantity,
          cart?.cart_items?.[itemIndex]?.id,
          additionalInfo
            ? {
                ...(cart?.cart_items?.[itemIndex]?.additional_info ?? {}),
                ...additionalInfo,
              }
            : undefined,
        )
      } else {
        return this.addToCart(sku, quantity, additionalInfo)
      }
    }
  }

  async updateCartItemById(
    sku: string,
    quantity: number,
    id?: number,
    additionalInfo?: any,
  ) {
    if (this.updating) {
      return
    }
    this.lockService()
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerCartItemUpdateMutation,
      ApiSalesManagerCartItemUpdateMutationVariables
    >({
      mutation: ApiSalesManagerCartItemUpdateDocument,
      variables: {
        data: {
          id,
          cartId: this.cart?.id,
          productErpId: sku,
          qty: quantity,
          additionalInfo: additionalInfo
            ? JSON.stringify(additionalInfo)
            : undefined,
        },
      },
    })
    if (mutateResult.data) {
      this.getStore().setItem(
        JSON.parse(
          mutateResult.data.api_sales_manager_cart_item_update.result.cart,
        ),
      )
      this.cartTotals = JSON.parse(
        mutateResult.data.api_sales_manager_cart_item_update.result.totals,
      )
    }
    this.unlockService()
    // manage payload and push error messages
    const payload: SalesCartEnsurePayload[] = JSON.parse(
      mutateResult.data?.api_sales_manager_cart_item_update?.result?.payload ??
        '[]',
    )
    this.manageEnsurePayload(payload)
    return mutateResult.data?.api_sales_manager_cart_item_update?.result?.id
  }

  overrideStoreCart(cart) {
    this.getStore().setItem(cart)
  }

  async updateCartNote(note: string) {
    await this._updateCartAdditionalInfo({
      note: note.substring(0, 1000),
    })
  }

  async updateCartShippingAddress(shippingId: string) {
    const additionalInfo = this.cartAdditionalInfo
    if (additionalInfo?.shipping?.id === shippingId) {
      return Promise.resolve()
    }
    let shipping: Shipping = {}
    if (additionalInfo?.shipping) {
      shipping = { ...additionalInfo.shipping }
    }
    shipping.id = shippingId
    await this._updateCartAdditionalInfo({
      shipping: {
        ...shipping,
      },
    })
  }

  async updateCartShippingDate(date: string) {
    const additionalInfo = this.cartAdditionalInfo
    if (!date || !additionalInfo) {
      return Promise.resolve()
    }
    let shipping = {}
    if (additionalInfo?.shipping) {
      shipping = additionalInfo?.shipping
    }
    await this._updateCartAdditionalInfo({
      shipping: {
        ...shipping,
        date,
      },
    })
  }

  async updateCartPayment(provider) {
    const additionalInfo = this.cartAdditionalInfo
    if (!additionalInfo || additionalInfo?.payment?.provider === provider) {
      return Promise.resolve()
    }
    let payment = {}
    if (additionalInfo?.payment) {
      payment = additionalInfo?.payment
    }
    await this._updateCartAdditionalInfo({
      payment: {
        ...payment,
        provider,
      },
    })
  }

  async cartToOrder() {
    const id = this.cart?.id != null ? this.cart.id.toString() : '' // not use the id so active cart is used
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerCartToOrderMutation,
      ApiSalesManagerCartToOrderMutationVariables
    >({
      mutation: ApiSalesManagerCartToOrderDocument,
      variables: {
        data: {
          id,
        },
      },
    })
    return mutateResult.data?.api_sales_manager_cart_to_order.result ?? ''
  }

  protected async _updateCartAdditionalInfo(additionalInfo: any) {
    if (this?.cart?.id) {
      await this._updateCart({
        type: this.getCartType(),
        id: this.cart.id,
        additional_info: additionalInfo
          ? {
              ...JSON.parse(JSON.stringify(this.cart?.additional_info ?? {})),
              ...additionalInfo,
            }
          : undefined,
      })
    }
  }

  protected async _updateCart(cart: CartUpdate) {
    if (this.updating) {
      return undefined
    }
    this.lockService()
    const id = cart.id ? cart.id.toString() : '' // not use the id so active cart is used
    const type = cart.type ? cart.type : this.getCartType()
    const status = cart.status ? cart.status : ''
    const orderId = cart.order_id ? cart.order_id.toString() : ''
    const additionalInfo = cart.additional_info
      ? JSON.stringify(cart.additional_info)
      : ''
    const items = cart.cart_items
      ? JSON.stringify(
          cart.cart_items.map((item) => {
            return {
              productErpId: item.product_erp_id,
              qty: item.qty,
              additionalInfo: item.additional_info,
            }
          }),
        )
      : ''
    const replacements = cart.cart_replacements
      ? JSON.stringify(
          cart.cart_replacements.map((item) => {
            return {
              replacementErpId: item.replacement_erp_id,
              qty: item.qty,
              additionalInfo: item.additional_info,
            }
          }),
        )
      : ''
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerCartUpdateMutation,
      ApiSalesManagerCartUpdateMutationVariables
    >({
      mutation: ApiSalesManagerCartUpdateDocument,
      variables: {
        data: {
          id,
          type,
          status,
          orderId,
          additionalInfo,
          items,
          replacements,
        },
      },
    })
    if (mutateResult.data) {
      this.getStore().setItem(
        JSON.parse(mutateResult.data.api_sales_manager_cart_update.result.cart),
      )
      this.cartTotals = JSON.parse(
        mutateResult.data.api_sales_manager_cart_update.result.totals,
      )
    }
    this.unlockService()
    // manage payload and push error messages
    const payload: SalesCartEnsurePayload[] = JSON.parse(
      mutateResult.data?.api_sales_manager_cart_update.result.payload ?? '[]',
    )
    this.manageEnsurePayload(payload)
    return mutateResult.data?.api_sales_manager_cart_update?.result?.id
      ? parseInt(
          mutateResult.data?.api_sales_manager_cart_update?.result?.id || '0',
        )
      : undefined
  }

  protected manageEnsurePayload(payload?: SalesCartEnsurePayload[]) {
    if (payload && payload.length) {
      for (const salesCartEnsurePayload of payload) {
        if (salesCartEnsurePayload.error?.messageCode) {
          const messageType =
            SalesCartAbstractService.API_PAYLOAD_MESSAGE_TYPE[
              salesCartEnsurePayload.error.code
            ] ?? MessageType.ERROR
          const messageValues = JSON.parse(
            JSON.stringify(salesCartEnsurePayload.error.parameters),
          )
          if (messageType === MessageType.ERROR) {
            this.systemMessageService.addErrorMessage(
              `api.error.${salesCartEnsurePayload.error.code}`,
              messageValues,
            )
          } else {
            this.systemMessageService.addInfoMessage(
              `api.info.${salesCartEnsurePayload.error.code}`,
              {
                ...messageValues,
                oldData: salesCartEnsurePayload.oldData,
                newData: salesCartEnsurePayload.newData,
              },
            )
          }
        }
      }
    }
  }

  async import(cart) {
    if (cart?.id) {
      return await this._updateCart({
        id: cart.id,
        status: 'imported',
        additional_info: {
          ...cart.additional_info,
          importedDate: new Date().toISOString(),
        },
      })
    }
    return undefined
  }

  /**
   * Parse an Excel file and returns errors if not in valid schemas
   * @see https://gitlab.com/catamphetamine/read-excel-file#errors
   * @param file
   */
  async parseImportableExcelFile(file) {
    await this.productPriceService.fetchAll()
    return await readXlsxFile(file, {
      transformData(data) {
        // @ts-ignore
        if (data?.[0] && isNaN(data[0][0])) {
          data.shift()
        }
        // @ts-ignore
        return [['codice', 'qt', 'riferimento']].concat(data)
      },
      schema: {
        codice: {
          prop: 'id',
          type: String,
          required: true,
        },
        qt: {
          prop: 'qty',
          type: String,
          required: true,
        },
        riferimento: {
          prop: 'notes',
          type: String,
          required: false,
        },
      },
    })
  }

  /**
   * Validates an Excel file to be ready to be imported
   * @see addToCartFromExcelFile
   * @param file
   */
  async validateExcelFile(file: File) {
    const validations: Array<ExcelFileError | Error> = []
    try {
      const { rows, errors } = await this.parseImportableExcelFile(file)
      if (rows.length > 0) {
        // adding standard parser errors
        validations.push(
          ...errors.map((e) => ({
            row: e.row,
            reason: e.error,
            col: e.column,
            value: e.value,
            extras: {},
          })),
        )
        const finalRows = rows as ImportableExcelRowObject[]

        const cart = this.cart
        const sameProductsInCart =
          cart?.cart_items.filter((ci) =>
            finalRows
              .map((fr) => this.fromSku(fr?.id?.toString() ?? ''))
              .includes(ci.product_erp_id),
          ) || []
        finalRows.forEach((r, i) => {
          const product = this.productPriceService.prices.find(
            (p) => p.erp_id === this.fromSku(r?.id?.toString() ?? ''),
          )
          const rowIndex = i + 2
          // checks if product exists
          if (product) {
            const cartItem = sameProductsInCart.find(
              (cartItem) => cartItem.product_erp_id === product.erp_id,
            )
            const cartItemQuantity = cartItem?.qty || 0
            // console.log(r.qty, product.box_pieces)
            if (
              !this.isQuantityValidForBox(
                r.qty + cartItemQuantity,
                product.box_pieces ?? 1,
              )
            ) {
              validations.push({
                reason: 'qty_invalid',
                row: rowIndex,
                col: 'qt',
                extras: {
                  box_pieces: product.box_pieces ?? 1,
                  qty: r.qty,
                  qty_total: r.qty + cartItemQuantity,
                },
              } as ExcelFileError)
            }
          } else if (r.id) {
            validations.push({
              reason: 'not_found',
              row: rowIndex,
              col: 'codice',
              extras: {
                id: r.id,
              },
            } as ExcelFileError)
          }
        })
      } else {
        validations.push(new Error('invalid_file'))
      }

      return { validations, rows: rows.length }
    } catch (e) {
      // console.error(e) // consider to remove this logging
      validations.push(new Error('invalid_file')) // ultrageneric error
    }
    return { validations: [], rows: 0 }
  }

  /**
   * Add multiple excel rows to the cart
   * @description Assumes the file is valid
   * @see validateExcelFile
   * @param file
   */
  async addToCartFromExcelFile(file: File) {
    const { rows } = await this.parseImportableExcelFile(file)
    const productsToAdd: AddableCartItem[] = []
    let importedQuantity = 0
    rows.forEach((r) => {
      const row = r as ImportableExcelRowObject
      if (row.id) {
        const fromSkuId = this.fromSku(row.id.toString())
        importedQuantity = Number(importedQuantity) + Number(row.qty)
        productsToAdd.push({
          sku: fromSkuId,
          quantity: row.qty,
          additionalInfo: row.notes
            ? {
                note: row.notes,
              }
            : {},
        })
      }
    })
    if (
      productsToAdd &&
      (await this.addToCartMany(Object.values(productsToAdd)))
    ) {
      this.systemMessageService.addSuccessMessage(
        `{imported} / {rows} rows imported from excel file - {productsImported} products`,
        {
          imported: Object.values(productsToAdd).length,
          rows: rows.length,
          productsImported: importedQuantity,
        },
        15000,
      )
    }
  }

  protected fromSku(sku: string) {
    sku = sku.padStart(6, '0')
    if (sku.length === 6) {
      return `8021696${sku}`
    }
    return sku
  }

  public isQuantityValidForBox(qty: number, box_pieces: number) {
    return qty % (box_pieces || 1) === 0
  }

  get orderNoteIsUpdating() {
    return this.getStore().isOrderNoteUpdating
  }

  get orderNote() {
    const additionalInfo = this.cartAdditionalInfo
    if (additionalInfo?.note) {
      return additionalInfo?.note
    } else {
      return ''
    }
  }

  get isUserAddingItems() {
    return this.getStore().isUserAddingItems
  }

  set isUserAddingItems(adding) {
    this.getStore().setIsUserAddingItems(adding)
  }

  get salesOrderShippingId() {
    const additionalInfo = this.cartAdditionalInfo
    return additionalInfo?.shipping?.id
  }

  get salesOrderPayment() {
    const additionalInfo = this.cartAdditionalInfo
    return additionalInfo?.payment?.provider && additionalInfo?.payment?.method
  }

  get cartReplacements() {
    return this.cart?.cart_replacements ?? []
  }

  async removeCartReplacementById(ids: number[]) {
    if (this.updating) {
      return false
    }
    this.lockService()
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerCartReplacementRemoveMutation,
      ApiSalesManagerCartReplacementRemoveMutationVariables
    >({
      mutation: ApiSalesManagerCartReplacementRemoveDocument,
      variables: {
        data: {
          ids,
        },
      },
    })
    if (
      mutateResult.data &&
      mutateResult.data?.api_sales_manager_cart_replacement_remove?.result
        ?.success &&
      mutateResult.data?.api_sales_manager_cart_replacement_remove?.result
        ?.cart &&
      mutateResult.data?.api_sales_manager_cart_replacement_remove?.result
        ?.totals
    ) {
      this.getStore().setItem(
        JSON.parse(
          mutateResult.data.api_sales_manager_cart_replacement_remove.result
            .cart,
        ),
      )
      this.cartTotals = JSON.parse(
        mutateResult.data.api_sales_manager_cart_replacement_remove.result
          .totals,
      )
    }
    this.unlockService()
    return (
      mutateResult.data?.api_sales_manager_cart_replacement_remove?.result
        ?.success ?? false
    )
  }

  async updateCartReplacementById(
    sku: string,
    quantity: number,
    id?: number,
    additionalInfo?: any,
  ) {
    if (this.updating) {
      return
    }
    this.lockService()
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerCartReplacementUpdateMutation,
      ApiSalesManagerCartReplacementUpdateMutationVariables
    >({
      mutation: ApiSalesManagerCartReplacementUpdateDocument,
      variables: {
        data: {
          id,
          cartId: this.cart?.id,
          replacementErpId: sku,
          qty: quantity,
          additionalInfo: additionalInfo
            ? JSON.stringify(additionalInfo)
            : undefined,
        },
      },
    })
    if (mutateResult.data) {
      this.getStore().setItem(
        JSON.parse(
          mutateResult.data.api_sales_manager_cart_replacement_update.result
            .cart,
        ),
      )
      this.cartTotals = JSON.parse(
        mutateResult.data.api_sales_manager_cart_replacement_update.result
          .totals,
      )
    }
    this.unlockService()
    // manage payload and push error messages
    const payload: SalesCartEnsurePayload[] = JSON.parse(
      mutateResult.data?.api_sales_manager_cart_replacement_update?.result
        ?.payload ?? '[]',
    )
    this.manageEnsurePayload(payload)
    return {
      id: mutateResult.data?.api_sales_manager_cart_replacement_update?.result
        ?.id,
      payload,
    }
  }
}
