import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { ExchangeRateQuery } from '~/graphql/types'

@Module({
  name: 'exchange-rate',
  stateFactory: true,
  namespaced: true,
})
export default class ExchangeRate extends VuexModule {
  fetchStarted = false
  loading = true
  items: ExchangeRateQuery['exchange_rate'] = []
  currentCurrencyCode = 'EUR'

  @Mutation
  setCurrentCurrencyCode(currency) {
    this.currentCurrencyCode = currency
  }

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
