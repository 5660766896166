import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import {
  DeleteProductReplacementByPkDocument,
  DeleteProductReplacementByPkMutation,
  DeleteProductReplacementByPkMutationVariables,
  DeleteProductReplacementDocument,
  DeleteProductReplacementMutation,
  DeleteProductReplacementMutationVariables,
  InsertProductReplacementDocument,
  InsertProductReplacementMapDocument,
  InsertProductReplacementMapMutation,
  InsertProductReplacementMapMutationVariables,
  InsertProductReplacementMutation,
  InsertProductReplacementMutationVariables,
  Order_By,
  ProductReplacementDocument,
  ProductReplacementListCustomerDocument,
  ProductReplacementListCustomerQuery,
  ProductReplacementListCustomerQueryVariables,
  ProductReplacementListDocument,
  ProductReplacementListQuery,
  ProductReplacementListQueryVariables,
  ProductReplacementQuery,
  ProductReplacementQueryVariables,
  ReplacementListDocument,
  ReplacementListQuery,
  ReplacementListQueryVariables,
  UpdateProductReplacementMapDocument,
  UpdateProductReplacementMapMutation,
  UpdateProductReplacementMapMutationVariables,
} from '~/graphql/types'
import { replacementStore } from '~/utils/store-accessor'
import { UserService } from '~/services/user.service'

export class ReplacementService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  baseImageURL = `///${window.$nuxt.$config.mediaDomain}/replacements`
  defaultImageURL = `///${window.$nuxt.$config.mediaDomain}/errors/generic.jpg`

  imageUrl(code?: string | null | undefined, timestamp?: number) {
    if (code && code.length > 0) {
      return `${this.baseImageURL}/${code}.jpg${
        timestamp ? '?t=' + timestamp : ''
      }`
    }
    return this.defaultImageURL
  }

  mapBaseImageURL = `///${window.$nuxt.$config.mediaDomain}/replacements/map`
  mapDefaultImageURL = `///${window.$nuxt.$config.mediaDomain}/errors/generic.jpg`

  mapImageUrl(code: string | null | undefined, timestamp?: number) {
    if (code && code.length > 0) {
      return `${this.mapBaseImageURL}/${code}.jpg${
        timestamp ? '?t=' + timestamp : ''
      }`
    }
    return this.mapDefaultImageURL
  }

  public store = replacementStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    let data
    if (
      this.userService.hasBackendSuperAdminRole ||
      this.userService.hasBackendAdminRole
    ) {
      data = (
        await this.apolloClientService.client.query<
          ProductReplacementListQuery,
          ProductReplacementListQueryVariables
        >({
          query: ProductReplacementListDocument,
        })
      ).data
    } else {
      data = (
        await this.apolloClientService.client.query<
          ProductReplacementListCustomerQuery,
          ProductReplacementListCustomerQueryVariables
        >({
          query: ProductReplacementListCustomerDocument,
        })
      ).data
    }

    if (data) {
      this.store.setItems(data.product)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get list() {
    return this.store.items
  }

  get search() {
    return this.store.search
  }

  get page() {
    return this.store.page
  }

  get itemsPerPage() {
    return this.store.itemsPerPage
  }

  set search(search) {
    this.store.setSearch(search)
  }

  set page(page) {
    this.store.setPage(page)
  }

  set itemsPerPage(itemsPerPage) {
    this.store.setItemsPerPage(itemsPerPage)
  }

  async replacementList(replacementErpId?: string, productErpId?: string) {
    const variables: ReplacementListQueryVariables = {
      where: {},
      order_by: {
        erp_id: Order_By.Asc,
        name: Order_By.Asc,
      },
    }
    if (replacementErpId) {
      variables.where!._or = [
        {
          erp_id: {
            _ilike: `%${replacementErpId}%`,
          },
        },
        {
          name: {
            _ilike: `%${replacementErpId}%`,
          },
        },
      ]
    }
    if (productErpId) {
      variables.where!.product_replacements = {
        product_erp_id: {
          _ilike: `%${productErpId}%`,
        },
      }
    }
    return (
      (
        await this.apolloClientService.client.query<
          ReplacementListQuery,
          ReplacementListQueryVariables
        >({
          query: ReplacementListDocument,
          variables,
        })
      ).data?.replacement ?? []
    )
  }

  async getProductReplacement(productErpId: string) {
    return (
      await this.apolloClientService.client.mutate<
        ProductReplacementQuery,
        ProductReplacementQueryVariables
      >({
        mutation: ProductReplacementDocument,
        variables: {
          productErpId,
        },
      })
    ).data?.product?.[0]
  }

  async deleteProductReplacementById(
    variables: DeleteProductReplacementByPkMutationVariables
  ) {
    const deleteMutation = await this.apolloClientService.client.mutate<
      DeleteProductReplacementByPkMutation,
      DeleteProductReplacementByPkMutationVariables
    >({
      mutation: DeleteProductReplacementByPkDocument,
      variables,
    })
    return deleteMutation.data?.delete_product_replacement_by_pk
  }

  async deleteProductReplacement(
    variables: DeleteProductReplacementMutationVariables
  ) {
    const deleteMutation = await this.apolloClientService.client.mutate<
      DeleteProductReplacementMutation,
      DeleteProductReplacementMutationVariables
    >({
      mutation: DeleteProductReplacementDocument,
      variables,
    })
    return (
      (deleteMutation.data?.delete_product_replacement?.affected_rows || 0) > 0
    )
  }

  async upsertProductReplacement(
    variables: InsertProductReplacementMutationVariables
  ) {
    const upsertMutation = await this.apolloClientService.client.mutate<
      InsertProductReplacementMutation,
      InsertProductReplacementMutationVariables
    >({
      mutation: InsertProductReplacementDocument,
      variables,
    })
    return upsertMutation.data?.insert_product_replacement
  }

  async upsertProductReplacementMap(
    variables: InsertProductReplacementMapMutationVariables
  ) {
    const upsertMutation = await this.apolloClientService.client.mutate<
      InsertProductReplacementMapMutation,
      InsertProductReplacementMapMutationVariables
    >({
      mutation: InsertProductReplacementMapDocument,
      variables,
    })
    return upsertMutation.data?.insert_product_replacement_map
  }

  async updateProductReplacementMap(
    variables: UpdateProductReplacementMapMutationVariables
  ) {
    const updateMutation = await this.apolloClientService.client.mutate<
      UpdateProductReplacementMapMutation,
      UpdateProductReplacementMapMutationVariables
    >({
      mutation: UpdateProductReplacementMapDocument,
      variables,
    })
    return (
      (updateMutation.data?.update_product_replacement_map?.affected_rows ??
        0) > 0
    )
  }
}
