
import Component from 'nuxt-class-component'
import AppSnackbars, { Snackbar } from '~/components/AppSnackbars.vue'
import App from '~/mixins/app'

@Component({
  components: {
    AppSnackbars,
  },
})
export default class AppSnackBarMessages extends App {
  get messages() {
    return this.systemMessageService.getMessages
  }

  set messages(messages) {
    this.systemMessageService.setMessages(messages)
  }

  color(message: Snackbar['message']) {
    return message.color
  }

  icon(snackbar: Snackbar) {
    return snackbar.color === 'success' ? 'mdi-check' : 'mdi-alert-circle'
  }

  message(snackbar: Snackbar) {
    return this.$t(snackbar.message.message, snackbar.message.values)
  }
}
