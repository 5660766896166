import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { AgentBonusQuery } from '~/graphql/types'

@Module({
  name: 'agent',
  stateFactory: true,
  namespaced: true,
})
export default class Agent extends VuexModule {
  // loading = true
  //
  // @Mutation
  // setLoading(loading) {
  //   this.loading = loading
  // }

  /**
   * rebate section
   */
  fetchRebateStarted = false
  loadingRebate = true
  rebate: AgentBonusQuery['agent_bonus'][0] | null = null

  @Mutation
  setFetchRebateStarted(fetchRebateStarted) {
    this.fetchRebateStarted = fetchRebateStarted
  }

  @Mutation
  setLoadingRebate(loadingRebate) {
    this.loadingRebate = loadingRebate
  }

  @Mutation
  setRebate(rebate) {
    this.rebate = rebate
  }
}
