import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { customerAddressStore } from '~/utils/store-accessor'
import {
  CustomerAddressDocument,
  CustomerAddressQuery,
  CustomerAddressQueryVariables,
} from '~/graphql/types'
import { UserService } from '~/services/user.service'

export class CustomerAddressService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = customerAddressStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        CustomerAddressQuery,
        CustomerAddressQueryVariables
      >({
        query: CustomerAddressDocument,
        variables: {
          where: {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
        },
      })
    ).data
    if (data) {
      this.store.setCustomerAddress(data.customer_address)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get customerAddress() {
    return this.store.customerAddress
  }
}
