import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { layoutStore } from '~/utils/store-accessor'
import { SettingService } from '~/services/setting.service'
import { ProductPriceService } from '~/services/product-price.service'
import { OrderPromoService } from '~/services/order-promo.service'

export class LayoutService {
  @Inject('SettingService') settingService!: SettingService
  @Inject('ProductPriceService')
  productPriceService!: ProductPriceService

  @Inject('OrderPromoService') orderPromoService!: OrderPromoService

  public store = layoutStore
  get pageName() {
    return this.store.pageName
  }

  get menuItems() {
    return this.store.menuItems.map((item) => {
      if (item.link === '/promo') {
        if (
          !this.settingService.promoEnableNow ||
          this.productPriceService.loading ||
          this.productPriceService.promoPrices.length === 0 ||
          this.orderPromoService.loading ||
          this.orderPromoService.items.length > 0
        ) {
          return { ...item, useInMenu: false, roles: [] }
        }
      }

      // TODO: temporary disabled in production until customer review of form fields
      if (
        item.link === '/new-customer' &&
        window.$nuxt.$config.apiDomain.endsWith('ideal-lux.com')
      ) {
        return { ...item, useInMenu: false, roles: [] }
      }
      return item
    })
  }

  updatePageName(name) {
    return this.store.setPageName(name)
  }
}
