import { Mutation, VuexModule } from 'vuex-module-decorators'
import { CartSubscription } from '~/graphql/types'

export type CartTotals = {
  currency: string
  items: {
    cart_item_id?: number
    cart_item_created_at: string
    product_erp_id: string
    qty: number
    amount: number
    product_amount: number
    currency: 'EUR'
    additional_info: {
      note: string
      discount: {
        amount: number
        amount_incl_tax: number
        percentage: number
      }
      product_amount_no_discount: number
      product_discount_percentage: number
      amount_incl_tax: number
      volume: number
      weight: number
      end_series: boolean
      quote?: {
        product_name: string
        // public unit price (no tax)
        product_amount_no_discount: number
        // public unit price (no tax) discounted
        product_amount: number
        // row total (no tax)
        amount: number
        // row total (with tax)
        amount_incl_tax: number
      }
      quote_discount_percentage?: number
    }
  }[]
  replacements: {
    cart_replacement_id?: number
    cart_replacement_created_at: string
    replacement_erp_id: string
    qty: number
    additional_info: {
      note: string
      product?: {
        name: string
      }
    }
  }[]
  discount: {
    amount: number
    amount_incl_tax: number
    percentage: number
  }
  free_shipping_missing_amount: number
  free_shipping_threshold_amount: number
  subtotal: number
  subtotal_incl_tax: number
  shipping: number
  shipping_incl_tax: number
  shipping_extra_cost: number
  shipping_extra_cost_incl_tax: number
  total: number
  total_excl_shipping: number
  total_incl_tax: number
  tax: number
  volume: number
  weight: number
  quote?: {
    total: number
    total_incl_tax: number
  }
}

export const ResetCartTotals: CartTotals = {
  currency: 'EUR',
  items: [],
  replacements: [],
  discount: {
    amount: 0,
    amount_incl_tax: 0,
    percentage: 0,
  },
  free_shipping_missing_amount: 0,
  free_shipping_threshold_amount: 0,
  subtotal: 0,
  subtotal_incl_tax: 0,
  shipping: 0,
  shipping_incl_tax: 0,
  shipping_extra_cost: 0,
  shipping_extra_cost_incl_tax: 0,
  total: 0,
  total_excl_shipping: 0,
  total_incl_tax: 0,
  tax: 0,
  volume: 0,
  weight: 0,
}

export type CartType = CartSubscription['cart'][0]
export type CartItemType = CartSubscription['cart'][0]['cart_items'][0]
export type CartReplacementType =
  CartSubscription['cart'][0]['cart_replacements'][0]

export default abstract class SalesCartAbstract extends VuexModule {
  loading = false
  updating = false
  emptying = false

  _item?: CartType = undefined
  _cartTotals: CartTotals = ResetCartTotals

  _isOrderNoteUpdating = false
  _isUserAddingItems = false

  get cart() {
    return this._item
  }

  get cartTotals() {
    return this._cartTotals
  }

  get isOrderNoteUpdating() {
    return this._isOrderNoteUpdating
  }

  get isUserAddingItems() {
    return this._isUserAddingItems
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setUpdating(updating) {
    this.updating = updating
  }

  @Mutation
  setEmptying(emptying) {
    this.emptying = emptying
  }

  @Mutation
  setItem(item) {
    this._item = item
  }

  @Mutation
  setCartTotals(cartTotals) {
    this._cartTotals = cartTotals
  }

  @Mutation
  setIsOrderNoteUpdating(updating) {
    this._isOrderNoteUpdating = updating
  }

  @Mutation
  setIsUserAddingItems(adding) {
    this._isUserAddingItems = adding
  }
}
