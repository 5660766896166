var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('div', {
    staticClass: "d-print-none"
  }, [_c('v-app-bar', {
    attrs: {
      "app": "",
      "dark": "",
      "clipped-left": "",
      "color": "secondary",
      "height": 64,
      "elevation": "0"
    }
  }, [_c('v-app-bar-nav-icon', {
    staticClass: "d-lg-none mr-0 mr-md-3",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _vm._v(" "), _c('v-toolbar-title', {
    staticClass: "b2b-title flex-shrink-0"
  }, [_c('nuxt-link', {
    staticClass: "d-flex",
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('app-logo', {
    staticClass: "ml-0",
    attrs: {
      "width": "100px",
      "dark": ""
    }
  })], 1), _vm._v(" "), _c('span', [_c('v-fade-transition', [_vm.layoutService.pageName ? _c('span', [_vm._v("\n            " + _vm._s(_vm.layoutService.pageName) + "\n          ")]) : _vm._e()]), _vm._v("\n         \n      ")], 1)], 1), _vm._v(" "), _c('v-spacer'), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-wrap flex-row-reverse justify-start"
  }, [_vm.userService.isAuthenticated && _vm.$vuetify.breakpoint.smAndUp ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "depressed": "",
      "color": "error",
      "to": _vm.localePath('/logout')
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('header.logout')))])]) : _vm._e(), _vm._v(" "), _c('v-select', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "auto",
      "max-width": "80px"
    },
    attrs: {
      "dense": "",
      "full-width": false,
      "items": _vm.availableLocales,
      "item-value": "code",
      "hide-details": "",
      "item-text": "name"
    },
    model: {
      value: _vm.selectedLocale,
      callback: function ($$v) {
        _vm.selectedLocale = $$v;
      },
      expression: "selectedLocale"
    }
  }), _vm._v(" "), _c('v-select', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "auto",
      "max-width": "150px"
    },
    attrs: {
      "dense": "",
      "full-width": false,
      "items": _vm.availableCurrencies,
      "item-value": "code",
      "hide-details": "",
      "item-text": "name"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.icon === 'mdi-currency-eur-off' ? _c('strong', {
          staticStyle: {
            "margin-right": "8px"
          },
          domProps: {
            "textContent": _vm._s(item.symbol)
          }
        }) : _c('v-icon', {
          attrs: {
            "left": ""
          },
          domProps: {
            "textContent": _vm._s(item.icon)
          }
        }), _vm._v("\n          " + _vm._s(item.name) + "\n        ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.icon === 'mdi-currency-eur-off' ? _c('strong', {
          staticStyle: {
            "margin-right": "8px"
          },
          domProps: {
            "textContent": _vm._s(item.symbol)
          }
        }) : _c('v-icon', {
          attrs: {
            "left": ""
          },
          domProps: {
            "textContent": _vm._s(item.icon)
          }
        }), _vm._v("\n          " + _vm._s(item.name) + "\n        ")];
      }
    }]),
    model: {
      value: _vm.selectedCurrency,
      callback: function ($$v) {
        _vm.selectedCurrency = $$v;
      },
      expression: "selectedCurrency"
    }
  })], 1)], 1), _vm._v(" "), _vm.userService.isAuthenticated ? _c('v-navigation-drawer', {
    staticClass: "secondary",
    style: _vm.customNavigationDrawerStyle,
    attrs: {
      "app": "",
      "dark": "",
      "left": "",
      "temporary": !_vm.$vuetify.breakpoint.lgAndUp,
      "clipped": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_vm.isAgent && !_vm.customerService.loading ? _c('div', {
          staticClass: "secondary"
        }, [_c('v-divider'), _vm._v(" "), _c('v-list-item', [_c('v-list-item-content', [_c('v-autocomplete', {
          staticClass: "select-customer",
          attrs: {
            "items": _vm.customers,
            "value": _vm.selectedCustomer,
            "auto-select-first": "",
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "label": _vm.$t('header.selectCustomerLabel'),
            "item-text": "name",
            "item-value": "user_id"
          },
          on: {
            "change": _vm.selectCustomer
          },
          scopedSlots: _vm._u([_vm.isAgentCustomer ? {
            key: "prepend-item",
            fn: function () {
              return [_c('v-list-item', {
                on: {
                  "click": _vm.deselectCustomer
                }
              }, [_c('v-list-item-content', {
                staticClass: "v-list-item-deselect-customer"
              }, [_vm._v("\n                    " + _vm._s(_vm.$t('header.deselectCustomerLabel')) + "\n                  ")])], 1), _vm._v(" "), _c('v-divider', {
                staticClass: "mb-2"
              })];
            },
            proxy: true
          } : null, {
            key: "item",
            fn: function (_ref3) {
              var item = _ref3.item;
              return [[_c('v-list-item-content', {
                staticClass: "v-list-item-select-customer"
              }, [_c('v-list-item-title', [_vm._v("\n                      " + _vm._s(_vm.typedCustomer(item).name) + "\n                    ")]), _vm._v(" "), _c('v-list-item-subtitle', [_vm._v("\n                      " + _vm._s(_vm.typedCustomer(item).address) + "\n                      " + _vm._s(_vm.typedCustomer(item).address_2) + "\n                      -\n                      " + _vm._s(_vm.typedCustomer(item).zip_code) + "\n                      " + _vm._s(_vm.typedCustomer(item).city) + "\n                      (" + _vm._s(_vm.typedCustomer(item).province ? `${_vm.typedCustomer(item).province} -` : '') + _vm._s(_vm.typedCustomer(item).country) + ")\n                    ")])], 1)]];
            }
          }], null, true)
        })], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c('v-divider'), _vm._v(" "), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('v-icon', {
          attrs: {
            "left": "",
            "size": "24"
          }
        }, [_vm._v("mdi-account-circle")]), _vm._v(" "), _c('span', {
          staticClass: "button"
        }, [_vm._v(_vm._s(_vm.userDisplayName))])], 1)], 1), _vm._v(" "), _vm.$vuetify.breakpoint.xsOnly ? _c('v-list-item-action', {
          staticClass: "ml-1 my-auto"
        }, [_vm.userService.isAuthenticated ? _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "depressed": "",
            "color": "error",
            "to": _vm.localePath('/logout')
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('header.logout')))])]) : _vm._e()], 1) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 2383220639),
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_c('v-list-item-group', [_vm._l(_vm.menuItems, function (menuItem) {
    var _menuItem$class;
    return [menuItem.prependDivider ? _c('v-divider', {
      key: `prepend-divider-${menuItem.link}`,
      staticClass: "mb-1"
    }) : _vm._e(), _vm._v(" "), _c('v-list-item', {
      key: menuItem.link,
      class: `b2b-nav-item ${(_menuItem$class = menuItem.class) !== null && _menuItem$class !== void 0 ? _menuItem$class : ''}`,
      attrs: {
        "active-class": "",
        "to": _vm.localePath(menuItem.link)
      }
    }, [_c('v-list-item-icon', [menuItem.icon || menuItem.counterIcon ? _c('v-badge', {
      attrs: {
        "value": menuItem.counter && menuItem.counter > 0,
        "content": menuItem.counter,
        "right": "",
        "overlap": ""
      }
    }, [_c('v-icon', [_vm._v(_vm._s(menuItem.counterIcon && menuItem.counter && menuItem.counter > 0 ? menuItem.counterIcon : menuItem.icon) + "\n                ")])], 1) : _vm._e()], 1), _vm._v(" "), _c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(menuItem.label)))])], 1)];
  })], 2)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.promoPopupEnable ? _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.dialogPromo,
      callback: function ($$v) {
        _vm.dialogPromo = $$v;
      },
      expression: "dialogPromo"
    }
  }, [[_c('v-card', [_c('v-card-title'), _vm._v(" "), _c('v-card-text', [_vm._v(_vm._s(_vm.$t('promo.popup.label', {
    amount: 10,
    threshold: 20
  })))]), _vm._v(" "), _c('v-card-actions', [_c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "depressed": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialogPromo = false;
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('promo.popup.action.skip')) + "\n          ")]), _vm._v(" "), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": function ($event) {
        _vm.goTo('promo');
        _vm.dialogPromo = false;
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('promo.popup.action.goto')) + "\n          ")])], 1)], 1)]], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }