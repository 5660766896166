import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiInsertSubcustomerDocument,
  ApiInsertSubcustomerMutation,
  ApiInsertSubcustomerMutationVariables,
  ApiUpdateSubcustomerDocument,
  ApiUpdateSubcustomerMutation,
  ApiUpdateSubcustomerMutationVariables,
  DeleteUserByPkMutationVariables,
  Role_Enum,
  SubcustomerUserDocument,
  SubcustomerUserSubscription,
  SubcustomerUserSubscriptionVariables,
} from '~/graphql/types'
import { subcustomerStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class SubcustomerService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = subcustomerStore

  private _o: ZenObservable.Subscription | undefined

  subscribe() {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<
          SubcustomerUserSubscription,
          SubcustomerUserSubscriptionVariables
        >({
          query: SubcustomerUserDocument,
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.user)
          }
          this.store.setLoading(false)
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  get items() {
    return this.store.items
  }

  get loading() {
    return this.store.loading
  }

  get role() {
    return Role_Enum.SubCustomer
  }

  delete(variables: DeleteUserByPkMutationVariables) {
    return this.userService.delete(variables)
  }

  async insert(variables: ApiInsertSubcustomerMutationVariables) {
    const insertMutation = await this.apolloClientService.client.mutate<
      ApiInsertSubcustomerMutation,
      ApiInsertSubcustomerMutationVariables
    >({
      mutation: ApiInsertSubcustomerDocument,
      variables,
    })

    return insertMutation.data?.api_insert_subcustomer
  }

  async update(variables: ApiUpdateSubcustomerMutationVariables) {
    const updateMutation = await this.apolloClientService.client.mutate<
      ApiUpdateSubcustomerMutation,
      ApiUpdateSubcustomerMutationVariables
    >({
      mutation: ApiUpdateSubcustomerDocument,
      variables,
    })

    return updateMutation.data?.api_update_subcustomer
  }
}
