export type PrizeStage = {
  label: string
  currentRevenue: number
  startRevenue: number
  targetRevenue: number
  rate: number
}

export class RebateService {
  findBonusRate(rates: PrizeStage[], revenue: number) {
    const highestRate = rates.reduce((max, stage) =>
      {
        if (revenue >= stage.targetRevenue) {
          return stage
        } else {
          return max
        }
      }
    )
    if (revenue >= highestRate.targetRevenue) {
      return highestRate.rate
    }
    return 0
  }
}
