import 'reflect-metadata'
import { salesCartPromoStore } from '~/utils/store-accessor'
import { SalesCartAbstractService } from '~/services/sales-cart-abstract.service'
import SalesCartPromo from '~/store/sales-cart-promo'

export class SalesCartPromoService extends SalesCartAbstractService {
  getStore(): SalesCartPromo {
    return salesCartPromoStore
  }

  getCartType(): string {
    return 'promo'
  }

  subcustomerId() {
    return ''
  }
}
