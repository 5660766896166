import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { countryRegion } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'
import {
  CountryRegionDocument,
  CountryRegionQuery,
  CountryRegionQueryVariables,
} from '~/graphql/types'

export class CountryRegionService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = countryRegion

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        CountryRegionQuery,
        CountryRegionQueryVariables
      >({
        query: CountryRegionDocument,
      })
    ).data

    if (data) {
      this.store.setItems(data.country_region)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get items() {
    return this.store.items
  }
}
