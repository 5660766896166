import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'
import { agentStore } from '~/utils/store-accessor'
import {
  AgentBonusDocument,
  AgentBonusQuery,
  AgentBonusQueryVariables,
} from '~/graphql/types'

export class AgentService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = agentStore

  // get loading() {
  //   return this.store.loading
  // }

  /**
   * rebate section
   */

  async fetchRebate(force = false) {
    if (this.store.fetchRebateStarted && !force) {
      return
    }
    this.store.setFetchRebateStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        AgentBonusQuery,
        AgentBonusQueryVariables
      >({
        query: AgentBonusDocument,
      })
    ).data

    if (data && data.agent_bonus.length === 1) {
      this.store.setRebate(data.agent_bonus[0])
    }
    this.store.setLoadingRebate(false)
  }

  get loadingRebate() {
    return this.store.loadingRebate
  }

  get rebate() {
    return this.store.rebate
  }
}
