
/**
 * @see https://github.com/Aymkdn/v-snackbars
 */
import Component from 'nuxt-class-component'
import { Prop, Vue, Watch } from 'nuxt-property-decorator'
import { MessageObject } from '~/store/system-messages'
import App from '~/mixins/app'

export type SnackbarMessageObject = MessageObject

export interface Snackbar {
  key
  message: SnackbarMessageObject
  top
  right
  left
  bottom
  color
  transition
  timeout
  show
  contentClass
}

@Component({
  inheritAttrs: false,
  components: {
    'css-style': {
      render(createElement) {
        return createElement('style', this.$slots.default)
      },
    },
  },
})
export default class AppSnackbars extends App {
  len = 0 // we need it to have a css transition
  snackbars: Snackbar[] = [] // array of {key, message, top, right, left, bottom, color, transition, timeout, show}
  keys: string[] = [] // array of 'keys'
  heights = {} // height of each snackbar to correctly position them
  identifier = Date.now() + (Math.random() + '').slice(2) // to avoid issues when several v-snackbars on the page

  @Prop({
    type: Array as () => SnackbarMessageObject[],
    default: () => [],
  })
  messages!: SnackbarMessageObject[]

  @Prop({
    type: [Number, String],
    default: 5000,
  })
  timeout!: Number

  @Prop({
    type: [Number, String],
    default: 55,
  })
  distance!: Number

  @Prop({
    type: Array as () => SnackbarMessageObject[],
    default: () => [],
  })
  objects!: SnackbarMessageObject[]

  readonly $refs!: Record<
    string,
    Vue['$refs'] & { isActive: boolean } & { $el: Element }
  > &
    Vue['$refs']

  get allMessages() {
    if (this.objects.length > 0) return this.objects.map((o) => o)
    return this.messages
  }

  // to correcly position the snackbar
  get indexPosition() {
    const ret = {}
    const idx = {
      topCenter: 0,
      topLeft: 0,
      topRight: 0,
      bottomCenter: 0,
      bottomLeft: 0,
      bottomRight: 0,
    }
    this.snackbars.forEach((o) => {
      if (o.top && !o.left && !o.right) ret[o.key] = idx.topCenter++
      if (o.top && o.left) ret[o.key] = idx.topLeft++
      if (o.top && o.right) ret[o.key] = idx.topRight++
      if (o.bottom && !o.left && !o.right) ret[o.key] = idx.bottomCenter++
      if (o.bottom && o.left) ret[o.key] = idx.bottomLeft++
      if (o.bottom && o.right) ret[o.key] = idx.bottomRight++
    })
    return ret
  }

  get topOrBottom() {
    const ret = {}
    this.snackbars.forEach((o) => {
      ret[o.key] = o.top ? 'top' : 'bottom'
    })
    return ret
  }

  @Watch('messages')
  onMessagesUpdate() {
    this.eventify(this.messages)
  }

  @Watch('objects', { deep: true })
  onObjectsUpdate() {
    this.eventify(this.objects)
  }

  mounted() {
    this.eventify(this.messages)
    this.eventify(this.objects)
    this.setSnackbars()
    if (Object.keys(this.$refs).length > 0) {
      Object.keys(this.$refs).forEach((a) => {
        this.$refs[a].isActive = true
      })
    }
  }

  getProp(message, prop) {
    if (message && typeof message[prop] !== 'undefined') return message[prop]
    if (typeof this.$attrs[prop] !== 'undefined') return this.$attrs[prop]
    if (typeof this[prop] !== 'undefined') return this[prop]
    return undefined
  }

  setSnackbars() {
    const allMessages = this.allMessages
    for (let i = this.snackbars.length; i < allMessages.length; i++) {
      const message = allMessages[i]
      const key = i + '-' + Date.now()
      let top = this.getProp(message, 'top')
      let bottom = this.getProp(message, 'bottom')
      let left = this.getProp(message, 'left')
      let right = this.getProp(message, 'right')
      top = top === '' ? true : top
      bottom = bottom === '' ? true : bottom
      left = left === '' ? true : left
      right = right === '' ? true : right
      // by default, it will be at the bottom
      if (!bottom && !top) bottom = true
      this.snackbars.push({
        key,
        message,
        top,
        bottom,
        left,
        right,
        color: this.getProp(message, 'color') || 'black',
        contentClass:
          this.getProp(message, 'content-class') ||
          this.getProp(message, 'contentClass') ||
          '',
        timeout: null,
        transition:
          this.getProp(message, 'transition') ||
          (right ? 'slide-x-reverse-transition' : 'slide-x-transition'),
        show: false,
      })
      this.keys.push(key)

      this.$nextTick(() => {
        this.snackbars[i].show = true // to see the come-in animation

        this.$nextTick(() => {
          // find the correct height
          let height = this.distance
          const elem = document.querySelector(
            '.v-snackbars-' + this.identifier + '-' + key
          )

          if (elem) {
            const wrapper = elem.querySelector('.v-snack__wrapper')
            if (wrapper) {
              height = wrapper.clientHeight + 7
            }
          }
          this.$set(this.heights, key, height)

          // define the timeout
          const timeout = this.getProp(message, 'timeout')
          if (timeout > 0) {
            this.snackbars[i].timeout = setTimeout(
              () => this.removeMessage(key, true),
              timeout * 1
            )
          }
        })
      })
    }
  }

  removeMessage(key, fromComponent = false) {
    const idx = this.snackbars.findIndex((s) => s.key === key)
    if (idx > -1) {
      this.snackbars[idx].show = false

      const removeSnackbar = () => {
        const idx = this.snackbars.findIndex((s) => s.key === key)
        this.snackbars.splice(idx, 1)
        // dipose all
        this.keys = this.keys.filter((k) => k !== key)
        delete this.heights[key]
        // only send back the changes if it happens from this component
        if (fromComponent) {
          this.$emit(
            'update:messages',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.allMessages.filter((m, i) => i !== idx)
          )
          this.$emit(
            'update:objects',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.objects.filter((m, i) => i !== idx)
          )
        }
      }
      // if a timeout on the snackbar, clear it
      if (this.snackbars[idx].timeout) clearTimeout(this.snackbars[idx].timeout)

      // use a timeout to ensure the 'transitionend' will be triggerred
      const timeout = setTimeout(removeSnackbar, 600)

      // skip waiting if key does not exist
      const ref = this.$refs['v-snackbars-' + this.identifier]
      if (!ref || !ref[idx]) return

      // wait the end of the animation
      if (
        this.$refs['v-snackbars-' + this.identifier] &&
        this.$refs['v-snackbars-' + this.identifier].$el
      ) {
        this.$refs['v-snackbars-' + this.identifier].$el.addEventListener(
          'transitionend',
          () => {
            clearTimeout(timeout)
            removeSnackbar()
          },
          { once: true }
        )
      }
    }
  }

  calcDistance(key) {
    // calculate the position in the stack for the snackbar
    let distance = 0
    const snackbar = this.snackbars.find((s) => s.key === key)
    if (!snackbar) return 0
    for (let i = 0; i < this.snackbars.length; i++) {
      // we add all the heights for each visible snackbar in the same corner
      if (this.snackbars[i].key === key) break
      if (
        this.snackbars[i].show &&
        this.snackbars[i].bottom === snackbar.bottom &&
        this.snackbars[i].top === snackbar.top &&
        this.snackbars[i].right === snackbar.right &&
        this.snackbars[i].left === snackbar.left
      ) {
        distance += this.heights[this.snackbars[i].key] || 0
      }
    }

    return distance
  }

  eventify(arr) {
    // detect changes on 'messages' and 'objects'
    const eventify = (arr) => {
      arr.isEventified = true
      // overwrite 'push' method
      const pushMethod = arr.push
      arr.push = (e) => {
        pushMethod.call(arr, e)
        this.setSnackbars()
      }
      // overwrite 'splice' method
      const spliceMethod = arr.splice
      arr.splice = () => {
        const args: any[] = []
        let len = arguments.length
        while (len--) args[len] = arguments[len]
        spliceMethod.apply(arr, args)
        let idx = args[0]
        let nbDel = args[1]
        const elemsLen = args.length - 2

        // do we just remove an element?
        if (elemsLen === 0) {
          nbDel += idx
          while (idx < nbDel) {
            if (this.snackbars[idx]) {
              this.removeMessage(this.snackbars[idx].key)
            }
            idx++
          }
        } else if (elemsLen > 0) {
          // or we set a value on an element using this.$set, so we update the message
          for (let i = 2; i < elemsLen + 2; i++) {
            if (typeof args[i] === 'string') {
              this.$set(this.snackbars[idx], 'message', args[i])
            } else if (typeof args[i] === 'object') {
              for (const prop in args[i]) {
                if (prop === 'timeout') {
                  const timeout = args[i][prop] * 1
                  // if there's an existing timeout, clear it before setting the new timeout
                  if (this.snackbars[idx].timeout) {
                    clearTimeout(this.snackbars[idx].timeout)
                    this.snackbars[idx].timeout = null
                  }
                  if (timeout > -1) {
                    const key = this.snackbars[idx].key
                    this.snackbars[idx].timeout = setTimeout(() => {
                      this.removeMessage(key, true)
                    }, timeout)
                  }
                } else {
                  // update the property
                  this.$set(this.snackbars[idx], prop, args[i][prop])
                }
              }
            }
          }
          idx++
        }
      }
    }
    if (!arr.isEventified) eventify(arr)
  }
}
