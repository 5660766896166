import { cid, container } from 'inversify-props'
import { UserService } from '~/services/user.service'

export default async function ({ app, route, redirect }) {
  const userService = container.get<UserService>(cid.UserService)

  if (route.query.iframe === '1') {
    await userService.logout()
  }

  // If the user is authenticated redirect to home page
  if (app.$apolloHelpers.getToken()) {
    return redirect(app.localePath('/'))
  }
}
