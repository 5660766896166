import { Inject } from 'inversify-props'
import { SalesCartOrderService } from '~/services/sales-cart-order.service'
import {
  NewAbstractService,
  StepperStep,
} from '~/services/new-abstract.service'
import { newOrderStore } from '~/utils/store-accessor'
import NewAbstract from '~/store/new-abstract'

export class NewOrderService extends NewAbstractService {
  @Inject('SalesCartOrderService') salesCartService!: SalesCartOrderService

  getStore(): NewAbstract {
    return newOrderStore
  }

  getSteps(): StepperStep[] {
    return [
      { step: 1, stepLabelKey: 'sales.step.cart.label' },
      { step: 2, stepLabelKey: 'sales.step.shipment.label' },
      { step: 3, stepLabelKey: 'sales.step.payment.label' },
      { step: 4, stepLabelKey: 'sales.step.review.label' },
    ]
  }
}
