import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { OrderPromoSubscription } from '~/graphql/types'

@Module({
  name: 'order-promo',
  stateFactory: true,
  namespaced: true,
})
export default class OrderPromo extends VuexModule {
  loading = true
  items: OrderPromoSubscription['order'] = []

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(items) {
    this.items = items
  }
}
