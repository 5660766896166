import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { ApolloProvider } from "vue-apollo/types/apollo-provider";

export class ApolloClientService {
  @Inject('$apollo')
  private _client!: ApolloProvider['defaultClient']

  get client() {
    return this._client
  }
}
