import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiSalesManagerCancelOrderDocument,
  ApiSalesManagerCancelOrderMutation,
  ApiSalesManagerCancelOrderMutationVariables,
  ApiSalesManagerCaptureOrderDocument,
  ApiSalesManagerCaptureOrderMutation,
  ApiSalesManagerCaptureOrderMutationVariables,
  ApiSalesManagerResetOrderDocument,
  ApiSalesManagerResetOrderMutation,
  ApiSalesManagerResetOrderMutationVariables,
  OrderByIncrementIdDocument,
  OrderByIncrementIdQuery,
  OrderByIncrementIdQueryVariables,
  OrderListDocument,
  OrderListQuery,
  OrderListQueryVariables,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'
import { adminOrderStore } from '~/utils/store-accessor'

export class AdminOrderService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  public store = adminOrderStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)

    const where: OrderListQueryVariables['where'] = {
      status: {
        _in: ['new', 'pending'],
      },
      // order_transactions_aggregate: {
      //   count: {
      //     predicate: {
      //       _gt: 0,
      //     },
      //   },
      // },
    }

    const data = (
      await this.apolloClientService.client.query<
        OrderListQuery,
        OrderListQueryVariables
      >({
        query: OrderListDocument,
        variables: {
          where,
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.order)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get list() {
    return this.store.items
  }

  async getByIncrementId(incrementId: string | number) {
    const order = (
      await this.apolloClientService.client.query<
        OrderByIncrementIdQuery,
        OrderByIncrementIdQueryVariables
      >({
        query: OrderByIncrementIdDocument,
        variables: {
          incrementId:
            typeof incrementId === 'string'
              ? parseInt(incrementId)
              : incrementId,
        },
      })
    ).data.order[0]
    if (!order) {
      throw new Error('non va bene')
    }
    return order
  }

  async cancelOrderByIncrementId(incrementId: string) {
    const result = await this.apolloClientService.client.mutate<
      ApiSalesManagerCancelOrderMutation,
      ApiSalesManagerCancelOrderMutationVariables
    >({
      mutation: ApiSalesManagerCancelOrderDocument,
      variables: {
        data: {
          id: incrementId,
        },
      },
    })
    return !!result.data?.api_sales_manager_cancel_order?.result
  }

  async resetOrderByIncrementId(
    incrementId: string,
    payment?: {
      provider?: string
      method?: string
      condition?: string
    }
  ) {
    const result = await this.apolloClientService.client.mutate<
      ApiSalesManagerResetOrderMutation,
      ApiSalesManagerResetOrderMutationVariables
    >({
      mutation: ApiSalesManagerResetOrderDocument,
      variables: {
        data: {
          id: incrementId,
          provider: payment?.provider ?? '',
          method: payment?.method ?? '',
          condition: payment?.condition ?? '',
        },
      },
    })
    return result.data?.api_sales_manager_reset_order?.result
  }

  async captureOrderByIncrementId(incrementId: string) {
    const result = await this.apolloClientService.client.mutate<
      ApiSalesManagerCaptureOrderMutation,
      ApiSalesManagerCaptureOrderMutationVariables
    >({
      mutation: ApiSalesManagerCaptureOrderDocument,
      variables: {
        data: {
          id: incrementId,
        },
      },
    })
    return result.data?.api_sales_manager_capture_order?.result ?? false
  }

  public isPaymentProviderOnline(payment: {
    provider?: string
    method?: string
  }) {
    return payment?.provider !== 'erp'
  }
}
