import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'user',
  stateFactory: true,
  namespaced: true,
})
export default class User extends VuexModule {
  refreshTokenTimeout: any = null

  @Mutation
  setRefreshTokenTimeout(value) {
    this.refreshTokenTimeout = value
  }
}
