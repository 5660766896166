import { NuxtApp } from '@nuxt/types/app'
interface NuxtWindow extends Window {
  onNuxtReady(obj: object): void
}
declare let window: NuxtWindow

window.onNuxtReady((app) => {
  const nuxt = app as NuxtApp
  nuxt.$vuetify.lang.current = nuxt.$i18n.locale
  nuxt.$watch('$i18n.locale', () => {
    nuxt.$vuetify.lang.current = nuxt.$i18n.locale
  })
})
