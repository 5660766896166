import moment from 'moment'
import { Component, Vue } from 'nuxt-property-decorator'
import { Inject } from 'inversify-props'
import { SystemMessageService } from '~/services/system-message.service'
import { ExchangeRateService } from '~/services/exchange-rate.service'

@Component
export default class App extends Vue {
  @Inject('SystemMessageService') systemMessageService!: SystemMessageService
  @Inject('ExchangeRateService') exchangeRateService!: ExchangeRateService

  formRules = {
    required: (value) =>
      value instanceof Array && !value.length
        ? this.$t('form.validation.message.required')
        : !!value || this.$t('form.validation.message.required'),
    notEmptyString: (value) =>
      value === '' || this.$t('form.validation.message.required'),
    requiredFile: (value) =>
      !!value || this.$t('form.validation.message.requiredFile'),
    percentage: (value) =>
      (Number(value) >= 0 && Number(value) <= 100) ||
      this.$t('form.validation.message.percentage'),
    digits: (value) =>
      /^[0-9]*$/.test(value) || this.$t('form.validation.message.integer'),
    email: (value) => {
      if (value === '' || value === null) {
        return true
      }
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || this.$t('form.validation.message.email')
    },
    emailMany: (value) => {
      if (Array.isArray(value)) {
        if (value.length) {
          for (const maybeEmail of value) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (!pattern.test(maybeEmail)) {
              return this.$t('form.validation.message.emailMany')
            }
          }
        }
      }
      return true
    },
    limit: (value, characters = 100) =>
      (value || '').length <= characters ||
      this.$t('form.validation.message.limit', {
        characters: characters.toString(),
      }),
    limitMin: (value, characters = 0) =>
      (value || '').length === 0 ||
      (value || '').length >= characters ||
      this.$t('form.validation.message.limitMin', {
        characters: characters.toString(),
      }),
    min: (value, minValue = 0) =>
      (value || 0) >= minValue ||
      this.$t('form.validation.message.min', {
        minValue: minValue.toString(),
      }),
    max: (value, maxValue = 100) =>
      (value || 0) <= maxValue ||
      this.$t('form.validation.message.max', {
        maxValue: maxValue.toString(),
      }),
  }

  priceManager = {
    toNumber: (value: number | string) => {
      if (typeof value === 'string') {
        value = parseFloat(value)
      }
      return Math.round((value + Number.EPSILON) * 100) / 100
    },
    toFixed: (value: number | string, precision = 2) => {
      return this.priceManager.toNumber(value).toFixed(precision)
    },
    toPercentage: (value, precision = 0) => {
      const nf = new Intl.NumberFormat('it-IT', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: precision,
      })
      return `${nf.format(value)}%`
    },
    toCurrency: (
      value: number | string,
      zeroAlt?: string,
      currency?: string,
      locale?: string
    ) => {
      const currentCurrency = this.exchangeRateService.currentCurrency
      let rate = 1
      if (!currency) {
        if (currentCurrency) {
          currency = this.exchangeRateService.currentCurrency?.code ?? 'EUR'
          rate = this.exchangeRateService.currentCurrency?.rate ?? 1
        } else {
          currency = 'EUR'
        }
      } else {
        // find rate based on currency provided
        rate =
          this.exchangeRateService.currencies.find((c) => c?.code === currency)
            ?.rate ?? 1
      }
      if (!locale) {
        locale = 'it-IT'
      }
      const ratedValue = this.priceManager.toNumber(
        this.priceManager.toNumber(value) * rate
      )
      if (typeof zeroAlt === 'string' && ratedValue === 0) {
        return zeroAlt
      }
      return this.$n(ratedValue, {
        style: 'currency',
        currency,
        locale,
      })
      // return new Intl.NumberFormat(locale, {
      //   style: 'currency',
      //   currency,
      // }).format(ratedValue)
    },
  }

  dateManager = {
    toDate: (
      value?: number | string | Date,
      format?: string,
      time?: boolean
    ) => {
      if (value == null) {
        return ''
      }
      if (!format) {
        if (time) {
          format = 'DD/MM/YYYY hh:mm'
        } else {
          format = 'DD/MM/YYYY'
        }
      }
      return moment(value).format(format)
    },
    toDateTime: (value?: number | string | Date) => {
      return this.dateManager.toDate(value, undefined, true)
    },
    toDateFormatted: (
      value?: number | string | Date,
      options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
      locale?: string
    ) => {
      if (value == null) {
        return ''
      } else if (typeof value === 'number') {
        value = Number(value)
      } else if (typeof value === 'string') {
        value = moment(value).toDate().getTime()
      } else {
        // implicitly a Date object
        value = value.getTime()
      }
      if (!locale) {
        locale = 'it-IT'
      }
      // return this.$d(value, undefined, locale)
      return new Intl.DateTimeFormat(locale, options).format(value)
    },
  }

  dimensionManager = {
    volumeUnit: 'm³',
    weightUnit: 'Kg',
    toVolume: (value: number, locale?: string, unit: boolean = true) => {
      if (!value) {
        return this.$t('cart.item.volumeNotDefined')
      }
      if (!locale) {
        locale = 'it-IT'
      }
      return `${new Intl.NumberFormat(locale, {
        minimumFractionDigits: 3,
      }).format(value)}${unit ? ' ' + this.dimensionManager.volumeUnit : ''}`
    },
    toWeight: (value: number, locale?: string, unit: boolean = true) => {
      if (!value) {
        return this.$t('cart.item.volumeNotDefined')
      }
      if (!locale) {
        locale = 'it-IT'
      }
      return `${new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
      }).format(value)}${unit ? ' ' + this.dimensionManager.weightUnit : ''}`
    },
  }

  productManager = {
    toSku: (sku?: string | null) => {
      if (sku) {
        const toRemoveCharacters = 7
        return sku.length > toRemoveCharacters
          ? sku.slice(toRemoveCharacters)
          : sku
      }
      return sku
    },
  }

  fileManager = {
    downloadableFile(filename) {
      return `///${window.$nuxt.$config.mediaDomain}/downloads/${filename}`
    },
    documentFile(folder, filename) {
      return `///${window.$nuxt.$config.mediaDomain}/documents/${folder}/${filename}`
    },
  }

  goTo(path: string, prefix = true) {
    this.$router.push(this.localePath(`${prefix ? '/' : ''}${path}`))
  }

  orderPathById(id: string | number) {
    return `/order/${id}`
  }

  goToOrder(id: string | number) {
    this.goTo(this.orderPathById(id), false)
  }
}
