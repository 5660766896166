import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiSalesManagerFormMutation,
  ApiSalesManagerFormMutationVariables,
  ApiSalesManagerFormDocument,
  ApiSalesManagerPayMutation,
  ApiSalesManagerPayMutationVariables,
  ApiSalesManagerPayDocument,
  ApiSalesManagerRegisterMutation,
  ApiSalesManagerRegisterMutationVariables,
  ApiSalesManagerRegisterDocument,
  ApiSalesManagerAmountQuery,
  ApiSalesManagerAmountQueryVariables,
  ApiSalesManagerAmountDocument,
  ApiSalesManagerUpdateTransactionMutation,
  ApiSalesManagerUpdateTransactionMutationVariables,
  ApiSalesManagerUpdateTransactionDocument,
  TransactionQuery,
  TransactionQueryVariables,
  TransactionDocument,
} from '~/graphql/types'
import { ApolloClientService } from '~/services/apollo-client.service'

export class SalesTransactionService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  async form(transactionId: string, locale?: string) {
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerFormMutation,
      ApiSalesManagerFormMutationVariables
    >({
      mutation: ApiSalesManagerFormDocument,
      variables: {
        data: {
          transactionId,
          locale,
        },
      },
    })
    return mutateResult.data?.api_sales_manager_form.result
  }

  async register(
    amount: string,
    currency: string,
    provider: string,
    method: string,
    reference?: number | string
  ) {
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerRegisterMutation,
      ApiSalesManagerRegisterMutationVariables
    >({
      mutation: ApiSalesManagerRegisterDocument,
      variables: {
        data: {
          amount,
          currency,
          provider,
          method,
          reference: reference ? reference.toString() : undefined,
        },
      },
    })
    return mutateResult.data?.api_sales_manager_register.result
  }

  async pay(
    amount: string,
    currency: string,
    provider: string,
    method: string
  ) {
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerPayMutation,
      ApiSalesManagerPayMutationVariables
    >({
      mutation: ApiSalesManagerPayDocument,
      variables: {
        data: {
          amount,
          currency,
          provider,
          method,
        },
      },
    })
    return mutateResult.data?.api_sales_manager_pay.result
  }

  async amount(reference?: number | string) {
    if (!reference) {
      return {
        currency: 'EUR',
        amount: '0',
        amountInclTax: '0',
        provider: 'erp',
        method: '',
        condition: '',
      }
    }
    const mutateResult = await this.apolloClientService.client.query<
      ApiSalesManagerAmountQuery,
      ApiSalesManagerAmountQueryVariables
    >({
      query: ApiSalesManagerAmountDocument,
      variables: {
        data: {
          reference: reference.toString(),
        },
      },
    })
    return mutateResult.data?.api_sales_manager_amount.result
  }

  async getTransaction(transactionId: string) {
    const queryResult = await this.apolloClientService.client.query<
      TransactionQuery,
      TransactionQueryVariables
    >({
      query: TransactionDocument,
      variables: {
        where: {
          transaction_id: { _eq: transactionId },
        },
      },
    })
    return queryResult.data?.transaction[0]
  }

  async updateTransaction(
    transactionId: string,
    request: { [key: string]: any },
    forceCancel?: boolean
  ) {
    const mutateResult = await this.apolloClientService.client.mutate<
      ApiSalesManagerUpdateTransactionMutation,
      ApiSalesManagerUpdateTransactionMutationVariables
    >({
      mutation: ApiSalesManagerUpdateTransactionDocument,
      variables: {
        data: {
          transactionId,
          request: JSON.stringify(request),
          forceCancel,
        },
      },
    })
    return mutateResult.data?.api_sales_manager_update_transaction.result
  }
}
