/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import SystemMessages from '~/store/system-messages'

import User from '~/store/user'

import OrderConfirmed from '~/store/order-confirmed'
import Order from '~/store/order'
import Invoice from '~/store/invoice'
import CreditMemo from '~/store/credit-memo'
import OrderItemUnshipped from '~/store/order-item-unshipped'
import ShipmentDocument from '~/store/shipment-document'
import CustomerStatistic from '~/store/customer-statistic'
import ExchangeRate from '~/store/exchange-rate'
import Customer from '~/store/customer'
import CustomerAddress from '~/store/customer-address'
import PromotionalOffers from '~/store/promotional-offers'
import SalesCartOrder from '~/store/sales-cart-order'
import SalesCartProposal from '~/store/sales-cart-proposal'
import ProductPrice from '~/store/product-price'
import PaymentData from '~/store/payment-data'
import Layout from '~/store/layout'
import NewOrder from '~/store/new-order'
import Report from '~/store/report'
import OrderPending from '~/store/order-pending'
import BackendAdmin from '~/store/backend-admin'
import Subcustomer from '~/store/subcustomer'
import ShippingMethod from '~/store/shipping-method'
import CustomerAddressDropshipping from '~/store/customer-address-dropshipping'
import FileDownload from '~/store/file-download'
import Mail from '~/store/mail'
import Communication from '~/store/communication'
import ProposalSent from '~/store/proposal-sent'
import QuoteList from '~/store/quote-list'
import SalesCartQuote from '~/store/sales-cart-quote'
import CountryRegion from '~/store/country-region'
import ProductFiles from '~/store/product-files'
import SalesCartPromo from '~/store/sales-cart-promo'
import Setting from '~/store/setting'
import OrderPromo from '~/store/order-promo'
import CommunicationCodes from '~/store/communication-codes'
import CommunicationMails from '~/store/communication-mails'
import AdminOrder from '~/store/admin-order'
import NewProposal from '~/store/new-proposal'
import NewPromo from '~/store/new-promo'
import Agent from '~/store/agent'
import Replacement from '~/store/replacement'
import File from '~/store/file'

let systemMessagesStore: SystemMessages

let userStore: User

let mailStore: Mail
let communicationStore: Communication
let communicationCodesStore: CommunicationCodes
let communicationMailsStore: CommunicationMails
let orderConfirmedStore: OrderConfirmed
let orderStore: Order
let orderPendingStore: OrderPending
let invoiceStore: Invoice
let reportStore: Report
let creditMemoStore: CreditMemo
let orderItemUnshippedStore: OrderItemUnshipped
let shipmentDocumentStore: ShipmentDocument
let shippingMethodStore: ShippingMethod
let customerStatisticStore: CustomerStatistic
let exchangeRateStore: ExchangeRate
let fileStore: File
let fileDownloadStore: FileDownload
let agentStore: Agent
let customerStore: Customer
let customerAddressStore: CustomerAddress
let customerAddressDropshippingStore: CustomerAddressDropshipping
let promotionalOffersStore: PromotionalOffers
let salesCartOrderStore: SalesCartOrder
let salesCartPromoStore: SalesCartPromo
let salesCartProposalStore: SalesCartProposal
let salesCartQuoteStore: SalesCartQuote
let productPriceStore: ProductPrice
let productFilesStore: ProductFiles
let paymentDataStore: PaymentData
let layoutStore: Layout
let newOrderStore: NewOrder
let newPromoStore: NewPromo
let newProposalStore: NewProposal
let backendAdminStore: BackendAdmin
let subcustomerStore: Subcustomer
let proposalSentStore: ProposalSent
let quoteListStore: QuoteList
let countryRegion: CountryRegion
let settingStore: Setting
let orderPromoStore: OrderPromo
let adminOrderStore: AdminOrder
let replacementStore: Replacement

function initialiseStores(store: Store<any>): void {
  systemMessagesStore = getModule(SystemMessages, store)

  userStore = getModule(User, store)

  mailStore = getModule(Mail, store)
  communicationStore = getModule(Communication, store)
  communicationCodesStore = getModule(CommunicationCodes, store)
  communicationMailsStore = getModule(CommunicationMails, store)
  orderConfirmedStore = getModule(OrderConfirmed, store)
  orderStore = getModule(Order, store)
  orderPendingStore = getModule(OrderPending, store)
  invoiceStore = getModule(Invoice, store)
  reportStore = getModule(Report, store)
  creditMemoStore = getModule(CreditMemo, store)
  orderItemUnshippedStore = getModule(OrderItemUnshipped, store)
  shipmentDocumentStore = getModule(ShipmentDocument, store)
  shippingMethodStore = getModule(ShippingMethod, store)
  customerStatisticStore = getModule(CustomerStatistic, store)
  exchangeRateStore = getModule(ExchangeRate, store)
  fileStore = getModule(File, store)
  fileDownloadStore = getModule(FileDownload, store)
  agentStore = getModule(Agent, store)
  customerStore = getModule(Customer, store)
  customerAddressStore = getModule(CustomerAddress, store)
  customerAddressDropshippingStore = getModule(
    CustomerAddressDropshipping,
    store
  )
  promotionalOffersStore = getModule(PromotionalOffers, store)
  salesCartOrderStore = getModule(SalesCartOrder, store)
  salesCartPromoStore = getModule(SalesCartPromo, store)
  salesCartProposalStore = getModule(SalesCartProposal, store)
  salesCartQuoteStore = getModule(SalesCartQuote, store)
  productPriceStore = getModule(ProductPrice, store)
  productFilesStore = getModule(ProductFiles, store)
  paymentDataStore = getModule(PaymentData, store)
  layoutStore = getModule(Layout, store)
  newOrderStore = getModule(NewOrder, store)
  newPromoStore = getModule(NewPromo, store)
  newProposalStore = getModule(NewProposal, store)
  backendAdminStore = getModule(BackendAdmin, store)
  subcustomerStore = getModule(Subcustomer, store)
  proposalSentStore = getModule(ProposalSent, store)
  quoteListStore = getModule(QuoteList, store)
  countryRegion = getModule(CountryRegion, store)
  settingStore = getModule(Setting, store)
  orderPromoStore = getModule(OrderPromo, store)
  adminOrderStore = getModule(AdminOrder, store)
  replacementStore = getModule(Replacement, store)
}

export {
  initialiseStores,
  systemMessagesStore,
  userStore,
  mailStore,
  communicationStore,
  communicationCodesStore,
  communicationMailsStore,
  orderConfirmedStore,
  orderStore,
  orderPendingStore,
  invoiceStore,
  reportStore,
  creditMemoStore,
  orderItemUnshippedStore,
  shipmentDocumentStore,
  shippingMethodStore,
  customerStatisticStore,
  exchangeRateStore,
  fileStore,
  fileDownloadStore,
  agentStore,
  customerStore,
  customerAddressStore,
  customerAddressDropshippingStore,
  promotionalOffersStore,
  salesCartOrderStore,
  salesCartPromoStore,
  salesCartProposalStore,
  salesCartQuoteStore,
  productPriceStore,
  productFilesStore,
  paymentDataStore,
  layoutStore,
  newOrderStore,
  newPromoStore,
  newProposalStore,
  backendAdminStore,
  subcustomerStore,
  proposalSentStore,
  quoteListStore,
  countryRegion,
  settingStore,
  orderPromoStore,
  adminOrderStore,
  replacementStore,
}
