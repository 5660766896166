import 'reflect-metadata'
import { systemMessagesStore } from '~/utils/store-accessor'
import { MessageType } from '~/store/system-messages'

export class SystemMessageService {
  private store = systemMessagesStore

  private addMessage(
    message,
    values?: {
      [key: string]: any
    },
    timeout = 5000,
    color: MessageType = MessageType.INFO
  ) {
    this.store.addMessage({
      message: message.toString(),
      values,
      color,
      timeout,
    })
  }

  addErrorMessage(
    message,
    values?: {
      [key: string]: any
    },
    timeout = 15000
  ) {
    this.addMessage(message, values, timeout, MessageType.ERROR)
  }

  addSuccessMessage(
    message,
    values?: {
      [key: string]: any
    },
    timeout = 5000
  ) {
    this.addMessage(message, values, timeout, MessageType.SUCCESS)
  }

  addInfoMessage(
    message,
    values?: {
      [key: string]: any
    },
    timeout = 15000
  ) {
    this.addMessage(message, values, timeout, MessageType.INFO)
  }

  get getMessages() {
    return this.store.messages
  }

  setMessages(messages) {
    this.store.setMessages(messages)
  }
}
