import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ProductFilesDocument,
  ProductFilesQuery,
  ProductFilesQueryVariables,
  Role_Enum,
} from '~/graphql/types'
import { productFilesStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class ProductFilesService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  baseDocURL = `///${window.$nuxt.$config.mediaDomain}/documents`

  private store = productFilesStore

  /**
   * todo may remove this
   */
  get role() {
    return this.userService.isAgent ? Role_Enum.Agent : Role_Enum.Customer
  }

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        ProductFilesQuery,
        ProductFilesQueryVariables
      >({
        query: ProductFilesDocument,
      })
    ).data
    if (data) {
      this.store.setItems(data.product)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get product_files() {
    return this.store.items
  }
}
