const setRootVH = () => {
  // @ts-ignore
  document
    .querySelector(':root')
    // @ts-ignore
    .style.setProperty('--vh', window.innerHeight / 100 + 'px')
}

window.addEventListener('resize', () => {
  setRootVH()
})
setRootVH()
