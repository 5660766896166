var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('v-footer', {
    attrs: {
      "dark": "",
      "color": "secondary b2b-footer"
    }
  }, [_c('v-card-text', {
    staticClass: "text-center text-lg-right pa-0 overline",
    staticStyle: {
      "line-height": "2"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$t('footer.line1'))), _c('br'), _vm._v("\n    " + _vm._s(_vm.$t('footer.line2')) + "\n  ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }