import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { customerStore } from '~/utils/store-accessor'
import { UserService } from '~/services/user.service'
import {
  ApiCustomerCreateCustomerInput,
  ApiCustomerCreateDocument,
  ApiCustomerCreateMutation,
  ApiCustomerCreateMutationVariables,
  CustomerCurrentDocument,
  CustomerCurrentQuery,
  CustomerCurrentQueryVariables,
  CustomerDocument,
  CustomerQuery,
  CustomerQueryVariables,
  CustomerWithAdditionalDataDocument,
  CustomerWithAdditionalDataQuery,
  CustomerWithAdditionalDataQueryVariables,
  UpsertCustomerAdditionalDataDocument,
  UpsertCustomerAdditionalDataMutation,
  UpsertCustomerAdditionalDataMutationVariables,
} from '~/graphql/types'

export class CustomerService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = customerStore

  async fetchAll(force = false, withAdditionalData = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    let data
    if (withAdditionalData) {
      data = (
        await this.apolloClientService.client.query<
          CustomerWithAdditionalDataQuery,
          CustomerWithAdditionalDataQueryVariables
        >({
          query: CustomerWithAdditionalDataDocument,
          variables: {},
        })
      ).data
    } else {
      data = (
        await this.apolloClientService.client.query<
          CustomerQuery,
          CustomerQueryVariables
        >({
          query: CustomerDocument,
          variables: {},
        })
      ).data
    }

    if (data) {
      this.store.setItems(data.customer)
    }
    this.store.setLoading(false)
  }

  async fetchCurrent(force = false) {
    if (this.store.fetchCurrentStarted && !force) {
      return
    }
    this.store.setFetchCurrentStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        CustomerCurrentQuery,
        CustomerCurrentQueryVariables
      >({
        query: CustomerCurrentDocument,
        variables: {
          where: {
            erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
        },
      })
    ).data

    if (data && data.customer.length === 1) {
      this.store.setCurrent(data.customer[0])
    }
    this.store.setLoadingCurrent(false)
  }

  get loading() {
    return this.store.loading
  }

  get loadingCurrent() {
    return this.store.loadingCurrent
  }

  get customer() {
    return this.store.current
  }

  get customers() {
    return this.store.items
  }

  async newCustomer(customer: ApiCustomerCreateCustomerInput) {
    // uncomment for debug
    // if (!confirm(JSON.stringify(customer))) {
    //   return false
    // }
    const createMutation = await this.apolloClientService.client.mutate<
      ApiCustomerCreateMutation,
      ApiCustomerCreateMutationVariables
    >({
      mutation: ApiCustomerCreateDocument,
      variables: {
        customer,
      },
    })

    return createMutation.data?.api_customer_create.result
  }

  async upsertCustomerAdditionalData(customer_erp_id: string, data: object) {
    const upsertMutation = await this.apolloClientService.client.mutate<
      UpsertCustomerAdditionalDataMutation,
      UpsertCustomerAdditionalDataMutationVariables
    >({
      mutation: UpsertCustomerAdditionalDataDocument,
      variables: {
        customer_erp_id,
        data,
      },
    })
    return upsertMutation.data?.insert_customer_additional_data_one
      ?.customer_erp_id
  }
}
