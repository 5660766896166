import { cid, container } from 'inversify-props'
import { SalesTransactionService } from '~/services/sales-transaction.service'
import { SystemMessageService } from '~/services/system-message.service'

export default async function ({ app, params, query, redirect }) {
  const systemMessageService = container.get<SystemMessageService>(
    cid.SystemMessageService
  )

  const transactionService = container.get<SalesTransactionService>(
    cid.SalesTransactionService
  )

  systemMessageService.addErrorMessage('transaction.back.message')

  const transactionId = params?.transaction
  const request = {
    ...query,
  }

  await transactionService.updateTransaction(transactionId, request, true)

  const transaction = await transactionService.getTransaction(transactionId)
  const orderType = transaction?.order_transactions[0]?.order?.type
  const orderId = transaction?.order_transactions[0]?.order?.increment_id
  if (orderType === 'invoice') {
    return redirect(app.localePath('/invoices'))
  } else {
    if (orderId) {
      return redirect(app.localePath(`/order/${orderId}`))
    }
    return redirect(app.localePath('/new-order/step-3'))
  }
}
