import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { quoteListStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'
import {
  CartDocument,
  CartSubscription,
  CartSubscriptionVariables,
  DeleteQuoteByPkDocument,
  DeleteQuoteByPkMutation,
  DeleteQuoteByPkMutationVariables,
} from '~/graphql/types'

export class QuoteListService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = quoteListStore

  get commonWhere() {
    const where: CartSubscriptionVariables['where'] = {
      customer_erp_id: {
        _eq: this.userService.customerErpId,
      },
      status: {
        _eq: 'active',
      },
      type: {
        _eq: 'quote',
      },
      subcustomer_id: {
        _is_null: true,
      },
    }

    if (this.userService.agentErpId) {
      where.agent_erp_id = {
        _eq: this.userService.agentErpId,
      }
    } else {
      where.agent_erp_id = {
        _is_null: true,
      }
    }

    return where
  }

  private _o: ZenObservable.Subscription | undefined

  subscribe() {
    const where = { ...this.commonWhere }

    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<CartSubscription, CartSubscriptionVariables>({
          query: CartDocument,
          variables: {
            where,
            limit: null, // force to fetch all carts
          },
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.cart)
          }
          this.store.setLoading(false)
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  get loading() {
    return this.store.loading
  }

  get items() {
    return this.store.items
  }

  async delete(variables: DeleteQuoteByPkMutationVariables) {
    const deleteMutation = await this.apolloClientService.client.mutate<
      DeleteQuoteByPkMutation,
      DeleteQuoteByPkMutationVariables
    >({
      mutation: DeleteQuoteByPkDocument,
      variables,
    })
    return deleteMutation.data?.delete_cart_by_pk?.id
  }
}
