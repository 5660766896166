import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import {
  ProductReplacementListCustomerQuery,
  ProductReplacementListQuery,
} from '~/graphql/types'

@Module({
  name: 'replacement',
  stateFactory: true,
  namespaced: true,
})
export default class Replacement extends VuexModule {
  fetchStarted = false
  loading = true
  _items:
    | ProductReplacementListQuery['product']
    | ProductReplacementListCustomerQuery['product'] = []

  _search = ''
  _page = 1
  _itemsPerPage: 10 | 50 | 100 = 100

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(list) {
    this._items = list
  }

  @Mutation
  setSearch(search) {
    this._search = search
  }

  @Mutation
  setPage(page) {
    this._page = page
  }

  @Mutation
  setItemsPerPage(itemsPerPage) {
    this._itemsPerPage = itemsPerPage
  }

  get items() {
    return this._items
  }

  get search() {
    return this._search
  }

  get page() {
    return this._page
  }

  get itemsPerPage() {
    return this._itemsPerPage
  }
}
