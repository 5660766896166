import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { ProductFilesQuery } from '~/graphql/types'

@Module({
  name: 'product-files',
  stateFactory: true,
  namespaced: true,
})
export default class ProductFiles extends VuexModule {
  loading = true
  updating = true
  items: ProductFilesQuery['product'] = []
  fetchStarted = false

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setUpdating(updating) {
    this.updating = updating
  }

  @Mutation
  setItems(items) {
    this.items = items
  }

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }
}
