
import Component from 'nuxt-class-component'
import { Inject } from 'inversify-props'
import App from '~/mixins/app'
import AppSnackBarMessages from '~/components/AppSnackBarMessages.vue'
import { ExchangeRateService } from '~/services/exchange-rate.service'
import { CustomerService } from '~/services/customer.service'
import { SalesCartOrderService } from '~/services/sales-cart-order.service'
import { UserService } from '~/services/user.service'
import { ProductPriceService } from '~/services/product-price.service'
import { CustomerPaymentService } from '~/services/customer-payment.service'
import { ShippingMethodService } from '~/services/shipping-method.service'
import { SettingService } from '~/services/setting.service'
import { SalesCartProposalService } from '~/services/sales-cart-proposal.service'
import { AgentService } from '~/services/agent.service'

@Component({
  middleware: 'authenticated',
  components: {
    AppSnackBarMessages,
  },
})
export default class DefaultLayout extends App {
  @Inject('ExchangeRateService') exchangeRateService!: ExchangeRateService

  @Inject('CustomerPaymentService')
  customerPaymentService!: CustomerPaymentService

  @Inject('UserService') userService!: UserService

  @Inject('AgentService') agentService!: AgentService
  @Inject('CustomerService') customerService!: CustomerService

  @Inject('ShippingMethodService') shippingMethodService!: ShippingMethodService

  @Inject('ProductPriceService')
  productPriceService!: ProductPriceService

  @Inject('SalesCartOrderService') salesCartOrderService!: SalesCartOrderService

  @Inject('SalesCartProposalService')
  salesCartProposalService!: SalesCartProposalService

  @Inject('SettingService') settingService!: SettingService

  // not use async-await because we want parallel fetches
  created() {
    // new order
    this.exchangeRateService.fetchAll()

    // customer new order + agent create user
    this.customerPaymentService.fetchAll()
    this.shippingMethodService.fetchAll()

    // customer current user + new order
    if (this.userService.customerErpId) {
      this.customerService.fetchCurrent()

      this.productPriceService.fetchAll()

      // subscribe active carts
      this.salesCartOrderService.subscribe()
    }

    // sub-customer current user + new proposal
    if (this.userService.subcustomerId) {
      // subscribe active carts
      this.salesCartProposalService.subscribe()
    }

    if (
      this.userService.hasCustomerRole ||
      this.userService.hasDemoCustomerRole
    ) {
      this.settingService.subscribe()
    }

    // agent select customer + backend-admin crud subcustomer
    if (
      this.userService.isAgent ||
      this.userService.isBackendSuperAdmin ||
      this.userService.isBackendAdmin
    ) {
      this.customerService.fetchAll()
    }

    // agent
    if (this.userService.isAgent) {
      this.agentService.fetchRebate()
    }

    // backend-super-admin + backend-admin
    if (
      this.userService.isBackendSuperAdmin ||
      this.userService.isBackendAdmin
    ) {
      this.settingService.subscribe()
    }
  }
}
