import { Mutation, VuexModule } from 'vuex-module-decorators'

export default class NewAbstract extends VuexModule {
  _step = 1

  get step() {
    return this._step
  }

  @Mutation
  setStep(step) {
    this._step = step
  }
}
