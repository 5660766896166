import { Context } from '@nuxt/types'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { cid, container } from 'inversify-props'
import { SystemMessageService } from '~/services/system-message.service'
import { MessageType } from '~/store/system-messages'

const API_PAYLOAD_MESSAGE_TYPE = {
  13500: 'info',
  14000: 'info',
  15500: 'info',
  15700: 'info',
  15800: 'info',
}

const API_RELOAD_PAGE_ERROR_CODE = [10401]

class ReloadPageError extends Error {
  constructor(message?: string) {
    super(message)

    // Set the prototype explicitly @see https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ReloadPageError.prototype)
  }
}

const manageErrors = ({ message, extensions }) => {
  if (message.startsWith('Could not verify JWT')) {
    throw new Error(message)
  } else {
    const systemMessageService = container.get<SystemMessageService>(
      cid.SystemMessageService,
    )
    let messageType = MessageType.ERROR
    let messageCode = message
    let messageValues
    try {
      if (extensions?.exception?.messageCode) {
        messageCode = extensions.exception.messageCode
      }
      if (extensions?.exception?.code) {
        messageType =
          API_PAYLOAD_MESSAGE_TYPE?.[extensions.exception.code] ??
          MessageType.ERROR
        messageCode = `api.error.${extensions.exception.code}`
      }
      if (extensions?.exception?.parameters) {
        messageValues = JSON.parse(
          JSON.stringify(extensions.exception.parameters),
        )
      }
    } catch (e) {}
    if (messageType === MessageType.INFO) {
      systemMessageService.addInfoMessage(messageCode, messageValues)
    } else {
      systemMessageService.addErrorMessage(messageCode, messageValues)
    }
    if (
      extensions?.exception?.code &&
      API_RELOAD_PAGE_ERROR_CODE.includes(extensions?.exception?.code)
    ) {
      throw new ReloadPageError(message)
    }
  }
}

export default (context: Context) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let isGraphqlError = networkError && (<any>networkError).statusCode === 401
    let isReloadPageError = false
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        try {
          manageErrors({ message, extensions })
        } catch (e) {
          if (e instanceof ReloadPageError) {
            isReloadPageError = true
          } else {
            isGraphqlError = true
          }
        }
      })
    }

    if (isGraphqlError) {
      setTimeout(() => {
        window.location.href = context.localePath('/logout')
      }, 2500)
    }
    if (isReloadPageError) {
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }
  })

  return {
    link: errorLink,
    httpEndpoint: `https://${context.$config.hasuraDomain}/v1/graphql`,
    wsEndpoint: `wss://${context.$config.hasuraDomain}/v1/graphql`,
    apollo: {
      cache: new InMemoryCache(),
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          // fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          // fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        subscription: {
          fetchPolicy: 'no-cache',
          // fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    },
  }
}
