import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import { customerAddressDropshippingStore } from '~/utils/store-accessor'
import {
  CustomerAddressDropshippingDocument,
  CustomerAddressDropshippingSubscription,
  CustomerAddressDropshippingSubscriptionVariables,
  CustomerCurrentQuery,
  InsertCustomerAddressDropshippingDocument,
  InsertCustomerAddressDropshippingMutation,
  InsertCustomerAddressDropshippingMutationVariables,
} from '~/graphql/types'
import { UserService } from '~/services/user.service'

export class CustomerAddressDropshippingService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = customerAddressDropshippingStore
  private _o: ZenObservable.Subscription | undefined

  subscribe() {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<
          CustomerAddressDropshippingSubscription,
          CustomerAddressDropshippingSubscriptionVariables
        >({
          query: CustomerAddressDropshippingDocument,
          variables: {
            where: {
              customer_erp_id: {
                _eq: this.userService.customerErpId,
              },
            },
          },
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.customer_address_dropshipping)
          }
          this.store.setLoading(false)
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o?.unsubscribe()
      this._o = undefined
    }
  }

  get loading() {
    return this.store.loading
  }

  get addresses() {
    return this.store.items
  }

  async newAddress(
    address: {
      mandatory: string
      line: string
      city: string
      province: string
      zipcode: string
      country: string
      lastname: string
      firstname: string
      // email: string
      telephone: string
    },
    customer?: CustomerCurrentQuery['customer'][0] | null
  ) {
    const mutateResult = await this.apolloClientService.client.mutate<
      InsertCustomerAddressDropshippingMutation,
      InsertCustomerAddressDropshippingMutationVariables
    >({
      mutation: InsertCustomerAddressDropshippingDocument,
      variables: {
        objects: {
          customer_erp_id: customer?.erp_id,
          name: address.mandatory.substring(0, 50),
          address: address.line.substring(0, 50),
          address_2: address.line.substring(50, 100),
          // email: address.email.substring(0, 80),
          email: '', // prevent not null violation
          city: address.city.substring(0, 40),
          country: address.country.substring(0, 10),
          phone: address.telephone.substring(0, 50),
          province: address.province.substring(0, 30),
          zip_code: address.zipcode.substring(0, 20),
          firstname: address.firstname.substring(0, 50),
          lastname: address.lastname.substring(0, 50),
          shipping_method_code: customer?.shipping_method_code,
        },
      },
    })
    return mutateResult.data?.insert_customer_address_dropshipping
  }
}
