import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiMailManagerMailInput,
  ApiMailManagerRetryDocument,
  ApiMailManagerRetryMutation,
  ApiMailManagerRetryMutationVariables,
  ApiMailManagerSendDocument,
  ApiMailManagerSendMutation,
  ApiMailManagerSendMutationVariables,
  Mail,
  MailDocument,
  MailQueryVariables,
  MailsDocument,
  MailsQuery,
  MailsQueryVariables,
} from '~/graphql/types'
import { mailStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { EmailType, MailType } from '~/store/mail'

export type SendMailType = {
  from?: EmailType
  to: EmailType[]
  bcc?: EmailType[]
  cc?: EmailType[]
  replayTo?: EmailType
  provider?: string
  content: {
    context: string
    charset: string
    subject: string
    html: string
    text?: string
    attachments?: {
      content: string
      name: string
    }[]
  }
}

export class MailService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  public store = mailStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        MailsQuery,
        MailsQueryVariables
      >({
        query: MailsDocument,
      })
    ).data

    if (data) {
      this.store.setItems(data.mail)
    }
    this.store.setLoading(false)
  }

  get items() {
    return this.store.items
  }

  get loading() {
    return this.store.loading
  }

  async mail(identifier: string) {
    const queryResult = await this.apolloClientService.client.query<
      Mail,
      MailQueryVariables
    >({
      query: MailDocument,
      variables: {
        id: identifier,
      },
    })
    return queryResult.data?.mail[0] as MailType
  }

  async retry(identifier: string) {
    return (
      await this.apolloClientService.client.mutate<
        ApiMailManagerRetryMutation,
        ApiMailManagerRetryMutationVariables
      >({
        mutation: ApiMailManagerRetryDocument,
        variables: {
          id: identifier,
        },
      })
    ).data?.api_mail_manager_retry.result
  }

  async send(mail: SendMailType) {
    return (
      await this.apolloClientService.client.mutate<
        ApiMailManagerSendMutation,
        ApiMailManagerSendMutationVariables
      >({
        mutation: ApiMailManagerSendDocument,
        variables: { mail: mail as ApiMailManagerMailInput },
      })
    ).data?.api_mail_manager_send.result
  }
}
