import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { Communication_External_Mail } from '~/graphql/types'

export type Agent = {
  email: string
  erp_id: string
  id: string
  name: string
}

@Module({
  name: 'communication-mails',
  stateFactory: true,
  namespaced: true,
})
export default class CommunicationMails extends VuexModule {
  fetchStarted = false
  loading = true
  externalMails: Communication_External_Mail[] = []
  agents: Agent[] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setExternalMails(mails) {
    this.externalMails = mails
  }

  @Mutation
  setAgents(mails) {
    this.agents = mails
  }
}
