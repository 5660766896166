import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ApiInsertUserDocument,
  ApiInsertUserMutation,
  ApiInsertUserMutationVariables,
  ApiUpdateUserDocument,
  ApiUpdateUserMutation,
  ApiUpdateUserMutationVariables,
  DeleteUserByPkMutationVariables,
  Role_Enum,
  UserDocument,
  UserSubscription,
  UserSubscriptionVariables,
} from '~/graphql/types'
import { backendAdminStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'
import { UserService } from '~/services/user.service'

export class BackendAdminService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  public store = backendAdminStore

  private _o: ZenObservable.Subscription | undefined

  subscribe() {
    if (!this._o) {
      this._o = this.apolloClientService.client
        .subscribe<UserSubscription, UserSubscriptionVariables>({
          query: UserDocument,
          variables: {
            where: {
              role: {
                _eq: this.role,
              },
            },
          },
        })
        .subscribe(({ data }) => {
          if (data) {
            this.store.setItems(data.user)
          }
          this.store.setLoading(false)
        })
    }
  }

  unsubscribe() {
    if (this._o) {
      this._o.unsubscribe()
      this._o = undefined
    }
  }

  get items() {
    return this.store.items
  }

  get loading() {
    return this.store.loading
  }

  get role() {
    return Role_Enum.BackendAdmin
  }

  delete(variables: DeleteUserByPkMutationVariables) {
    return this.userService.delete(variables)
  }

  async insert(variables: ApiInsertUserMutationVariables) {
    const insertMutation = await this.apolloClientService.client.mutate<
      ApiInsertUserMutation,
      ApiInsertUserMutationVariables
    >({
      mutation: ApiInsertUserDocument,
      variables,
    })

    return insertMutation.data?.api_insert_user
  }

  async update(variables: ApiUpdateUserMutationVariables) {
    const updateMutation = await this.apolloClientService.client.mutate<
      ApiUpdateUserMutation,
      ApiUpdateUserMutationVariables
    >({
      mutation: ApiUpdateUserDocument,
      variables,
    })

    return updateMutation.data?.api_update_user
  }
}
