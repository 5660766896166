import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { OrderListQuery } from '~/graphql/types'

@Module({
  name: 'order',
  stateFactory: true,
  namespaced: true,
})
export default class Order extends VuexModule {
  fetchStarted = false
  loading = true
  _items: OrderListQuery['order'] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setItems(list) {
    this._items = list
  }

  get items() {
    return this._items
  }
}
