var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('app-snackbars', {
    attrs: {
      "objects": _vm.messages,
      "bottom": "",
      "right": ""
    },
    on: {
      "update:objects": function ($event) {
        _vm.messages = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var object = _ref.object;
        return [_c('v-icon', {
          staticClass: "system-message-icon"
        }, [_vm._v(_vm._s(_vm.icon(object)) + " ")]), _vm._v(" "), _c('span', {
          staticClass: "system-message-text"
        }, [_vm._v(_vm._s(_vm.message(object)))])];
      }
    }, {
      key: "action",
      fn: function (_ref2) {
        var close = _ref2.close,
          message = _ref2.message;
        return [_c('v-btn', {
          staticClass: "align-self-start",
          attrs: {
            "icon": "",
            "color": _vm.color(message)
          },
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }