import 'reflect-metadata'
import { Inject } from 'inversify-props'
import {
  ExchangeRateDocument,
  ExchangeRateQuery,
  ExchangeRateQueryVariables,
} from '~/graphql/types'
import { exchangeRateStore } from '~/utils/store-accessor'
import { ApolloClientService } from '~/services/apollo-client.service'

export class ExchangeRateService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  public store = exchangeRateStore

  private readonly _currencies = [
    {
      code: 'GBP',
      name: 'British Pound',
      icon: 'mdi-currency-gbp',
      symbol: '£',
    },
    {
      code: 'HRK',
      name: 'Croatian Kuna',
      icon: 'mdi-currency-eur-off',
      symbol: 'kn',
    },
    {
      code: 'CZK',
      name: 'Czech Koruna',
      icon: 'mdi-currency-eur-off',
      symbol: 'Kč',
    },
    {
      code: 'EUR',
      name: 'Euro',
      icon: 'mdi-currency-eur',
      symbol: '€',
    },
    {
      code: 'HUF',
      name: 'Hungarian Forint',
      icon: 'mdi-currency-eur-off',
      symbol: 'Ft',
    },
    {
      code: 'PLN',
      name: 'Polish Zloty',
      icon: 'mdi-currency-eur-off',
      symbol: 'zł',
    },
    {
      code: 'RON',
      name: 'Romanian New Leu',
      icon: 'mdi-currency-eur-off',
      symbol: 'lei',
    },
    {
      code: 'RUB',
      name: 'Russian Ruble',
      icon: 'mdi-currency-rub',
      symbol: '₽',
    },
    {
      code: 'CHF',
      name: 'Swiss Franc',
      icon: 'mdi-currency-eur-off',
      symbol: 'CHF',
    },
    {
      code: 'USD',
      name: 'US Dollar',
      icon: 'mdi-currency-usd',
      symbol: '$',
    },
  ]

  get currencies() {
    return this._currencies.map((c) => {
      if (c.code === 'EUR') {
        return {
          ...c,
          rate: 1,
          last_updated_at: null,
        }
      } else {
        const item = this.store.items.find((i) => i.currency === c.code)
        if (item) {
          return {
            ...c,
            rate: item.rate,
            last_updated_at: item.last_update_at,
          }
        }
      }
      return null
    })
  }

  get currentCurrency() {
    return this.currencies.find(
      (c) => c?.code === this.store.currentCurrencyCode
    )
  }

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        ExchangeRateQuery,
        ExchangeRateQueryVariables
      >({
        query: ExchangeRateDocument,
        variables: {
          where: {
            currency: {
              _in: [
                'EUR',
                'CHF',
                'CZK',
                'GBP',
                'HUF',
                'PLN',
                'RON',
                'RUB',
                'USD',
              ],
            },
          },
        },
      })
    ).data

    if (data) {
      this.store.setItems(data.exchange_rate)
    }
    this.store.setLoading(false)
  }
}
