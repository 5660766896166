
import { Component } from 'nuxt-property-decorator'
import App from '~/mixins/app'

const setRootFH = () => {
  const footer = document.querySelector('footer')

  // @ts-ignore
  document
    .querySelector(':root')
    // @ts-ignore
    .style.setProperty('--fh', footer.offsetHeight + 'px')
}

@Component
export default class AppFooter extends App {
  mounted() {
    window.addEventListener('resize', setRootFH)
    setRootFH()
  }

  destroyed() {
    window.removeEventListener('resize', setRootFH)
  }
}
