import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { PaymentMethodAndConditionQuery } from '~/graphql/types'

@Module({
  name: 'payment-data',
  stateFactory: true,
  namespaced: true,
})
export default class PaymentData extends VuexModule {
  fetchStarted = false
  loading = true
  paymentMethods: PaymentMethodAndConditionQuery['payment_method'] = []
  paymentConditions: PaymentMethodAndConditionQuery['payment_condition'] = []

  @Mutation
  setFetchStarted(fetchStarted) {
    this.fetchStarted = fetchStarted
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading
  }

  @Mutation
  setPaymentMethods(paymentMethods) {
    this.paymentMethods = paymentMethods
  }

  @Mutation
  setPaymentConditions(paymentConditions) {
    this.paymentConditions = paymentConditions
  }
}
