import * as Sentry from '@sentry/vue'

export default function () {
  return [
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ]
}
