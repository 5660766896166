var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('v-app', {
    staticClass: "white"
  }, [_c('v-main', [_c('nuxt')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }