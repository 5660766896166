import { Inject } from 'inversify-props'
import { ApolloClientService } from '~/services/apollo-client.service'
import {
  Order_By,
  ProductPriceViewDocument,
  ProductPriceViewQuery,
  ProductPriceViewQueryVariables,
} from '~/graphql/types'
import { productPriceStore } from '~/utils/store-accessor'
import { UserService } from '~/services/user.service'

export class ProductPriceService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService
  @Inject('UserService') userService!: UserService

  private store = productPriceStore

  async fetchAll(force = false) {
    if (this.store.fetchStarted && !force) {
      return
    }
    this.store.setFetchStarted(true)
    const data = (
      await this.apolloClientService.client.query<
        ProductPriceViewQuery,
        ProductPriceViewQueryVariables
      >({
        query: ProductPriceViewDocument,
        variables: {
          where: {
            customer_erp_id: {
              _eq: this.userService.customerErpId,
            },
          },
          order_by: {
            name: Order_By.Asc,
          },
        },
      })
    ).data
    if (data) {
      this.store.setItems(data.product_price_view)
    }
    this.store.setLoading(false)
  }

  get loading() {
    return this.store.loading
  }

  get prices() {
    return this.store.items
  }

  get promoPrices() {
    return this.prices.filter((price) => price.price_promo > 0)
  }

  price(code: string, qty = 1) {
    const productPrice = this.prices.find((price) => price.erp_id === code)
    if (productPrice) {
      return {
        real: productPrice.price_real,
        rowReal: productPrice.price_real * qty,
        realNoDiscount: productPrice.price_real_no_discount,
        rowRealNoDiscount: productPrice.price_real_no_discount * qty,
        public: productPrice.price_public,
        rowPublic: productPrice.price_public * qty,
        discountPercent: productPrice.discount_percent,
        customerDiscountDiscountPercent:
          productPrice.customer_discount_discount_percent,
        publicDiscountPercent:
          (1 -
            productPrice.price_real_no_discount / productPrice.price_public) *
          100,
        allowInvoiceDiscount: productPrice?.allow_invoice_discount ?? false,
        allowLineDiscount: productPrice?.allow_line_discount ?? false,
        allowLineDiscountAgent:
          (productPrice?.allow_line_discount ?? false) &&
          productPrice.discount_percent <= 1,
      }
    }
    return {
      real: 0,
      rowReal: 0,
      realNoDiscount: 0,
      rowRealNoDiscount: 0,
      public: 0,
      rowPublic: 0,
      discountPercent: 0,
      customerDiscountDiscountPercent: 0,
      publicDiscountPercent: 0,
      allowInvoiceDiscount: false,
      allowLineDiscount: false,
      allowLineDiscountAgent: false,
    }
  }
}
