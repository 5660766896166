import { Inject } from 'inversify-props'
import moment from 'moment'
import { ApolloClientService } from '~/services/apollo-client.service'
import {
  LastUpdateDocument,
  LastUpdateQuery,
  LastUpdateQueryVariables,
} from '~/graphql/types'

export class ProductService {
  @Inject('ApolloClientService') apolloClientService!: ApolloClientService

  baseImageURL = `///${window.$nuxt.$config.mediaDomain}/photos`
  defaultImageURL = `///${window.$nuxt.$config.mediaDomain}/errors/generic.jpg`

  imageUrl(code: string | null | undefined) {
    if (code != null && code.length > 0) {
      return `${this.baseImageURL}/${code.slice(code.length - 6)}.jpg`
    }
    return this.defaultImageURL
  }

  async lastUpdate() {
    const product = (
      await this.apolloClientService.client.query<
        LastUpdateQuery,
        LastUpdateQueryVariables
      >({
        query: LastUpdateDocument,
      })
    ).data?.product?.[0]
    if (product) {
      return moment(product.updated_at).toDate()
    }
    return new Date()
  }
}
